import _ from "lodash";

import {
  SOCIAL_INSTAGRAM_CONTENT,
  SOCIAL_REDIRECTED_API_URL,
  SOCIAL_TIKTOK_CONTENT,
  SOCIAL_YOUTUBE_CONTENT,
} from "constants/APIs";
import { Env, pendoKey } from "../config";
import clientIdByClientName from "../constants/clientIdByClientName";
import {
  CONTENT_TYPE,
  MEDIA_TYPE,
  PENDO_TRACKING_TYPE,
  PLACEHOLDER_IMAGE_IN_BASE64,
  SOCIAL,
} from "../constants/common";
import configUrls from "../constants/configUrls";
import errorMsgs from "../constants/errorMsgs";
import galleryFolderIdByClient from "../constants/galleryFolderIdByClient";
import resolution from "../constants/resolution";

const formatDate = (input) => {
  const date = new Date(input);
  const month = date.toLocaleString("default", { month: "long" });
  return `${month}, ${date.getFullYear()}`;
};

const validateEmail = (email) => {
  const reg =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
};

const removeFirstSlash = (str) => {
  return str.replace(/^\/+/g, "");
};

const isInteger = (value) => /^[0-9]+$/.test(value);

const getLastUploadedFile = (uploadResults) => {
  let lastUploadedFile = "";

  if (uploadResults) {
    const { thumbnail = "" } = uploadResults;
    if (thumbnail.includes("https://")) {
      return thumbnail;
    }
    return `${configUrls.S3Server}${thumbnail}`;
  }
  return lastUploadedFile;
};

const validateInput = (data) => {
  const { email = "", firstname = "", lastName = "" } = data;

  let validInput = true;
  let isValidEmail = true;
  let isValidFirstName = true;
  let isValidLastName = true;
  let emailErrMsg = "";
  let firstNameErrMsg = "";
  let lastNameErrMsg = "";

  if (!email) {
    validInput = false;
    isValidEmail = false;
    emailErrMsg = errorMsgs.requiredEmail;
  } else {
    if (!validateEmail(email)) {
      validInput = false;
      isValidEmail = false;
      emailErrMsg = errorMsgs.invalidEmail;
    } else {
      isValidEmail = true;
    }
  }

  if (!firstname) {
    validInput = false;
    isValidFirstName = false;
    firstNameErrMsg = errorMsgs.requiredFirstName;
  } else {
    isValidFirstName = true;
  }

  if (!lastName) {
    validInput = false;
    isValidLastName = false;
    lastNameErrMsg = errorMsgs.requiredLastname;
  } else {
    isValidLastName = true;
  }

  return {
    validInput, // validate success or failed
    isValidEmail,
    isValidFirstName,
    isValidLastName,
    emailErrMsg,
    firstNameErrMsg,
    lastNameErrMsg,
  };
};

const validateUploadedFiles = async (data) => {
  let isValidFiles = true;
  let videoCount = 0;
  let errorMsg = "";
  let previewImg = "";
  let previewTxt = "";

  if (data.length === 0) {
    errorMsg = errorMsgs.requiredFile;
  } else if (data.length > 20) {
    errorMsg = errorMsgs.maxFiles;
  } else {
    for (let i = 0; i < data.length; i++) {
      const fileType = data[i].type.split("/")[0];

      if (fileType === MEDIA_TYPE.VIDEO || fileType === MEDIA_TYPE.IMAGE) {
        if (fileType === MEDIA_TYPE.VIDEO && videoCount >= 1) {
          errorMsg = errorMsgs.multipleVideos;
          break;
        }

        if (fileType === MEDIA_TYPE.IMAGE) {
          const isValid = await isValidSizeImg(data[i]);
          if (!isValid) {
            errorMsg = errorMsgs.invalidFilesize;
            break;
          }
        }
        videoCount++;
      } else {
        errorMsg = errorMsgs.invalidFileType;
        break;
      }
    }
  }

  if (errorMsg) {
    isValidFiles = false;
  }

  return {
    isValidFiles,
    errorMsg,
    previewImg,
    previewTxt,
  };
};

const isValidSizeImg = (file) => {
  const MAX_WIDTH = 6000;
  const MAX_HEIGHT = 6000;
  const MAX_FILE_SIZE = 19660800;
  let img = new Image();
  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      if (
        file.size > MAX_FILE_SIZE ||
        img.width > MAX_WIDTH ||
        img.height > MAX_HEIGHT
      ) {
        resolve(false);
      }
      resolve(true);
    };
    // Reject promise on error
    img.onerror = reject;
  });
  img.src = window.URL.createObjectURL(file);
  return promise;
};
const getEmptyFormData = () => {
  return {
    uploadedFiles: [],
    previewImg: "",
    previewTxt: "",
    isAgreed: false,
    selectedHashtags: [],
    isValidEmail: true,
    isValidFirstName: true,
    isValidLastName: true,
    emailErrMsg: "",
    firstNameErrMsg: "",
    lastNameErrMsg: "",
  };
};

const parseChallengeData = (data) => {
  const {
    id = 0,
    imageUri = "",
    name = "",
    highContents,
    hashtags = "",
  } = data;

  return {
    id,
    imageUri,
    name,
    highContents,
    hashtags: hashtags ? hashtags.replaceAll("#", "").split("===") : [],
  };
};

const parseUploadFormData = (data) => {
  const {
    clientId = null,
    uploadedFiles = [],
    uploadedFileType = "",
    selectedHashtags = [],
    location = "",
    email = "",
    firstName = "",
    lastName = "",
    description = "",
    instagramUsername = "",
    twitterUsername = "",
    additionalInfo = "",
    termsIds = [],
    ipStack,
    termsTemplateId,
    brandName = "",
  } = data;
  let formData = new FormData();
  let locationStr = "nodata nodata-ND-ND";

  if (uploadedFiles.length > 1) {
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
  } else {
    formData.append("files", uploadedFiles[0]);
  }

  if (location) {
    let country = "ND";
    let regionCode = "ND";
    let city = "nodata";
    let region = "nodata";

    if (location.country_code !== "null") {
      country = location.country_code;
    }

    if (location.region_code !== "null") {
      regionCode = location.region_code;
    }

    if (location.city !== "null") {
      city = location.city;
    }

    if (location.region_name !== "null") {
      region = location.region_name;
    }

    if (city) {
      if (city.toLowerCase() !== region.toLowerCase()) {
        city += " " + region;
      }
    }

    locationStr = city + "-" + country + "-" + regionCode;
  }

  if (clientId) {
    formData.append("clientId", clientId);
  }

  formData.append("email", email);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("description", description);
  formData.append("type", uploadedFileType);
  formData.append("location", locationStr);
  formData.append("googleTerms", JSON.stringify(null));

  if (selectedHashtags) {
    formData.append("hashtags", selectedHashtags.join(","));
  }

  if (instagramUsername) {
    formData.append("instagramUsername", instagramUsername);
  }

  if (twitterUsername) {
    formData.append("twitterUsername", twitterUsername);
  }

  if (additionalInfo) {
    formData.append("additionalInfo", JSON.stringify(additionalInfo));
  }

  if (termsIds) {
    formData.append("termsIds", termsIds);
  }

  if (ipStack) {
    formData.append("ipStack", JSON.stringify(ipStack));
  }

  if (termsTemplateId) {
    formData.append("termsTemplateId", termsTemplateId);
  }

  formData.append("brandName", brandName);

  return formData;
};

const getPreviewImgAndType = (data) => {
  return new Promise((resolve) => {
    if (data.length === 1 || data.length > 1) {
      const fileType = data[0]["type"].split("/")[0];

      if (fileType === "image") {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve({
            previewImg: reader.result,
            uploadedFileType: fileType,
          });
        };

        reader.readAsDataURL(data[0]);
      } else if (fileType === "video") {
        resolve({
          previewImg: "",
          uploadedFileType: fileType,
        });
      }
    }
  });
};

const getGalleryFolderIdByName = (pathName) => {
  return (
    galleryFolderIdByClient[Env][removeFirstSlash(pathName).toLowerCase()] || 0
  );
};

const getClientIdByName = (pathName) => {
  return (
    clientIdByClientName[Env][removeFirstSlash(pathName).toLowerCase()] || 0
  );
};

const isTabletWidth = () =>
  window.innerWidth > resolution.mobile &&
  window.innerWidth <= resolution.tablet;
const isMobileWidth = () => window.innerWidth <= resolution.mobile;

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const isS3ContentURL = (uri) => {
  return uri && uri.startsWith("/");
};

const isSocialS3Uri = (uri) => {
  if (!uri) {
    return false;
  }
  return (
    uri.startsWith("instagram") ||
    uri.startsWith("/instagram") ||
    uri.startsWith("twitter") ||
    uri.startsWith("/twitter")
  );
};

const getSocialS3URL = (uri) => {
  if (!uri) {
    return "";
  }
  if (isSocialS3Uri(uri)) {
    if (uri.charAt(0) === "/") {
      return `${configUrls.socialS3URL}${uri}`;
    }
    return `${configUrls.socialS3URL}/${uri}`;
  }
  if (isS3ContentURL(uri)) {
    return `${configUrls.S3Server}${uri}`;
  }
  return uri;
};

const getClientId = (URLQueryParams) =>
  new URLSearchParams(URLQueryParams).get("cl") || 0;

const getGalleryFolderId = (URLQueryParams) =>
  new URLSearchParams(URLQueryParams).get("gid") || 0;

const handleThumbnailLoadedError = (e, type = "video") => {
  e.target.onerror = null;
  if (type === "video") {
    e.target.poster = `${PLACEHOLDER_IMAGE_IN_BASE64}`;
  } else {
    e.target.src = `${PLACEHOLDER_IMAGE_IN_BASE64}`;
  }
};

const getInstagramMediaThumbnail = async (url) => {
  if (!url) {
    return null;
  }
  try {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: url,
    };
    const result = await fetch(`${SOCIAL_REDIRECTED_API_URL}`, requestOptions);
    if (result.ok) {
      const response = await result.json();
      const contentUrl = response.result;
      return contentUrl;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getTiktokMediaThumbnail = async (url) => {
  if (!url) {
    return null;
  }
  try {
    const result = await fetch(`https://www.tiktok.com/oembed?url=${url}`, {
      method: "GET",
    });
    if (result.ok) {
      const response = await result.json();
      return response.thumbnail_url;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getInstagramMediaUrl = async (url, media) => {
  if (!url) {
    return "";
  }
  try {
    const result = await fetch(`${SOCIAL_INSTAGRAM_CONTENT}?url=${url}`, {
      method: "GET",
    });
    if (result.ok) {
      const response = await result.json();
      const contentUrl = response.result;
      media.imgUrl = contentUrl;
      media.contentUrl = contentUrl;
      return contentUrl;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getTiktokMediaUrl = async (url, media) => {
  if (!url) {
    return "";
  }
  try {
    const result = await fetch(`${SOCIAL_TIKTOK_CONTENT}?url=${url}`, {
      method: "GET",
    });
    if (result.ok) {
      const response = await result.json();
      const contentUrl = response.result;
      media.contentUrl = contentUrl;
      media.imgUrl = contentUrl;
      return contentUrl;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getYoutubeMediaUrl = async (mediaId, media) => {
  if (!mediaId) {
    return "";
  }
  try {
    const result = await fetch(`${SOCIAL_YOUTUBE_CONTENT}?mediaId=${mediaId}`, {
      method: "GET",
    });
    if (result.ok) {
      const response = await result.json();
      const contentUrl = response.result;
      media.contentUrl = contentUrl;
      media.imgUrl = contentUrl;
      return contentUrl;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const handleImageLoadedError = (valE, uri, otherUrl, noSetTarget) => {
  const e = valE || { target: { src: "", poster: "" } };

  if (!noSetTarget) {
    e.target.onError = null;
  }

  const handleSetTarget = (val) => {
    if (!noSetTarget) {
      e.target.src = val;
    }
  };

  if (!uri) {
    if (otherUrl) {
      return otherUrl;
    }
    return uri;
  }
  const j = uri.indexOf("://");
  let imageUrl = uri;

  if (j >= 0) {
    for (let i = j + 3; i < uri.length; i += 1) {
      if (uri.charAt(i) === "/") {
        imageUrl = uri.substring(i);
        break;
      }
    }
  }

  if (isSocialS3Uri(imageUrl)) {
    e.target.src = getSocialS3URL(uri);
    return e.target.src;
  }

  if (otherUrl && isURL(otherUrl)) {
    handleSetTarget(otherUrl);
    return otherUrl;
  }

  e.target.poster = `${PLACEHOLDER_IMAGE_IN_BASE64}`;

  return `${PLACEHOLDER_IMAGE_IN_BASE64}`;
};

const handleVideoLoadedError = async (valE, socialType, permalink, media) => {
  const e = valE || { target: { src: "", poster: "" } };
  e.target.onError = null;
  const defaultPlaceholder = PLACEHOLDER_IMAGE_IN_BASE64;
  const initDefault = () => {
    e.target.src = `${defaultPlaceholder}`;
    e.target.poster = `${defaultPlaceholder}`;
    return `${defaultPlaceholder}`;
  };
  let url = "";

  if (!permalink) {
    return initDefault();
  }

  if (socialType === SOCIAL.INSTAGRAM.toLowerCase()) {
    url = await getInstagramMediaUrl(permalink, media);
  } else if (socialType === SOCIAL.TIKTOK.toLowerCase()) {
    url = await getTiktokMediaUrl(permalink, media);
  } else if (socialType === SOCIAL.YOUTUBE.toLowerCase()) {
    url = await getYoutubeMediaUrl(media.mediaId, media);
  }

  if (!url) {
    return initDefault();
  }

  e.target.src = url;
  return url;
};

const handleImageThumbnailLoadedError = async (e, socialType, permalink) => {
  e.target.onError = null;
  const defaultPlaceholder = PLACEHOLDER_IMAGE_IN_BASE64;
  if (!permalink) {
    e.target.src = defaultPlaceholder;
    e.target.poster = defaultPlaceholder;
    return defaultPlaceholder;
  }

  let url = "";
  if (socialType === SOCIAL.INSTAGRAM) {
    url = await getInstagramMediaThumbnail(permalink);
  } else if (socialType === SOCIAL.TIKTOK) {
    url = await getTiktokMediaThumbnail(permalink);
  }

  if (!url) {
    e.target.src = defaultPlaceholder;
    return defaultPlaceholder;
  }

  e.target.src = url;
  return url;
};

const isURL = (url) => {
  return url?.startsWith("http://") || url?.startsWith("https://");
};

const getContentByType = (data) => {
  let contentTemp = {};

  if (
    data.type === CONTENT_TYPE.VERIFIED ||
    data.type === CONTENT_TYPE.CONTENT
  ) {
    contentTemp = data.content;
  } else if (data.type === CONTENT_TYPE.SELECT) {
    contentTemp = data.select;
  }

  return contentTemp;
};

const formatUrl = (url) => {
  if (!url || !url.trim()) {
    return "";
  }
  const newUrl = url?.trim();
  if (newUrl?.startsWith("http://") || newUrl?.startsWith("https://")) {
    return newUrl;
  }
  return `https://${newUrl}`;
};

const pendoTrackingEvent = (data) => {
  const { clientId = 0, galleryId = 0, contentId = 0, trackType } = data || {};
  let trackingEvent = "";

  switch (trackType.label) {
    case PENDO_TRACKING_TYPE.LOADED.label: {
      trackingEvent = `Client Id: ${clientId} - Gallery Id: ${galleryId} - ${PENDO_TRACKING_TYPE.LOADED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.UPLOADER_LINK_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - Gallery Id: ${galleryId} - ${PENDO_TRACKING_TYPE.UPLOADER_LINK_CLICKED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.SOCIAL_USERNAME_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - Content Id: ${contentId} - ${PENDO_TRACKING_TYPE.SOCIAL_USERNAME_CLICKED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.HYPERLINK_CAPTION_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - Content Id: ${contentId} - ${PENDO_TRACKING_TYPE.HYPERLINK_CAPTION_CLICKED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.CONTENT_VISITED.label: {
      trackingEvent = `Client Id: ${clientId} - Content Id: ${contentId} - ${PENDO_TRACKING_TYPE.CONTENT_VISITED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.ALL_GALLERY_VISITED.label: {
      trackingEvent = `Client Id: ${clientId} - ${PENDO_TRACKING_TYPE.ALL_GALLERY_VISITED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.ALL_CONTENT_VISITED.label: {
      trackingEvent = `Client Id: ${clientId} - ${PENDO_TRACKING_TYPE.ALL_CONTENT_VISITED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.ALL_HYPERLINK_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - ${PENDO_TRACKING_TYPE.ALL_HYPERLINK_CLICKED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.GALLERY_HYPERLINK_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - Gallery Id: ${galleryId} - ${PENDO_TRACKING_TYPE.GALLERY_HYPERLINK_CLICKED.description}`;
      break;
    }

    case PENDO_TRACKING_TYPE.GALLERY_SOCIALLINK_CLICKED.label: {
      trackingEvent = `Client Id: ${clientId} - Gallery Id: ${galleryId} - ${PENDO_TRACKING_TYPE.GALLERY_SOCIALLINK_CLICKED.description}`;
      break;
    }

    default: {
      trackingEvent = `Client Id: ${clientId} - Gallery Id: ${galleryId} - Gallery Image Clicked`;
      break;
    }
  }

  return trackingEvent;
};

const pendoTracking = (data) => {
  const { clientId = 0, galleryId = 0, contentId = 0, trackType } = data || {};
  const { pendo = {} } = window;

  try {
    if (pendo && !_.isEmpty(pendo)) {
      setTimeout(() => {
        const initData = {
          visitor: {
            id: "",
          },
          account: {
            id: clientId,
          },
        };
        pendo.initialize(initData);
        const trackingData = {
          visitorId: "",
          accountId: clientId,
          timestamp: new Date().getTime(),
        };
        pendo.track(
          pendoTrackingEvent({ clientId, galleryId, contentId, trackType }),
          trackingData
        );
        console.info(`[Pendo - ${trackType.description}] Sent tracking data!`);
      }, 2000);
    }
  } catch (error) {
    console.info(`[Pendo - ${trackType.description}] Sent tracking data!`);
  }
};

const encodeToBase64 = (val) => {
  if (!val) {
    return "";
  }
  return btoa(val.toString());
};

const decodeFromBase64 = (val) => {
  if (!val) {
    return "";
  }
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (!base64regex.test(val)) {
    console.info("decodeFromBase64 input is not base64");
    return val;
  }
  try {
    return atob(val.toString());
  } catch (error) {
    console.info("decodeFromBase64 error");
  }
  return val;
};

/* eslint-disable */
const pendoFunc = (p, e, n, d) => (pendoKey) => {
  const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];

  let w;
  let x;
  const o = (p[d] = p[d] || {});
  o._q = o._q || [];
  for (w = 0, x = v.length; w < x; ++w)
    (function (m) {
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? "unshift" : "push"](
            [m].concat([].slice.call(arguments, 0))
          );
        };
    })(v[w]);
  const y = e.createElement(n);
  y.async = !0;
  y.src = "https://cdn.pendo.io/agent/static/" + pendoKey + "/pendo.js";
  const z = e.getElementsByTagName(n)[0];

  z.parentNode.insertBefore(y, z);
};
/* eslint-enable */

const loadPendoScript = () => {
  return pendoFunc(window, document, "script", "pendo")(pendoKey);
};

const convertSearchParamsToObj = (searchParams) => {
  searchParams = JSON.parse(
    '{"' +
      decodeURI(searchParams.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );

  return searchParams;
};

const urlExist = async (url) => {
  try {
    const result = await fetch(url, { method: "HEAD" });
    return result.ok;
  } catch (e) {
    return null;
  }
};

/* eslint-disable */
const hashCode = (str) => {
  let hash = 0;

  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i); //eslint-disable-line unicorn/prefer-code-point
    hash = (hash << 5) - hash + chr; //eslint-disable-line no-bitwise
    hash = Math.trunc(hash); // Convert to 32bit integer
  }
  return hash;
};

const isOverflowX = (element) => {
  return element.scrollWidth > element.clientWidth;
};

const getIPStack = (location) => {
  const {
    ip = "",
    type = "",
    continent_code = "",
    continent_name = "",
    country_code = "",
    country_name = "",
    region_code = "",
    region_name = "",
    city = "",
    zip = "",
    latitude = "",
    longitude = "",
    location: { geoname_id = "", capital = "" } = {},
    connection: { asn = "", isp = "" } = {},
  } = location || {};

  return {
    ip,
    type,
    continentCode: continent_code,
    continentName: continent_name,
    countryCode: country_code,
    countryName: country_name,
    regionCode: region_code,
    regionName: region_name,
    city,
    zip,
    latitude,
    longitude,
    locationGeonameId: geoname_id,
    locationCapital: capital,
    connectionAsn: asn,
    connectionIsp: isp,
  };
};

const uniqueIgnoreCaseArray = (values) => {
  let uniqueArr = [];
  if (values) {
    const lowerCaseArr = values.map((item) => {
      return item?.toString().toLowerCase();
    });
    const uniqueLowerCaseArr = lowerCaseArr.filter(
      (item, pos, array) => item && array.indexOf(item) === pos
    );
    uniqueArr = uniqueLowerCaseArr.map((lowerCaseItem) => {
      const index = lowerCaseArr.indexOf(lowerCaseItem);
      return values[index];
    });
  }
  return uniqueArr || [];
};

export default {
  formatDate,
  validateEmail,
  removeFirstSlash,
  isInteger,
  getLastUploadedFile,
  validateInput,
  validateUploadedFiles,
  isValidSizeImg,
  getEmptyFormData,
  parseChallengeData,
  parseUploadFormData,
  getPreviewImgAndType,
  getGalleryFolderIdByName,
  getClientIdByName,
  isTabletWidth,
  isMobileWidth,
  capitalizeFirstLetter,
  getClientId,
  getGalleryFolderId,
  getSocialS3URL,
  handleThumbnailLoadedError,
  getContentByType,
  formatUrl,
  pendoTracking,
  loadPendoScript,
  convertSearchParamsToObj,
  urlExist,
  hashCode,
  encodeToBase64,
  decodeFromBase64,
  isOverflowX,
  getIPStack,
  handleImageLoadedError,
  handleImageThumbnailLoadedError,
  isURL,
  handleVideoLoadedError,
  uniqueIgnoreCaseArray,
};

import _ from "lodash";
import { CONTENT_FLAGS, SCREEN_MODE, SOCIAL } from "../constants/common";
import {
  CALL_TO_ACTION,
  CHUNK_SIZE,
  FILTER_FIELD_NAME,
  GALLERY_THEME,
  NUMBER_SLIDER_ITEM,
  SUFFIX,
  VIEW_TYPE,
} from "../constants/gallery";
import resolution from "../constants/resolution";
import utils from "./utils";

const formatSignageContentList = (listGallery, screenMode, metadata) => {
  let listMosaicTemp = [...listGallery];
  let sliderContentIndex = 0;

  if (
    screenMode !== SCREEN_MODE.MOSAIC.S &&
    metadata?.theme === GALLERY_THEME.SIGNAGE &&
    metadata?.uploaderLink?.options &&
    metadata?.uploaderLink?.options.includes(CALL_TO_ACTION.QRCode)
  ) {
    for (let index = 0; index <= listMosaicTemp?.length - 1; index++) {
      // L: The mosaic theme has 3 types, each type is 1 slider (5, 5, 5).
      // M: The mosaic theme has 3 types, each type is 1 slider (3, 6, 3).

      sliderContentIndex += 1;

      if (getValues(screenMode, sliderContentIndex).condition) {
        listMosaicTemp.splice(index, 0, {
          id: Math.random() * 9999,
          name: CALL_TO_ACTION.QRCode,
          contentUrl: "",
          thumbnail: listMosaicTemp[index].thumbnail,
        });
      }

      if (sliderContentIndex === getValues(screenMode).total) {
        sliderContentIndex = 0;
      }
    }
  }

  return listMosaicTemp;
};

const getValues = (screenMode, count) => {
  switch (screenMode) {
    case SCREEN_MODE.MOSAIC.L: // 15 contents (5, 5, 5).
      return {
        condition:
          count % CHUNK_SIZE.DESKTOP[0] === 0 &&
          count % (CHUNK_SIZE.DESKTOP[0] * 2) !== 0,
        total: CHUNK_SIZE.DESKTOP[0] * 3,
      };

    // 12 contents (3, 6, 3).
    default:
      return {
        condition:
          count === CHUNK_SIZE.TABLET[0] * 3 ||
          count === CHUNK_SIZE.TABLET[0] * 4,
        total: CHUNK_SIZE.TABLET[0] * 4,
      };
  }
};

const getScreenMode = (width, theme) => {
  let deviceMode = SCREEN_MODE.GRID.XL;
  let idxCenter = 2;
  let slidesToShow = NUMBER_SLIDER_ITEM[3];

  const subtractNumber = {
    minDesktopMosaic: 240,
    minDesktopGrid: 330,
  };

  if (theme === GALLERY_THEME.SLIDER) {
    slidesToShow = NUMBER_SLIDER_ITEM[4];
    switch (true) {
      case width <= resolution.tabletM && width > resolution.mobileXL: {
        deviceMode = SCREEN_MODE.SLIDER.M;
        idxCenter = 1;
        slidesToShow = NUMBER_SLIDER_ITEM[2];
        break;
      }

      case width <= resolution.mobileXL:
        deviceMode = SCREEN_MODE.SLIDER.S;
        slidesToShow = NUMBER_SLIDER_ITEM[0];
        break;

      default:
        deviceMode = SCREEN_MODE.SLIDER.L;
        break;
    }
  } else if (
    theme === GALLERY_THEME.SIGNAGE ||
    theme === GALLERY_THEME.MOSAIC
  ) {
    switch (true) {
      case width > resolution.mobileL1 && width <= resolution.tabletM: {
        deviceMode = SCREEN_MODE.MOSAIC.M;
        break;
      }

      case width <= resolution.mobileL1:
        deviceMode = SCREEN_MODE.MOSAIC.S;
        break;

      default:
        deviceMode = SCREEN_MODE.MOSAIC.L;
        break;
    }
  } else {
    slidesToShow = NUMBER_SLIDER_ITEM[3];
    switch (true) {
      case width <= resolution.minDesktop && width > resolution.tabletM:
        deviceMode = SCREEN_MODE.GRID.L;
        slidesToShow = NUMBER_SLIDER_ITEM[2];
        break;

      case width > resolution.tabletS && width <= resolution.tabletM:
        deviceMode = SCREEN_MODE.GRID.M;
        slidesToShow = NUMBER_SLIDER_ITEM[1];
        break;

      case width <= resolution.tabletS:
        deviceMode = SCREEN_MODE.GRID.S;
        slidesToShow = NUMBER_SLIDER_ITEM[0];
        break;

      default:
        deviceMode = SCREEN_MODE.GRID.XL;
        break;
    }
  }

  return {
    deviceMode,
    idxCenter,
    slidesToShow,
  };
};

const formatSocialUsername = (username, socialType) => {
  let usernameTemp = username;
  switch (socialType) {
    case SOCIAL.INSTAGRAM.toLowerCase(): {
      if (username && username.charAt(0) === "@") {
        usernameTemp = usernameTemp.substring(1);
      }
      break;
    }

    case SOCIAL.TIKTOK.toLowerCase(): {
      if (username && username.charAt(0) !== "@") {
        usernameTemp = `@${username}`;
      }
      break;
    }

    default:
      break;
  }
  return usernameTemp;
};

const setChangedValue = (value, changedValue, callback) => {
  if (value !== changedValue) {
    callback(changedValue);
  }
};

const formatCustomPropertyName = (name) => {
  const convertedName = name?.trim().replaceAll(" ", "");
  return `${convertedName}${SUFFIX.CUSTOM_PROPERTIES}`;
};

const setFilterList = (contentList) => {
  let customProperties = [];
  const creators = [];

  contentList.forEach((content) => {
    const {
      review: { custom = [] } = {},
      creatorProfile: {
        firstName = "",
        lastName = "",
        instagramUsername = "",
        tiktokUsername = "",
        twitterUsername = "",
        youtubeUsername = "",
      } = {},
    } = content || {};

    // 1. First name, last name; 2. Email; 3. One of social usernames
    const creatorName = `${firstName || ""} ${lastName || ""}`;
    const creatorOption = {
      creatorName: creatorName.trim(),
      instagramUsername,
      tiktokUsername,
      twitterUsername,
      youtubeUsername,
    };

    if (custom && custom.length) {
      custom.forEach((item) => {
        const option = {
          label: item.name,
          value: item.values,
          fieldName: formatCustomPropertyName(item.name),
        };
        customProperties.push(option);
      });
    }

    Object.keys(creatorOption).map((key) => {
      const creatorItem = creatorOption[key];
      if (creatorItem && !creators.includes(creatorItem)) {
        creators.push(creatorItem);
      }
    });
  });

  customProperties = combinedCustomProperties(customProperties);
  customProperties = customProperties.map((property) => {
    const values = property.value;
    return {
      ...property,
      value: utils.uniqueIgnoreCaseArray(values),
    };
  });

  return {
    customProperties,
    creators,
  };
};

const combinedCustomProperties = (customProperties) => {
  return customProperties.reduce((previousValue, currentValue) => {
    currentValue = {
      ...currentValue,
      value:
        typeof currentValue.value === "string"
          ? [currentValue.value]
          : currentValue.value,
    };
    const index = previousValue.findIndex(
      (item) => item.label === currentValue.label
    );

    if (index !== -1) {
      const uniqueArray = _.concat(
        previousValue[index].value,
        currentValue.value
      );
      previousValue[index].value = uniqueArray;
    } else {
      previousValue.push(currentValue);
    }

    return previousValue;
  }, []);
};

const convertOptions = (optionList) => {
  return optionList.map((option) => {
    return {
      label: option,
      value: option,
    };
  });
};

const filterCreatorList = (contentList, filteredValues) => {
  return contentList.reduce((accumulator, currentValue) => {
    const {
      creatorProfile: {
        firstName = "",
        lastName = "",
        instagramUsername = "",
        tiktokUsername = "",
        twitterUsername = "",
        youtubeUsername = "",
      } = {},
    } = currentValue;

    const creatorName = `${firstName || ""} ${lastName || ""}`;
    const filterCreatorInfo = [
      creatorName.trim(),
      instagramUsername,
      tiktokUsername,
      twitterUsername,
      youtubeUsername,
    ];

    if (
      !_.isEmpty(filteredValues) &&
      FILTER_FIELD_NAME.CREATOR in filteredValues
    ) {
      Object.keys(filteredValues).forEach((key) => {
        if (!!filteredValues[key].length && key === FILTER_FIELD_NAME.CREATOR) {
          filteredValues[key].forEach((value) => {
            const convertedCreatorInfo = filterCreatorInfo.map((item) =>
              item?.toLowerCase()
            );
            if (convertedCreatorInfo.includes(value.toLowerCase())) {
              const index = accumulator.findIndex(
                (prevContent) => prevContent.id === currentValue.id
              );
              if (index === -1) {
                accumulator.push(currentValue);
              }
            }
          });
        }
      });
    } else {
      accumulator = [];
    }
    return accumulator;
  }, []);
};

const filterCustomPropertyList = (contentList, filteredValues) => {
  return contentList.reduce((accumulator, currentValue) => {
    const { review: { custom = [] } = {} } = currentValue;
    Object.keys(filteredValues).forEach((key) => {
      if (filteredValues[key].length && key !== FILTER_FIELD_NAME.CREATOR) {
        filteredValues[key].forEach((value) => {
          if (custom.length) {
            custom.forEach((property) => {
              const name = formatCustomPropertyName(property?.name);
              if (name === key) {
                const isMatched =
                  typeof property?.values === "string"
                    ? property.values.toLowerCase() === value.toLowerCase()
                    : property?.values?.some(
                        (propertyItem) =>
                          propertyItem?.toLowerCase() === value.toLowerCase()
                      );
                if (isMatched) {
                  const index = accumulator.findIndex(
                    (prevContent) => prevContent.id === currentValue.id
                  );
                  if (index === -1) {
                    accumulator.push(currentValue);
                  }
                }
              }
            });
          }
        });
      }
    });
    return accumulator;
  }, []);
};

const filterContentList = (filteredValues, contentList) => {
  const filteredCreatorListTemp =
    filterCreatorList(contentList, filteredValues) || [];
  const filteredCustomPropertyListTemp =
    filterCustomPropertyList(contentList, filteredValues) || [];

  let filteredList = contentList;

  if (!_.isEmpty(filteredValues)) {
    filteredList = filteredCustomPropertyListTemp.length
      ? filteredCustomPropertyListTemp.filter((content) =>
          filteredCreatorListTemp.length
            ? filteredCreatorListTemp.some(({ id }) => content.id === id)
            : content
        )
      : filteredCreatorListTemp;
  }

  return filteredList;
};

const calculateWidth = (contentInnerHtml, expandWidth = 0) => {
  const tempSpan = document.createElement("span");
  tempSpan.style.cssText = `font-family: IBM Plex Sans !important; font-size: 16px;`;
  tempSpan.textContent = contentInnerHtml;
  // Append the span to the body (off-screen)
  tempSpan.style.position = "absolute";
  tempSpan.style.top = "-9999px";
  tempSpan.style.left = "-9999px";
  document.body.appendChild(tempSpan);
  // Get the offset width of the span element (its width in pixels)
  const width = tempSpan.offsetWidth;
  // Remove the temporary span from the body
  document.body.removeChild(tempSpan);
  // Return the calculated width
  return width + expandWidth;
};

const getClassName = (width, height) => {
  let className = "";

  if (width === height) {
    className = VIEW_TYPE.SQUARE;
  } else if (width > height) {
    className = VIEW_TYPE.LANDSCAPE;
  } else if (width < height) {
    className = VIEW_TYPE.PORTRAIT;
  }

  return className;
};

const checkExplicitContent = (flags) => {
  let result = false;
  if (!flags) {
    return result;
  }

  if (flags && flags.length > 0) {
    flags.forEach((f) => {
      if (
        f &&
        f.trim() &&
        f.trim().toLowerCase() === CONTENT_FLAGS.EXPLICIT_CONTENT.toLowerCase()
      ) {
        result = true;
      }
    });
  }
  return result;
};

export {
  convertOptions,
  filterContentList,
  formatCustomPropertyName,
  formatSignageContentList,
  formatSocialUsername,
  getScreenMode,
  setChangedValue,
  setFilterList,
  calculateWidth,
  getClassName,
  checkExplicitContent,
};

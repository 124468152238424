import resolution from "constants/resolution";
import THEME from "constants/themes";
import GalleryContainerContext from "contexts/GalleryContainer";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import { StyledSection } from "styled-components/CommonStyled";
import { IconInstagram, IconTwitter } from "../../../../../assets/icons";
import { ReactComponent as IconBackCarousel } from "../../../../../assets/icons/svg/general-icon-back-carousel.svg";
import { ReactComponent as IconNextCarousel } from "../../../../../assets/icons/svg/general-icon-next-carousel.svg";
import {
  PENDO_TRACKING_TYPE,
  SCREEN_MODE,
  SOCIAL,
  SOCIAL_PROFILE,
} from "../../../../../constants/common";
import {
  DETAIL_LIGHTBOX,
  DETAIL_ON_HOVER,
  DIMENSION,
  GALLERY_THEME,
  NUMBER_SLIDER_ITEM,
  TYPE_ACTIONS,
} from "../../../../../constants/gallery";
import GalleryContext from "../../../../../contexts/Gallery";
import {
  formatSocialUsername,
  getScreenMode,
} from "../../../../../services/gallery";
import utils from "../../../../../services/utils";
import { StyledSocialUsername } from "../../../../../styled-components/CustomGallery/ContentDetailStyled";
import {
  StyledIcon,
  StyledInfoItem,
  StyledOverlay,
  StyledSliderGallery,
} from "../../../../../styled-components/CustomGallery/CustomGalleryStyled";
import {
  StyleContainerCarousel,
  StyledBgEmpty,
  StyledHyperlinkWidget,
  StyledHyperlinkWrapper,
  StyledImageWrapper,
  StyledSliderWrapper,
  StyledWrapperCarousel,
  StyledWrapperImageCarousel,
} from "../../../../../styled-components/CustomGallery/SliderGalleryStyled";
import GalleryFooter from "../../../../shared/section/GalleryFooter/GalleryFooter";
import ContentDetail from "../ContentDetail";
import FilterBar from "../GalleryFilter/FilterBar";
import MosaicGallery from "../MosaicGallery/MosaicGallery";
import ThumbnailMedia from "../ThumbnailMedia/ThumbnailMedia";
import UploaderModal from "../UploaderModal/UploaderModal";

const SliderGallery = (props) => {
  const sliderRef = useRef();
  const galleryContainerRef = useRef();

  const { contentList, galleryDetail } = props;
  const { metadata = {} } = galleryDetail;
  const {
    theme = "",
    showDetailOnHover,
    showDetailLightBox,
    rows,
    autoScroll,
    videoAutoPlay,
    visibleBg,
    galleryBackground,
    isUploaderPopup,
    galleryFilters,
  } = metadata || {};

  const defaultRows = 2;
  const rowsOfGallery = rows || defaultRows;

  const DEFAULT_CENTER = {
    DESKTOP: 2,
    TABLET: 1,
  };

  const defaultConfig = {
    deviceMode: SCREEN_MODE.GRID.XL,
    idxCenter: DEFAULT_CENTER.DESKTOP,
    slidesToShow: NUMBER_SLIDER_ITEM[3],
  };

  const [customContentList, setCustomContentList] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState(DEFAULT_CENTER.DESKTOP);
  const [dimension, setDimension] = useState();
  const [containerDimension, setContainerDimension] = useState();

  const [isDragging, setIsDragging] = useState(false);
  const [autoplay, setAutoplay] = useState(autoScroll);
  const [galleryConfig, setGalleryConfig] = useState(defaultConfig);
  const { deviceMode, slidesToShow, idxCenter } = galleryConfig;
  const [currentContent, setCurrentContent] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleUploader, setVisibleUploader] = useState(false);
  const { pendoTrackingData, isLoading, filtersApplied } =
    useContext(GalleryContext);

  useLayoutEffect(() => {
    const updateDevice = () => {
      if (galleryContainerRef.current) {
        handleGalleryConfig(galleryContainerRef);
        setContainerDimension({
          width: galleryContainerRef.current?.clientWidth,
          height: galleryContainerRef.current?.clientHeight,
        });
      }

      // The variable is used for the content detail modal
      setDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      resetSlider();
    };

    window.addEventListener("resize", updateDevice);
    updateDevice();
    return () => window.removeEventListener("resize", updateDevice);
  }, [galleryContainerRef.current]);

  const handleGalleryConfig = (galleryContainerRef) => {
    if (galleryContainerRef.current) {
      const mode = getScreenMode(
        galleryContainerRef.current?.clientWidth,
        theme
      );
      resetSlider();
      setDefaultCenter(mode.idxCenter);
      setGalleryConfig(mode);
    }
  };

  const resetSlider = () => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const isThumbnailGif = useMemo(() => {
    return videoAutoPlay || videoAutoPlay === undefined;
  }, [videoAutoPlay]);

  const themeType = useMemo(() => {
    return {
      isSignage: theme === GALLERY_THEME.SIGNAGE,
      isSlider: theme === GALLERY_THEME.SLIDER,
      isGrid: theme === GALLERY_THEME.GRID || !theme,
      isMosaic: theme === GALLERY_THEME.MOSAIC,
    };
  }, [theme]);

  const { isSignage, isSlider, isGrid, isMosaic } = themeType;

  useEffect(() => {
    const countOfContent = contentList.length;
    const newList = [...contentList];

    let countItem = NUMBER_SLIDER_ITEM[4];

    if (isSlider) {
      if (deviceMode === SCREEN_MODE.SLIDER.M) {
        countItem = NUMBER_SLIDER_ITEM[2];
      }

      if (
        countOfContent > 0 &&
        countOfContent < countItem &&
        deviceMode !== SCREEN_MODE.SLIDER.S
      ) {
        for (let i = 0; i < countItem - countOfContent; i += 1) {
          if (i % 2 === 0) {
            newList.push(null);
          } else {
            newList.unshift(null);
          }
        }
      }
    }
    setCustomContentList(newList);

    return () => {
      setCustomContentList([]);
    };
  }, [contentList, deviceMode]);

  const SliderArrow = (props) => {
    const onClick = () => {
      setAutoplay(false);
      props.onClick();
    };

    if (props.type === TYPE_ACTIONS.NEXT) {
      return <IconNextCarousel onClick={onClick} className={props.className} />;
    }

    return <IconBackCarousel onClick={onClick} className={props.className} />;
  };

  const isShowNavigationArrows = useMemo(
    () => (autoplay && metadata?.navigationArrows) || !autoplay,
    [metadata, autoplay]
  );

  const settings = useMemo(() => {
    let settingsTemp = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      swipeToSlide: true,
      initialSlide: 0,
      useTransform: false,
      nextArrow: isShowNavigationArrows ? (
        <SliderArrow type={TYPE_ACTIONS.NEXT} />
      ) : null,
      prevArrow: isShowNavigationArrows ? (
        <SliderArrow type={TYPE_ACTIONS.PREV} />
      ) : null,
      autoplay,
    };

    if (isSlider) {
      if (galleryContainerRef.current) {
        settingsTemp = {
          ...settingsTemp,
          slidesToShow,
        };
      }
    }

    if (autoplay && metadata?.autoplaySpeed) {
      const autoplaySpeed = metadata?.autoplaySpeed * 1000;
      settingsTemp = { ...settingsTemp, autoplaySpeed };
    }

    return settingsTemp;
  }, [autoplay, metadata, deviceMode, slidesToShow, galleryConfig]);

  const settingSlider = useMemo(() => {
    let settingsTemp = { ...settings };
    const countOfContent = customContentList.length;

    if (!metadata || isGrid) {
      const isInfinite =
        (deviceMode === SCREEN_MODE.GRID.XL &&
          countOfContent >= NUMBER_SLIDER_ITEM[3] * rowsOfGallery) ||
        (deviceMode === SCREEN_MODE.GRID.L &&
          countOfContent >= NUMBER_SLIDER_ITEM[2] * rowsOfGallery) ||
        (deviceMode === SCREEN_MODE.GRID.M &&
          countOfContent >= NUMBER_SLIDER_ITEM[1] * rowsOfGallery) ||
        deviceMode === SCREEN_MODE.GRID.S;

      settingsTemp = {
        ...settings,
        infinite: isInfinite,
        slidesToShow,
        rows:
          slidesToShow === NUMBER_SLIDER_ITEM[0]
            ? NUMBER_SLIDER_ITEM[0]
            : rowsOfGallery,
        slidesPerRow: 1,
        centerPadding: "0px",
      };
    } else {
      settingsTemp = {
        ...settings,
        infinite: isSlider ? countOfContent > NUMBER_SLIDER_ITEM[4] : true,
      };
    }

    if (
      deviceMode === SCREEN_MODE.SLIDER.S ||
      deviceMode === SCREEN_MODE.GRID.S ||
      (theme === GALLERY_THEME.MOSAIC && deviceMode === SCREEN_MODE.MOSAIC.S)
    ) {
      settingsTemp = {
        ...settingsTemp,
        centerMode: true,
        centerPadding: "11%",
        arrows: false,
      };
    }

    return {
      ...settingsTemp,
      lazyLoad: "ondemand",
    };
  }, [metadata, customContentList, deviceMode, slidesToShow]);

  const beforeChange = (oldIdx, index) => {
    let indexCenterTemp = 0;

    switch (deviceMode) {
      case SCREEN_MODE.SLIDER.L: {
        indexCenterTemp = index + 2;
        break;
      }

      default: {
        indexCenterTemp = index + 1;
        break;
      }
    }

    setGalleryConfig({ ...galleryConfig, idxCenter: indexCenterTemp });
  };

  const afterChange = () => {
    setIsDragging(false);
  };

  const renderClassName = (index) => {
    let moreClass = isSlider ? "slider " : "grid ";
    const countOfContent = customContentList.length;
    const subtractIndex = 1;

    const imgCenter =
      idxCenter >= countOfContent ? idxCenter - countOfContent : idxCenter;

    const imgCaLeft =
      idxCenter > countOfContent
        ? idxCenter - countOfContent - subtractIndex
        : idxCenter - subtractIndex;

    const imgCaRight =
      idxCenter + subtractIndex >= countOfContent
        ? idxCenter - countOfContent + subtractIndex
        : idxCenter + subtractIndex;

    const imgLeft = idxCenter - defaultCenter;
    const prefix = "image_carousel";

    if (containerDimension && containerDimension?.width > resolution.mobileXL) {
      switch (index) {
        case imgCenter:
          moreClass += `${prefix}_center`;
          break;

        case imgCaLeft:
          moreClass += `${prefix}_left`;
          break;

        case imgCaRight:
          moreClass += `${prefix}_right`;
          break;

        case imgLeft:
          moreClass += "image_left";
          break;

        default:
          moreClass += "image_right";
          break;
      }
    }

    return `${prefix} ${moreClass}`;
  };

  const handleBlockEvent = (e) => {
    e.stopPropagation();
  };

  const handlePendoTracking = (content) => {
    pendoTracking(PENDO_TRACKING_TYPE.SOCIAL_USERNAME_CLICKED, content);
    pendoTracking(PENDO_TRACKING_TYPE.GALLERY_SOCIALLINK_CLICKED);
  };

  const renderOverlay = (content, isLightbox) => {
    const { social, creatorProfile } = content;

    let instagramUsername =
      social && social.socialSource === SOCIAL.INSTAGRAM.toLowerCase()
        ? social.socialUsername
        : creatorProfile?.instagramUsername;
    const twitterUsername =
      social && social.socialSource === SOCIAL.TWITTER.toLowerCase()
        ? social.socialUsername
        : creatorProfile?.twitterUsername;

    instagramUsername = formatSocialUsername(
      instagramUsername,
      SOCIAL.INSTAGRAM.toLowerCase()
    );
    const hrefInstagram = `${SOCIAL_PROFILE.INSTAGRAM}${
      instagramUsername || ""
    }`;
    const hrefTwitter = `${SOCIAL_PROFILE.TWTTER}${twitterUsername || ""}`;

    if (showDetailOnHover && !isLightbox) {
      return (
        <StyledOverlay className="mark_hover">
          {instagramUsername &&
            showDetailOnHover?.includes(DETAIL_ON_HOVER?.showInstagramName) && (
              <StyledInfoItem
                onClick={(e) => {
                  handleBlockEvent(e);
                  handlePendoTracking(content);
                }}
                target="_blank"
                href={hrefInstagram}
              >
                <StyledSocialUsername>
                  <StyledIcon width="15px" height="15px">
                    <IconInstagram />
                  </StyledIcon>
                  {instagramUsername}
                </StyledSocialUsername>
              </StyledInfoItem>
            )}
          {twitterUsername &&
            showDetailOnHover?.includes(DETAIL_ON_HOVER?.showTwitterName) && (
              <StyledInfoItem
                onClick={(e) => {
                  handleBlockEvent(e);
                  handlePendoTracking(content);
                }}
                target="_blank"
                href={hrefTwitter}
              >
                <StyledSocialUsername>
                  <StyledIcon width="15px" height="15px">
                    <IconTwitter />
                  </StyledIcon>
                  {twitterUsername}
                </StyledSocialUsername>
              </StyledInfoItem>
            )}
        </StyledOverlay>
      );
    }

    if (showDetailLightBox && isLightbox) {
      return (
        <StyledOverlay className="mark_hover">
          {instagramUsername &&
            showDetailLightBox?.includes(
              DETAIL_LIGHTBOX?.showInstagramUsername
            ) && (
              <StyledInfoItem
                onClick={(e) => {
                  handleBlockEvent(e);
                  handlePendoTracking(content);
                }}
                target="_blank"
                href={hrefInstagram}
              >
                <StyledIcon width="15px" height="15px">
                  <IconInstagram />
                </StyledIcon>
                {instagramUsername}
              </StyledInfoItem>
            )}
          {twitterUsername &&
            showDetailLightBox?.includes(
              DETAIL_LIGHTBOX?.showInstagramUsername
            ) && (
              <StyledInfoItem
                onClick={(e) => {
                  handleBlockEvent(e);
                  handlePendoTracking(content);
                }}
                target="_blank"
                href={hrefTwitter}
              >
                <StyledIcon width="15px" height="15px">
                  <IconTwitter />
                </StyledIcon>
                {twitterUsername}
              </StyledInfoItem>
            )}
        </StyledOverlay>
      );
    }

    return null;
  };

  const handleSelectContent = (content) => {
    if (!isDragging && showDetailLightBox) {
      setVisible(true);
      setCurrentContent(content);
    }
  };

  const renderSliderGallery = () => {
    return (
      <Slider
        ref={sliderRef}
        beforeChange={beforeChange}
        afterChange={afterChange}
        onSwipe={() => {
          setIsDragging(true);
        }}
        {...settingSlider}
      >
        {customContentList.length &&
          customContentList.map((item, index) => {
            if (!item) {
              return (
                <StyledWrapperImageCarousel
                  className={renderClassName(index)}
                  key={`${item?.id}_${index}`}
                >
                  <StyledBgEmpty />
                </StyledWrapperImageCarousel>
              );
            }

            return (
              <StyledWrapperImageCarousel
                theme={theme}
                className={renderClassName(index)}
                key={`${item?.id}_${index}`}
              >
                <StyledImageWrapper
                  key={`${item?.id}_${index}_wrapper`}
                  onClick={() => handleSelectContent(item)}
                >
                  <ThumbnailMedia
                    content={item}
                    isThumbnailGif={isThumbnailGif}
                    renderOverlay={renderOverlay}
                  />
                </StyledImageWrapper>
              </StyledWrapperImageCarousel>
            );
          })}
      </Slider>
    );
  };

  const handleChangeContent = (type) => {
    const indexCurrent = contentList.findIndex(
      (item) => item?.id === currentContent?.id
    );
    if (indexCurrent !== -1) {
      let newIndex = indexCurrent + 1;
      if (type === TYPE_ACTIONS.NEXT) {
        if (indexCurrent === contentList.length - 1) {
          newIndex = 0;
        }
      } else {
        if (indexCurrent === 0) {
          newIndex = contentList.length - 1;
        } else {
          newIndex = indexCurrent - 1;
        }
      }
      setCurrentContent(contentList[newIndex]);
    }
  };

  const pendoTracking = (trackType, content) => {
    let data = {
      ...pendoTrackingData,
      trackType,
    };

    if (content) {
      data = { ...data, contentId: content.id };
    }
    utils.pendoTracking(data);
  };

  const renderUploaderLinkMsg = () => {
    const { uploaderLink } = metadata || {};
    const hyperlinkProps = isUploaderPopup
      ? {
          onClick: () => {
            setVisibleUploader(true);
            pendoTracking(PENDO_TRACKING_TYPE.UPLOADER_LINK_CLICKED);
          },
        }
      : {
          target: "_blank",
          href: uploaderLink ? utils.formatUrl(uploaderLink.url) : null,
        };

    if (uploaderLink && !isSignage) {
      return (
        <StyledHyperlinkWrapper>
          <StyledHyperlinkWidget {...hyperlinkProps}>
            {uploaderLink.text}
          </StyledHyperlinkWidget>
        </StyledHyperlinkWrapper>
      );
    }

    return null;
  };

  const renderGalleryContent = () => {
    const { uploaderLink, theme = "" } = metadata || {};

    let classNameTemp = "slider_carousel ";
    if (isSlider) {
      classNameTemp += "slider_theme";
    } else if (isGrid) {
      classNameTemp += "grid_theme";
    } else if (isSignage) {
      classNameTemp += "signage_theme";
    } else {
      classNameTemp += "mosaic_theme";
    }

    return (
      <StyleContainerCarousel
        ref={galleryContainerRef}
        rows={rowsOfGallery}
        theme={theme}
        className={classNameTemp}
        isShowNavigationArrows={isShowNavigationArrows}
        dimension={containerDimension}
        subtractedHeight={galleryFilters ? DIMENSION.FILTER.HEIGHT : 0}
        opacity={customContentList.length > 0 ? 1 : 0}
        padding={
          galleryFilters
            ? `${uploaderLink?.text ? 10 : 18}px 30px 30px 30px`
            : "30px"
        }
      >
        {renderUploaderLinkMsg()}
        {isMosaic || isSignage ? (
          <MosaicGallery
            settings={settingSlider}
            screenMode={deviceMode}
            galleryDetail={galleryDetail}
            containerDimension={containerDimension}
            contentList={contentList}
            renderOverlay={renderOverlay}
            handleSelectContent={handleSelectContent}
            arrowSlider={SliderArrow}
            isThumbnailGif={isThumbnailGif}
            metadata={metadata}
            setIsDragging={setIsDragging}
          />
        ) : (
          <StyledSliderWrapper>{renderSliderGallery()}</StyledSliderWrapper>
        )}

        {!isMosaic && !isSignage ? <GalleryFooter /> : null}
      </StyleContainerCarousel>
    );
  };

  const renderNoData = () => {
    return (
      contentList.length === 0 &&
      !isLoading && (
        <StyledSection
          color={THEME.colors.black}
          fontSize="20px"
          width="100%"
          textAlign="center"
          padding="24px 0"
        >
          {!_.isEmpty(filtersApplied) && t("gallery_filters.no_result")}
        </StyledSection>
      )
    );
  };

  const renderFilterBar = () => {
    if (galleryFilters) {
      return <FilterBar galleryDetail={galleryDetail} />;
    }
    return null;
  };

  const contextValues = {
    containerDimension,
  };

  return (
    <GalleryContainerContext.Provider value={contextValues}>
      <StyledSliderGallery theme={theme}>
        <StyledWrapperCarousel
          bgColor={visibleBg ? galleryBackground : THEME.colors.transparent}
          className="wrapper_carousel"
          theme={theme}
        >
          {renderFilterBar()}
          {!!contentList.length && renderGalleryContent()}
          {renderNoData()}
        </StyledWrapperCarousel>
        {currentContent && (
          <ContentDetail
            content={currentContent}
            visible={visible}
            onCancel={() => {
              setVisible(false);
              setCurrentContent(null);
            }}
            handleChangeContent={handleChangeContent}
            showDetailLightBox={showDetailLightBox}
            screenMode={deviceMode}
            dimension={dimension}
            className={isSlider ? "slider " : "grid "}
            pendoTrackingData={pendoTrackingData}
          />
        )}
      </StyledSliderGallery>
      {metadata.uploaderLink && visibleUploader && (
        <UploaderModal
          visible={visibleUploader}
          uploaderLink={metadata.uploaderLink}
          onCancel={() => {
            setVisibleUploader(false);
          }}
          dimension={dimension}
        />
      )}
    </GalleryContainerContext.Provider>
  );
};

SliderGallery.propTypes = {
  galleryDetail: PropTypes.object,
  contentList: PropTypes.array,
};

export default SliderGallery;

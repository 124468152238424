import { Divider, Form, Modal, Select, Spin } from "antd";
import { PREFIX_CLS } from "constants/common";
import { WIDTH } from "constants/gallery";
import THEME from "constants/themes";
import styled, { css } from "styled-components";

const StyledSection = styled.section`
  ${({ width }) => width && `width: ${width}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth} !important`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth} !important`};
  ${({ height }) => height && `height: ${height}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ background }) => background && `background: ${background}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
  ${({ display }) => display && `display: ${display}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  ${({ float }) => float && `float: ${float}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};

  &,
  * {
    font-family: "IBM Plex Sans" !important;
  }

  &.overflow {
    & ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray5};
    border-radius: 20px;
  }
`;

const StyledIconWrapper = styled.div`
  ${({ display }) => display && `display: ${display}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ disabled }) => disabled && "pointer-events: none"};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};

  svg {
    ${({ size }) => size && `width: ${size}px !important`};
    ${({ size }) => size && `height: ${size}px !important`};
    ${({ fill }) => fill && `fill: ${fill} !important`};

    path {
      ${({ fillPath }) => fillPath && `fill: ${fillPath} !important`};
      ${({ colorStroke }) => colorStroke && `stroke: ${colorStroke}`};
    }
  }
`;

const StyledModal = styled(Modal)`
  * {
    font-family: "IBM Plex Sans" !important;
    color: ${THEME.colors.darkBlue2};
  }

  .${PREFIX_CLS}-modal-header {
    border-radius: 12px 12px 0 0;

    .${PREFIX_CLS}-modal-title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .${PREFIX_CLS}-modal-body {
    padding: 16px 20px;
  }

  .${PREFIX_CLS}-modal-content {
    border-radius: 12px;
    margin: auto;

    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};

    .${PREFIX_CLS}-modal-close-x {
      svg {
        fill: ${THEME.colors.darkBlue2};
      }
    }
  }
`;

const StyledForm = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  ${({ display }) => display && `display: ${display}`};
  ${({ margin }) => margin && `margin: ${margin}`};

  .${PREFIX_CLS}-form-item-label {
    label {
      color: ${THEME.colors.darkBlue2} !important;
    }
  }
`;

const StyledSelect = styled(Select)`
  ${({ width }) => width && `width: ${width}px !important`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth} !important`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth} !important`};

  .${PREFIX_CLS}-select-selector {
    border-radius: 12px;
    min-height: 42px;
    border-color: ${THEME.colors.darkBlue2} !important;
    padding-inline-end: 34px !important;

    .${PREFIX_CLS}-select-selection-item {
      border-radius: 16px;
      border: none;
      height: 28px;
      line-height: 28px;
      padding: 0 12px;
      color: ${THEME.colors.darkBlue2} !important;

      ${({ selectionItemWidth }) =>
        selectionItemWidth &&
        css`
          &:not(:nth-child(2)) {
            max-width: ${({ selectionItemWidth }) => `${selectionItemWidth}px`};
          }
        `}

      &-remove {
        display: inline-flex;
      }
    }

    .${PREFIX_CLS}-select-selection-placeholder {
      ${({ fontSizePlaceholder }) =>
        fontSizePlaceholder && `font-size: ${fontSizePlaceholder}`};
    }
  }

  .${PREFIX_CLS}-select-clear {
    opacity: 1 !important;
    width: 36px;
    height: 36px;
    margin-top: -20px;
    padding: 10px 8px;
    inset-inline-end: 0px !important;
    background: transparent;
  }

  .${PREFIX_CLS}-select-arrow {
    svg {
      fill: ${THEME.colors.darkBlue2} !important;
    }
  }
`;

const StyledText = styled.span`
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};

  &.overflow {
    flex: none;
    max-width: ${WIDTH.FILTER_MENU.W_225}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.ropa {
    font-family: "Ropa Sans", sans-serif !important;
  }

  &.ibm_plex_sans {
    font-family: "IBM Plex Sans" !important;
  }
`;

const StyledTitle = styled.p`
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration}`};

  &.overflow {
    flex: none;
    max-width: ${WIDTH.FILTER_MENU.W_225}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledDivider = styled(Divider)`
  ${({ margin }) => margin && `margin: ${margin}`};

  &.${PREFIX_CLS}-divider-vertical {
    height: 38px;
    ${({ height }) => height && `height: ${height}`};
    border-inline-start: 2px solid ${THEME.colors.gray1};
  }
`;

const StyledSpin = styled(Spin)`
  &.${PREFIX_CLS}-spin {
    .${PREFIX_CLS}-spin-dot-item {
      background-color: ${THEME.colors.orangeBase} !important;
    }
  }
`;

const StyledExplicitOverlay = styled.div`
  position: absolute;
  ${({ margin }) => margin && `margin: ${margin}`};
  z-index: ${({ zIndex }) => zIndex || 1};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(22, 22, 22, 0.95);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;
  ${({ height }) => height && `max-height: ${height}px`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};

  * {
    color: ${THEME.colors.gray5};
  }
`;

export {
  StyledDivider,
  StyledExplicitOverlay,
  StyledForm,
  StyledFormItem,
  StyledIconWrapper,
  StyledModal,
  StyledSection,
  StyledSelect,
  StyledSpin,
  StyledText,
  StyledTitle,
};

import prodv2 from "./prodv2.json";
import qav2 from "./qav2.json";

const env = process.env.REACT_APP_ENV || "prodv2";

let APIServer = prodv2.API_SERVER;
let AppServer = prodv2.APP_SERVER;
let S3Server = prodv2.S3_SERVER;
let Env = "prodv2";
let socialS3URL = prodv2.SOCIAL_S3_URL;
let HomeUrl = prodv2.HOME_URL;
let landingURL = prodv2.LANDING_PAGE_URL;
let pendoKey = prodv2.PENDO_KEY;
let ipstackKey = prodv2.IPSTACK;

const trimedEnv = env.trim();

if (trimedEnv === "qav2") {
  APIServer = qav2.API_SERVER;
  AppServer = qav2.APP_SERVER;
  S3Server = qav2.S3_SERVER;
  socialS3URL = qav2.SOCIAL_S3_URL;
  HomeUrl = qav2.HOME_URL;
  landingURL = qav2.LANDING_PAGE_URL;
  pendoKey = qav2.PENDO_KEY;
  ipstackKey = qav2.IPSTACK;
  Env = "qav2";
} else if (trimedEnv === "prodv2") {
  APIServer = prodv2.API_SERVER;
  AppServer = prodv2.APP_SERVER;
  S3Server = prodv2.S3_SERVER;
  socialS3URL = prodv2.SOCIAL_S3_URL;
  HomeUrl = prodv2.HOME_URL;
  landingURL = prodv2.LANDING_PAGE_URL;
  pendoKey = prodv2.PENDO_KEY;
  ipstackKey = prodv2.IPSTACK;
  Env = "prodv2";
}

export {
  APIServer,
  AppServer,
  Env,
  S3Server,
  socialS3URL,
  HomeUrl,
  landingURL,
  pendoKey,
  ipstackKey,
};

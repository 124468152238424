import { Modal } from "antd";
import styled, { css } from "styled-components";
import resolution from "../../constants/resolution";
import THEME from "../../constants/themes";
import {
  StyledFlex,
  StyledIcon,
  StyledInfoItem,
  StyledOverlay,
} from "./CustomGalleryStyled";
import { PREFIX_CLS } from "constants/common";

const square = 595;
const landscape = 579;
const portrait = 712;

const squareSizeStyles = css`
  width: auto;
  height: auto;
  min-width: 400px;
  min-height: 400px;
  max-width: ${square}px;
  max-height: ${square}px;

  @media (max-width: ${resolution.minDesktop}px) {
    min-width: 300px;
    min-height: 300px;
    max-width: ${square - 195}px;
    max-height: ${square - 195}px;
  }

  @media (max-width: ${resolution.tabletM}px) {
    max-width: 512px;
    max-height: 512px;
    min-width: 512px;
    min-height: 512px;
  }

  @media (max-width: ${resolution.mobile}px) {
    max-width: 410px;
    max-height: 410px;
    min-width: 410px;
    min-height: 410px;
  }

  @media (max-width: 480px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
  }

  @media (max-width: 380px) {
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
  }
`;

const landscapeSizeStyles = css`
  width: auto;
  height: auto;
  min-width: ${landscape}px;
  min-height: 379px;
  max-width: 779px;
  max-height: ${landscape}px;

  @media (max-width: ${resolution.minDesktop}px) {
    min-width: ${landscape - 179}px;
    min-height: ${landscape - 179}px;
    max-width: ${landscape - 100}px;
    max-height: ${landscape - 100}px;
  }

  @media (max-width: ${resolution.tabletM}px) {
    max-width: 512px;
    max-height: 512px;
    min-width: 512px;
    min-height: 512px;
  }

  @media (max-width: ${resolution.mobile}px) {
    max-width: 410px;
    max-height: 410px;
    min-width: 410px;
    min-height: 410px;
  }

  @media (max-width: 480px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
  }

  @media (max-width: 380px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
  }
`;

const portraitSizeStyles = css`
  width: auto;
  height: auto;
  min-width: 318px;
  min-height: 512px;
  max-width: 518px;
  max-height: ${portrait}px;

  @media (max-width: ${resolution.minDesktop}px) {
    max-width: ${portrait - 200}px;
    max-height: ${portrait - 200}px;
    min-width: ${portrait - 200}px;
    min-height: ${portrait - 200}px;
  }

  @media (max-width: ${resolution.tabletM}px) {
    max-width: 512px;
    max-height: 512px;
    min-width: 512px;
    min-height: 512px;
  }

  @media (max-width: ${resolution.mobile}px) {
    max-width: 410px;
    max-height: 410px;
    min-width: 410px;
    min-height: 410px;
  }

  @media (max-width: 480px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
  }

  @media (max-width: 380px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    max-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
    min-height: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "275px"};
  }
`;

export const StyledContentModal = styled(Modal)`
  max-width: 1200px !important;
  width: auto !important;
  max-height: ${({ height }) =>
    height <= resolution.tabletS ? "90vh" : "unset"};
  height: unset !important;
  margin: 0 auto;

  .${PREFIX_CLS}-modal-body {
    padding: 0;
  }

  .${PREFIX_CLS}-modal-content {
    background: transparent;
    box-shadow: none;
    padding: 0 !important;
  }

  .${PREFIX_CLS}-modal-mask {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 480px) {
    .${PREFIX_CLS}-modal-content {
      padding: 0 !important;
    }
  }

  @media (max-width: ${resolution.mobileM - 15}px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width - 40}px` : "unset"};
  }
`;

export const StyledWrapperPopup = styled.div`
  position: relative;
`;

export const StyledIconChangeContent = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9999;

  &.prev {
    left: -15%;
  }

  &.next {
    right: -15%;
  }

  svg {
    fill: ${THEME.colors.white} !important;
    width: 40px;
    height: 40px;
  }

  @media (max-width: ${resolution.desktop10}px) {
    &.prev {
      left: -6%;
    }

    &.next {
      right: -6%;
    }
  }

  @media (max-width: ${resolution.tabletM}px) {
    height: 40px;
    width: 40px;

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  @media (max-width: 480px) {
    height: 40px;
    width: 40px;
    top: ${({ dimension }) => (dimension ? `${dimension.width / 2}px` : "50%")};
  }
`;

export const StyledWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  margin: auto;
`;

export const StyledThumbnail = styled.div`
  position: relative;
  width: 600px;
  height: 600px;

  &.responsive-content {
    width: unset;
    height: unset;
  }

  ${StyledOverlay} {
    background: unset;
    opacity: 1;
    height: 20%;
    top: 0;
    padding: 12px;
    background: linear-gradient(rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 90%);
    justify-content: unset;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    svg {
      &,
      path {
        fill: ${THEME.colors.white};
      }
    }

    ${StyledInfoItem} {
      color: ${THEME.colors.white};
    }
  }

  @media (max-width: ${resolution.desktop10}px) {
    width: ${({ dimension }) =>
      `${dimension?.width - 350 > 600 ? 600 : dimension?.width - 350}px`};
    height: ${({ dimension }) =>
      `${dimension?.width - 350 > 600 ? 600 : dimension?.width - 350}px`};
  }

  @media (max-width: ${resolution.tabletM}px) {
    width: unset;
    height: 100%;
  }

  @media (max-width: 480px) {
    max-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
    min-width: ${({ dimension }) =>
      dimension ? `${dimension.width}px` : "360px"};
  }
`;

export const StyledVideo = styled.video`
  background-color: rgba(22, 22, 22, 0.95);
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${({ isLoaded }) => `opacity: ${isLoaded ? 1 : 0}`};

  &.customize-item {
    object-fit: cover;

    &.square {
      ${squareSizeStyles}
    }

    &.landscape {
      ${landscapeSizeStyles}
    }

    &.portrait {
      ${portraitSizeStyles}
    }
  }

  &.responsive-content {
    width: auto;
    height: auto;
    min-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.width}px`};
    min-height: ${({ responsiveSize }) => `${responsiveSize.height}px`};
    max-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.width}px`};
    max-height: ${({ responsiveSize }) => `${responsiveSize.height}px`};

    @media (max-width: 480px) {
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
    }

    @media (max-width: 380px) {
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
    }
  }
`;

export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: rgba(22, 22, 22, 0.95);
  ${({ isLoaded }) => `opacity: ${isLoaded ? 1 : 0}`};

  &.customize-item {
    object-fit: cover;

    &.square {
      ${squareSizeStyles}
    }

    &.landscape {
      ${landscapeSizeStyles}
    }

    &.portrait {
      ${portraitSizeStyles}
    }
  }
  &.responsive-content {
    width: auto;
    height: auto;
    object-fit: cover;
    min-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.height}px`};
    min-height: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.height}px`};
    max-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.height}px`};
    max-height: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.height}px`};

    @media (max-width: 480px) {
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
    }

    @media (max-width: 380px) {
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
    }
  }
`;

export const StyledImgBg = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(22, 22, 22, 0.95);
  background-size: contain;
  background-image: ${({ src }) => `url('${src}')`};

  &.square {
    ${squareSizeStyles}
  }

  &.landscape {
    ${landscapeSizeStyles}
  }

  &.portrait {
    ${portraitSizeStyles}
  }

  &.responsive-content {
    width: auto;
    height: auto;
    min-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.width}px`};
    min-height: ${({ responsiveSize }) => `${responsiveSize.height}px`};
    max-width: ${({ responsiveSize, dimension }) =>
      dimension.width <= resolution.mobileL
        ? `${dimension.width - 100}px`
        : `${responsiveSize.width}px`};
    max-height: ${({ responsiveSize }) => `${responsiveSize.height}px`};

    @media (max-width: 480px) {
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "360px"};
    }

    @media (max-width: 380px) {
      min-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      min-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-width: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
      max-height: ${({ dimension }) =>
        dimension ? `${dimension.width}px` : "275px"};
    }
  }
`;

const subtractedContainer = 200;

const setWidthContentInfo = (isCustomizedModal, dimension) => {
  if (!isCustomizedModal) {
    if (dimension.height <= resolution.tabletS - subtractedContainer) {
      return "200px";
    }

    return "300px";
  }

  return "100% !important";
};

export const StyledWrapperContent = styled.div`
  padding: ${({ dimension }) =>
    dimension.height <= square - subtractedContainer
      ? "0 10px 10px 10px"
      : "0 30px 30px 30px"};
  height: calc(100% - 20px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray5};
    border-radius: 20px;
  }

  @media (max-width: ${resolution.tabletM}px) {
    padding: 0 10px 10px 10px;
  }
`;

export const StyledContainer = styled.div`
  width: ${({ isCustomizedModal, dimension }) =>
    setWidthContentInfo(isCustomizedModal, dimension)};
  background: rgba(22, 22, 22, 0.95);
  max-height: ${({ height }) => height}px;
  position: relative;
  padding: 40px 0;

  *:not(.footer) {
    font-family: IBM Plex Sans;
    font-size: 16px !important;
  }

  @media (max-width: 1200px) {
    width: ${({ dimension }) =>
      dimension.height <= resolution.tabletS - subtractedContainer / 2
        ? "200px"
        : "250px"};
  }

  @media (max-width: 768px) {
    width: 180px;
  }

  &.customize-container {
    ${StyledWrapperContent} {
      @media (max-width: ${resolution.tabletM}px) {
        max-height: 250px;
        min-height: 250px;
      }

      @media (max-width: ${resolution.tabletS}px) {
        max-height: 150px;
        min-height: 150px;
      }
    }

    @media (max-width: ${resolution.tabletM}px) {
      margin: -6px 0 0 0;
    }

    &.square {
      width: auto;
      height: auto;
      min-width: 400px;
      max-width: ${square}px;

      @media (max-width: ${resolution.tabletM}px) {
        max-height: 350px;
        min-height: 350px;
        max-width: 512px;
        min-width: 512px;
      }

      @media (max-width: ${resolution.tabletS}px) {
        max-height: 250px;
        min-height: 250px;
        max-width: 410px;
        min-width: 410px;
      }

      @media (max-width: 480px) {
        max-width: 100%;
        min-width: 100%;
      }

      @media (max-width: 380px) {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &.landscape {
      max-height: ${({ height }) =>
        height && height >= landscape ? landscape : `${height}px`};
      min-height: ${({ height }) =>
        height && height >= landscape ? landscape : `${height}px`};
      width: auto;
      height: auto;
      min-width: ${landscape}px;
      max-width: 779px;

      @media (max-width: ${resolution.tabletM}px) {
        max-height: 350px;
        min-height: 350px;
        max-width: 512px;
        min-width: 512px;
      }

      @media (max-width: ${resolution.tabletS}px) {
        max-height: 250px;
        min-height: 250px;
        max-width: 410px;
        min-width: 410px;
      }

      @media (max-width: 480px) {
        max-width: 100%;
        min-width: 100%;
      }

      @media (max-width: 380px) {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &.portrait {
      max-height: ${({ height }) =>
        height && height >= portrait ? portrait : `${height}px`};
      min-height: ${({ height }) =>
        height && height >= portrait ? portrait : `${height}px`};
      width: auto;
      height: auto;
      min-width: 318px;

      max-width: 518px;

      @media (max-width: ${resolution.tabletM}px) {
        max-height: 350px;
        min-height: 350px;
        max-width: 512px;
        min-width: 512px;
      }

      @media (max-width: ${resolution.tabletS}px) {
        max-height: 250px;
        min-height: 250px;
        max-width: 410px;
        min-width: 410px;
      }

      @media (max-width: 480px) {
        max-width: 100%;
        min-width: 100%;
      }

      @media (max-width: 380px) {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &.responsive-content {
      width: auto;
      height: auto;
      min-width: ${({ responsiveSize, dimension }) =>
        dimension.width <= resolution.mobileL
          ? `${dimension.width - 100}px`
          : `${responsiveSize.height}px`};
      min-height: fit-content;
      max-width: ${({ responsiveSize, dimension }) =>
        dimension.width <= resolution.mobileL
          ? `${dimension.width - 100}px`
          : `${responsiveSize.height}px`};
      max-height: fit-content;

      @media (max-width: 480px) {
        max-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "360px"};
        min-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "360px"};
      }

      @media (max-width: 380px) {
        min-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "275px"};
        max-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "275px"};
      }
    }

    &.responsive-content-video {
      width: auto;
      height: auto;
      min-width: ${({ responsiveSize, dimension }) =>
        dimension.width <= resolution.mobileL
          ? `${dimension.width - 100}px`
          : `${responsiveSize.width}px`};
      min-height: fit-content;
      max-width: ${({ responsiveSize, dimension }) =>
        dimension.width <= resolution.mobileL
          ? `${dimension.width - 100}px`
          : `${responsiveSize.width}px`};
      max-height: fit-content;

      @media (max-width: 480px) {
        max-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "360px"};
        min-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "360px"};
      }

      @media (max-width: 380px) {
        min-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "275px"};
        max-width: ${({ dimension }) =>
          dimension ? `${dimension.width}px` : "275px"};
      }
    }
  }
`;

export const StyledText = styled.p`
  ${({ color }) => color && `color: ${color}`};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  cursor: ${({ cursor }) => cursor};
`;

export const StyledCaption = styled.div`
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || "12px"};
  color: ${THEME.colors.white};
  margin: ${({ margin }) => margin || "10px 0"};

  a {
    text-decoration: underline;
    color: ${THEME.colors.blueBase};
  }
`;

export const StyledNameUser = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.colors.white};
  word-break: break-all;
  margin: ${({ margin }) => margin || "0"};

  &:hover {
    color: ${THEME.colors.orangeBase};
  }

  svg {
    margin-right: 5px;
  }
`;

export const StyledFooterLightBox = styled.div`
  position: absolute;
  bottom: ${({ isCustomizedModal }) => (isCustomizedModal ? "-10px" : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  color: ${THEME.colors.white};

  @media (max-width: ${resolution.tablet}px) {
    ${StyledText} {
      font-size: 12px;
    }
  }
`;

export const StyledIconClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.customize-icon-close {
    position: fixed;

    &,
    &-responsive {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &-responsive {
      position: relative;
      float: right;
      margin: 0 0 16px 0;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    fill: ${THEME.colors.gray5};
  }
`;

export const StyledSocialUsername = styled(StyledFlex)`
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  cursor: pointer;

  a {
    margin-top: 1.5px;
    text-decoration: none !important;
  }

  &:hover {
    a {
      color: ${THEME.colors.orangeBase} !important;
      text-decoration: none !important;
    }

    ${StyledIcon} {
      svg {
        &,
        path {
          fill: ${THEME.colors.orangeBase} !important;
        }
      }
    }
  }
`;

export const StyledContentMedia = styled.div`
  position: relative;
`;

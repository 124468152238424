import { Modal } from "antd";
import { PREFIX_CLS } from "constants/common";
import resolution from "constants/resolution";
import styled from "styled-components";

const StyledUploaderModal = styled(Modal)`
  width: fit-content;

  .${PREFIX_CLS}-modal-content {
    padding: 0;
    border-radius: unset;
    background-color: unset;
    box-shadow: unset;

    .${PREFIX_CLS}-modal-body {
      padding: 0 !important;
    }
  }
`;

const StyledIconClose = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  cursor: pointer;
  z-index: 99;

  svg {
    fill: #808a93;
  }

  @media only screen and (max-width: ${resolution.tabletM}px) {
    top: 0;
    right: 0;

    svg {
      fill: #ccd0d4;
    }
  }
`;

const StyledIframe = styled.iframe`
  background-color: #fff;
  overflow: auto;
  border-width: 0px;
  border-radius: 18px;
  height: ${({ dimension }) => `calc(${dimension.height}px - 200px)`};
  width: 800px;

  @media only screen and (max-width: ${resolution.tablet}px) {
    width: 769px;
  }

  @media only screen and (max-width: ${resolution.tabletM + 100}px) {
    width: ${({ dimension }) =>
      `${dimension.width ? dimension.width - 50 : 380}px`};
  }

  @media only screen and (max-width: ${resolution.tabletM}px) {
    margin-top: 32px;
    height: ${({ dimension }) => `calc(${dimension.height}px - 60px)`};
  }

  @media only screen and (max-width: ${resolution.mobileL}px) {
    width: ${({ dimension }) =>
      `${dimension.width ? dimension.width - 32 : 380}px`};
  }
`;

export { StyledUploaderModal, StyledIframe, StyledIconClose };

import Slider from "react-slick";
import styled, { css } from "styled-components";
import resolution from "../../constants/resolution";
import THEME from "../../constants/themes";
import { StyledOverlay } from "./CustomGalleryStyled";
import { GALLERY_THEME, PADDING_FRAME } from "constants/gallery";

const sliderWidth = {
  L: "100%",
};

const gutter = "12px";
const padding = PADDING_FRAME;

const setGrid = (
  rows = 2,
  cols = 4,
  theme = GALLERY_THEME.MOSAIC,
  height,
  signageMessageHeight = 0
) => {
  if (theme === GALLERY_THEME.SIGNAGE) {
    const practicalHeight = height - padding * 2 - signageMessageHeight;
    const rowHeight = `calc((${practicalHeight}px - ${
      rows === 1 ? "0px" : gutter
    }) / ${rows})`;

    if (practicalHeight <= 0) {
      return {
        config: `repeat(${rows}, 1fr) / repeat(${cols}, 1fr)`,
      };
    }

    return {
      config: `repeat(${rows}, ${rowHeight}) / repeat(${cols}, 1fr)`,
    };
  }

  return {
    config: `repeat(${rows}, 1fr) / repeat(${cols}, 1fr)`,
  };
};

export const StyledMosaicWrapper = styled.div`
  width: 100% !important;
  max-height: 100%;
  position: relative;
  margin: 0 auto;
`;

export const StyledMosaicContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid !important;
  justify-content: center;

  grid-gap: ${gutter};
  grid-auto-flow: column dense !important;
  grid: ${setGrid(2, 4, GALLERY_THEME.MOSAIC).config};

  &.signage-container {
    grid: ${({ height, signageMessageHeight }) =>
      `${
        setGrid(2, 4, GALLERY_THEME.SIGNAGE, height, signageMessageHeight)
          .config
      }`};
  }

  ${({ width }) =>
    width &&
    width <= resolution.tabletM &&
    width > resolution.mobileL1 &&
    css`
      grid: ${setGrid(2, 3).config};

      &.signage-container {
        grid: ${({ height, signageMessageHeight }) =>
          `${
            setGrid(2, 3, GALLERY_THEME.SIGNAGE, height, signageMessageHeight)
              .config
          }`};
      }
    `}

  ${({ width }) =>
    width &&
    width <= resolution.mobileL1 &&
    css`
      height: fit-content;
      grid: ${setGrid(1, 1).config};

      &.signage-container {
        grid: ${({ height, signageMessageHeight }) =>
          `${
            setGrid(1, 1, GALLERY_THEME.SIGNAGE, height, signageMessageHeight)
              .config
          }`};
      }

      ${StyledMosaicItem} {
        &.active-item {
          grid-row: unset;
          grid-column: unset;
        }
      }
    `}
`;

export const StyledSlider = styled(Slider)`
  width: ${sliderWidth.L};
  margin: 0 auto;

  .slick-prev {
    left: -18px !important;
  }

  .slick-next {
    right: -18px;
  }

  .slick-prev,
  .slick-next {
    z-index: 99;
    height: 40px;
    width: 40px;

    &:active {
      rect {
        fill: ${THEME.colors.orangeBase};
      }
    }
  }
`;

export const StyledMosaicItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;

  &:not(.signage.none_aspect_ratio) {
    aspect-ratio: 1 / 1;
  }

  &.custom-border-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover ${StyledOverlay} {
    opacity: 1;
  }

  &.active-item {
    grid-row: 1 / span 2;
    grid-column: ${({ columnStart }) => `${columnStart}/ span 2`};

    &.custom-border-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const styleSignageMessage = (width) => {
  switch (true) {
    case width <= resolution.desktop19 && width > resolution.minDesktop:
      return css`
        &,
        p {
          font-size: 36px;
        }

        .qr_code {
          width: 124px;
          height: 124px;
        }
      `;

    case width <= resolution.minDesktop && width > resolution.desktop10:
      return css`
        &,
        p {
          font-size: 28px;
        }

        .qr_code {
          width: 100px;
          height: 100px;
        }
      `;

    case width <= resolution.desktop10 && width > resolution.tablet:
      return css`
        &,
        p {
          font-size: 26px;
        }

        .qr_code {
          width: 90px;
          height: 90px;
        }
      `;

    case width <= resolution.tablet && width > resolution.mobile:
      return css`
        &,
        p {
          font-size: 24px;
        }

        .qr_code {
          width: 82px;
          height: 82px;
        }
      `;

    case width <= resolution.mobile && width > resolution.mobileL:
      return css`
        &,
        p {
          font-size: 18px;
        }

        .qr_code {
          width: 70px;
          height: 70px;
        }
      `;

    case width <= resolution.mobileL:
      return css`
        &,
        p {
          font-size: 16px;
        }

        .qr_code {
          width: 65px;
          height: 65px;
        }
      `;

    default:
      return css`
        &,
        p {
          font-size: 46px;
        }

        .qr_code {
          width: 132px;
          height: 132px;
        }
      `;
  }
};

export const StyledSignageMessage = styled.div`
  margin: -2px auto 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  background-color: ${({ ctaBackground }) =>
    ctaBackground || THEME.colors.white};
  color: ${({ fontQRCodeColor }) => fontQRCodeColor || THEME.colors.black};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px;
  width: ${sliderWidth.L};
  text-align: right;
  line-height: 1.15 !important;

  ${({ width }) => width && styleSignageMessage(width)};
`;

export const StyledSignageMessageText = styled.p`
  display: -webkit-box;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  margin: 0;
  padding-right: ${({ hasQRCode }) =>
    hasQRCode ? "22px" : "0"}; // to show ellipsis

  ${({ width }) =>
    width &&
    width <= resolution.tabletL &&
    css`
      padding-right: ${({ hasQRCode }) =>
        hasQRCode ? "16px" : "0"}; // to show ellipsis
    `}
`;

export const StyledQRCode = styled.div`
  display: inline-block;
  background-color: #fff;
  text-align: right;

  svg {
    width: 100%;
    height: 100%;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

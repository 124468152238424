export default {
  desktop19: 1920,
  minDesktop: 1440,
  desktop10: 1024,
  tablet: 991,
  tabletL: 900,
  tabletM: 768,
  tabletS: 600,
  mobile: 576,
  mobileXL: 500,
  mobileL1: 478,
  mobileL: 425,
  mobileM: 375,
  mobileS: 320,
};

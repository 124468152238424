/* eslint-disable */
import React from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.scss";

import store from "./stores";
import { Provider } from "react-redux";
import { CLASS_GALLERY, PREFIX_CLS } from "constants/common";
import utils from "services/utils";

// Import i18n
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import { ConfigProvider } from "antd";
import THEME from "constants/themes";

document.querySelectorAll(`.${CLASS_GALLERY}`).forEach((elm) => {
  const clientIdGlobal = utils.decodeFromBase64(elm?.dataset?.clientId);
  const galleryIdGlobal = elm?.dataset?.galleryId || undefined;
  if (elm?._reactRootContainer) {
    unmountComponentAtNode(elm);
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            prefixCls={PREFIX_CLS}
            theme={{
              components: {
                Modal: { wireframe: true },
              },
              token: {
                colorPrimary: THEME.colors.darkBlue2,
                colorPrimaryHover: THEME.colors.darkBlue2,
                controlOutline: "transparent",
                controlItemBgActive: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <App
              clientIdGlobal={clientIdGlobal}
              galleryIdGlobal={galleryIdGlobal}
            />
          </ConfigProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
    elm
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import PropTypes from "prop-types";
import { Component, Suspense } from "react";
import { Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import {
  IoIosArrowDropleft,
  IoIosArrowDropleftCircle,
  IoIosArrowDropright,
  IoIosArrowDroprightCircle,
  IoIosPlay,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";
// import Preload from "react-preload";
import SmoothImage from "components/shared/SmoothImage";
import { S3Server } from "../../../../config";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import "./CircleK.scss";

const logo = `${S3Server}/gallery/logo-black.png`;

const loadingIndicator = (
  <div className="gallery-preloader">
    <h5>Loading..</h5>
  </div>
);

class CircleK extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    setIsShowLoading: () => {},
    globalProps: {
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      contents: [],
      allContents: [],
      contentDetail: null,
      contentDetailIndex: 0,
      creators: [],
      isDisabledWidget: false,
      pageCount: 1,
      page: 0,
      isOpenImageDetail: false,
      isOpenUploadWidget: false,
      errorMsg: "",
      // Search / Filter
      keyword: "",
      creator: null,
      productsDepicted: null,
      productsUsed: null,
      creationTheme: null,
      creationCategory: null,
      browserWidth: 0,
    };

    this.customInput = {};
  }

  componentWillMount() {
    this.getLocationAPI();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };

  getLocationAPI = () => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const url = configUrls.externalAPI.getLocation;
    const successCallback = (resp) => {
      this.setState({ browserWidth: this.getWidth() }, () => {
        this.getGallery();
      });
      this.setState({ location: resp });
      const {
        location: { pathname = "" },
        globalProps: { sendTrackingPendoData },
      } = this.props;
      const pathName = pathname.includes("/:")
        ? utils.removeFirstSlash(pathname).split("/")[0]
        : pathname;
      const clientId = utils.getClientIdByName(pathName);
      sendTrackingPendoData(resp, clientId);
    };
    const failedCallback = (resp) => {};
    const errorCallback = () => {};

    requestAPI(
      "get",
      url,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      true
    );
  };

  getGallery() {
    const {
      globalProps: { requestAPI, sendTrackingPendoData },
      location: { pathname = "" },
    } = this.props;

    const { browserWidth } = this.state;

    const pathName = pathname.includes("/:")
      ? utils.removeFirstSlash(pathname).split("/")[0]
      : pathname;
    const {
      location: { search = "" },
    } = this.props;
    const gid = utils.getGalleryFolderId(search);
    const defaultGalleryId = utils.getGalleryFolderIdByName(pathName);
    const clientId = utils.getClientIdByName(pathName);
    sessionStorage.setItem("clientId", clientId);
    sessionStorage.setItem("clientName", utils.removeFirstSlash(pathName));
    const data = {
      maxRecords: 9999,
      page: 1,
      clientId: parseInt(clientId),
      galleryId: parseInt(gid == 0 ? defaultGalleryId : gid),
    };

    const successCallback = (resp) => {
      this.setState({ allContents: resp }, () => {
        this.showGallery(0);
      });
    };

    const failedCallback = (resp) => {
      this.setState({
        isDisabledWidget: true,
        errorMsg: resp.status.status || errorMsgs.callAPIFailed,
      });
    };

    const errorCallback = () => {
      this.setState({
        isDisabledWidget: true,
        errorMsg: errorMsgs.callAPIFailed,
      });
    };

    if (clientId) {
      const url = `${configUrls.API.getGallery}`;
      requestAPI(
        "put",
        url,
        data,
        successCallback,
        failedCallback,
        errorCallback
      );
    } else {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Page Not Found",
      });
    }
  }

  showGallery = (page) => {
    const { browserWidth, allContents = [] } = this.state;
    const itemsPerPage = browserWidth >= 768 ? 12 : 8;
    const total = allContents.length;
    const pageCount =
      total < itemsPerPage
        ? 1
        : total < itemsPerPage
        ? Math.ceil(total / itemsPerPage)
        : Math.ceil(total / itemsPerPage);
    const contents = allContents.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );

    this.setState({
      contents,
      pageCount,
    });
  };

  getMaxContentDetailIndex = () => {
    const { contents = [] } = this.state;
    return contents.length === 0 ? 0 : contents.length - 1;
  };

  handlePageChange = (page) => {
    this.showGallery(page.selected);
  };

  handleOpenImageDetail = (isOpen, contentDetailIndex) => {
    const { contents = [] } = this.state;
    const maxContentDetailIndex = this.getMaxContentDetailIndex();

    if (contentDetailIndex < 0) {
      contentDetailIndex = 0;
    }

    if (contentDetailIndex >= maxContentDetailIndex) {
      contentDetailIndex = maxContentDetailIndex;
    }
    const contentContainer = contents[contentDetailIndex];
    this.setState({
      isOpenImageDetail: isOpen,
      contentDetailIndex,
      contentDetail: contentContainer
        ? contentContainer.content
          ? contentContainer.content
          : contentContainer.select
        : null,
    });
  };

  handleOpenUploadWidget = (isOpen) => {
    this.setState({ isOpenUploadWidget: isOpen });
  };

  handleChangeKeyword = (e) => {
    this.setState({ keyword: e.target.value });
  };

  render() {
    const {
      isDisabledWidget,
      contents = [],
      contentDetail = {},
      contentDetailIndex,
      pageCount,
      page,
      isOpenImageDetail,
      isOpenUploadWidget,
      errorMsg,
    } = this.state;

    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";

    const { contentUrl = "", type = "" } = isOpenImageDetail
      ? contentDetail
      : {};
    const maxContentDetailIndex = this.getMaxContentDetailIndex();
    const isDisabledPreviousIcon = contentDetailIndex <= 0;
    const isDisabledNextIcon = contentDetailIndex >= maxContentDetailIndex;
    let preloadImages = [];

    contents.forEach((contentContainer) => {
      const content = contentContainer.content
        ? contentContainer.content
        : contentContainer.select;
      preloadImages.push(`${utils.getSocialS3URL(content.thumbnail)}`);
    });

    return (
      <div className={`widget-circlek-gallery ${widgetContainerClasses}`}>
        <div className="container">
          <div className="widget-block widget-gallery">
            <div className="widget-body">
              <div className="widget-content">
                <div className="gallery">
                  <Suspense fallback={loadingIndicator}>
                    <div className="row row-cols-3">
                      {contents &&
                        contents.length > 0 &&
                        contents.map((item, index) => (
                          <div className="col" key={index}>
                            <div
                              className="gallery-item"
                              onClick={() =>
                                this.handleOpenImageDetail(true, index)
                              }
                            >
                              <SmoothImage
                                src={`${utils.getSocialS3URL(
                                  item.content
                                    ? item.content.thumbnail
                                    : item.select.thumbnail
                                )}`}
                                alt="content gallery image"
                              />

                              {item.type === "video" && (
                                <div className="gallery-icon-play">
                                  <IconContext.Provider
                                    value={{
                                      className: "react-icons",
                                      size: "1.25rem",
                                      color: "#fff",
                                    }}
                                  >
                                    <IoIosPlay />
                                  </IconContext.Provider>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                      {!errorMsg && contents.length <= 0 && (
                        <span>No records found</span>
                      )}
                      {errorMsg && (
                        <div className="error-msg">
                          <p>{errorMsg} &nbsp;</p>
                        </div>
                      )}
                    </div>
                  </Suspense>
                </div>
              </div>
              {/* / Widget Content */}
            </div>

            <div className="widget-footer">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                initialPage={page}
                forcePage={page}
                disableInitialCallback={true}
              />
              <div className="logo">
                <span>Powered By</span>
                <a href="https://www.entribe.com" target="_blank">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            {/* End Footer */}
          </div>
        </div>

        <Modal
          show={isOpenImageDetail}
          size="lg"
          aria-labelledby="Gallery image"
          dialogClassName="circlek-gallery-modal"
          onHide={() => this.handleOpenImageDetail(false, contentDetailIndex)}
          centered
        >
          <Modal.Body>
            <div className="modal-container">
              <a
                className="close-icon"
                onClick={() =>
                  this.handleOpenImageDetail(false, contentDetailIndex)
                }
              >
                <IconContext.Provider value={{ size: "2rem" }}>
                  <BsX />
                </IconContext.Provider>
              </a>
              {contentUrl && (
                <div className="image-container ">
                  {type === "video" ? (
                    <video
                      className="gallery-video custom"
                      src={`${utils.getSocialS3URL(contentUrl)}`}
                      controls
                      autoPlay="autoplay"
                      width="100%"
                      onError={(e) => utils.handleThumbnailLoadedError(e)}
                    />
                  ) : (
                    <SmoothImage
                      src={`${utils.getSocialS3URL(contentUrl)}`}
                      alt="content gallery image"
                    />
                  )}
                </div>
              )}
            </div>

            <div className="slide-control">
              <IconContext.Provider value={{ size: "2rem" }}>
                <a
                  className="prev-icon"
                  onClick={() =>
                    this.handleOpenImageDetail(true, contentDetailIndex - 1)
                  }
                  disabled={isDisabledPreviousIcon}
                >
                  {utils.isMobileWidth() ? (
                    <IoIosArrowDropleftCircle />
                  ) : (
                    <IoIosArrowDropleft />
                  )}
                </a>
                <a
                  className="next-icon"
                  onClick={() =>
                    this.handleOpenImageDetail(true, contentDetailIndex + 1)
                  }
                  disabled={isDisabledNextIcon}
                >
                  {utils.isMobileWidth() ? (
                    <IoIosArrowDroprightCircle />
                  ) : (
                    <IoIosArrowDropright />
                  )}
                </a>
              </IconContext.Provider>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={isOpenUploadWidget}
          aria-labelledby="Upload modal"
          dialogClassName="circlek-upload-modal"
          backdrop="static"
          onHide={() => this.handleOpenUploadWidget(false)}
          centered
        >
          <Modal.Body>
            <a
              className="close-icon"
              onClick={() => this.handleOpenUploadWidget(false)}
            >
              <IconContext.Provider value={{ size: "2rem" }}>
                <BsX />
              </IconContext.Provider>
            </a>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CircleK);

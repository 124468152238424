export default {
  tl: {
    circlek: 913,
    teampixel: 1163, // Account: cl1, Challenge: #teampixel,
    inkedibles: 1163, // Account: cl1, Challenge: #teampixel,
  },
  qa: {
    circlek: 913,
    teampixel: 1257, // Account: teampixel, Challenge: #teampixel,
    inkedibles: 1256, // Account: inkedibles, Challenge: #inkedibles,
  },
  prod: {
    circlek: 299,
    teampixel: 434,
    inkedibles: 446,
  },
  qav2: {
    teampixel: 87, // Account: teampixel
    inkedibles: 3, // Account: inkedibles
    circlek: 4,
  },
  prodv2: {
    teampixel: 87, // Account: teampixel
    inkedibles: 4, // Account: inkedibles
    circlek: 4,
  },
};

import { useForm } from "antd/es/form/Form";
import { IconFilter } from "assets/icons";
import ScrollableWrapper from "components/shared/section/ScrollableWrapper";
import { DIMENSION, WIDTH } from "constants/gallery";
import THEME from "constants/themes";
import GalleryContext from "contexts/Gallery";
import GalleryContainerContext from "contexts/GalleryContainer";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertOptions } from "services/gallery";
import utils from "services/utils";
import {
  StyledDivider,
  StyledForm,
  StyledIconWrapper,
  StyledSection,
  StyledText,
} from "styled-components/CommonStyled";
import {
  StyledFilterDivider,
  StyledFilters,
  StyledTextClearAll,
} from "styled-components/CustomGallery/GalleryFilterStyled";
import CustomizedSelect from "../CustomizedSelect";
import FilterPopup from "../FilterPopup";

const FilterBar = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { customPropertyList, setFiltersApplied, filtersApplied } =
    useContext(GalleryContext);
  const { containerDimension } = useContext(GalleryContainerContext);

  const CLASS_NAME = "scrollable_wrapper";

  const [modalType, setModalType] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const MODAL_TYPE = {
    FILTER: "FILTER",
  };

  useEffect(() => {
    return () => {
      setIsVisible(false);
    };
  }, [containerDimension]);

  useEffect(() => {
    if (!_.isEmpty(filtersApplied)) {
      form.setFieldsValue({ ...filtersApplied });
    } else {
      form.resetFields();
    }
  }, [filtersApplied]);

  const additionalValues = useMemo(() => {
    const elContainer = document.querySelector(`.${CLASS_NAME}`);
    let className = `${CLASS_NAME} `;
    const otherSelectProps = {};
    let isOverflowX = false;

    if (containerDimension && elContainer) {
      // If the container el is overflow, remove the prop getPopupContainer
      if (elContainer) {
        isOverflowX = utils.isOverflowX(elContainer);
      }

      if (!isOverflowX) {
        className += "disable_scrolling";
        otherSelectProps.getPopupContainer = (trigger) => trigger.parentElement; // getPopupContainer does not work when the container has scrolling
      }
    }

    return {
      className,
      otherSelectProps,
      wrapperWidth: containerDimension?.width - 75,
    };
  }, [containerDimension, filtersApplied, isVisible]);

  const handleModal = (type) => {
    setModalType(type);
  };

  const renderFilter = () => {
    return (
      <ScrollableWrapper
        className={additionalValues?.className}
        wrapperWidth={`${additionalValues?.wrapperWidth}px`}
        callback={() => {
          setIsVisible(true);
        }}
      >
        <StyledFilters
          onClick={() => handleModal(MODAL_TYPE.FILTER)}
          display="flex"
          justify="flex-start"
          cursor="pointer"
          color={THEME.colors.darkBlue2}
        >
          <StyledText className="ibm_plex_sans" color={THEME.colors.darkBlue2}>
            {_.isEmpty(filtersApplied)
              ? t("gallery_filters.all_filters")
              : t("gallery_filters.filters_applied")}
          </StyledText>
          <StyledIconWrapper
            display="inline-flex"
            margin="0 4px"
            fillPath={
              !_.isEmpty(filtersApplied) ? THEME.colors.darkBlue2 : "none"
            }
          >
            <IconFilter />
          </StyledIconWrapper>
        </StyledFilters>
        {!_.isEmpty(filtersApplied) && <StyledFilterDivider type="vertical" />}
        {customPropertyList.map((item, index) => {
          if (item) {
            const key = `${item.label}_${index}`;
            const className = `${item.fieldName}_${index}`;
            return (
              item?.label && (
                <CustomizedSelect
                  key={key}
                  className={className}
                  label={item.label}
                  text={t("gallery_filters.filter_by", {
                    field: item.label,
                  })}
                  options={convertOptions(item.value)}
                  selectWidth={WIDTH.FILTER_MENU.W_225}
                  fieldName={item.fieldName}
                  selectedValues={
                    filtersApplied ? filtersApplied[item.fieldName] : []
                  }
                  otherSelectProps={additionalValues?.otherSelectProps}
                />
              )
            );
          }

          return (
            <StyledDivider key={index} margin="0 24px 0 0" type="vertical" />
          );
        })}
        {!_.isEmpty(filtersApplied) && (
          <>
            <StyledTextClearAll className="ibm_plex_sans" onClick={clearAll}>
              {t("gallery_filters.clear_all")}
            </StyledTextClearAll>
          </>
        )}
      </ScrollableWrapper>
    );
  };

  const onValuesChange = (changedValue) => {
    const fieldName = Object.keys(changedValue)[0];
    const selectedValues = { ...filtersApplied, ...changedValue };
    if (changedValue[fieldName].length === 0) {
      delete selectedValues[fieldName];
    }
    setFiltersApplied(selectedValues);
  };

  const clearAll = () => {
    setFiltersApplied({});
    form.resetFields();
  };

  return (
    <>
      <StyledSection
        padding="16px 32px 4px 32px"
        fontSize="16px"
        display="flex"
        height={`${DIMENSION.FILTER.HEIGHT}px`}
        alignItems="center"
        whiteSpace="nowrap"
      >
        <StyledForm form={form} onValuesChange={onValuesChange}>
          {containerDimension && renderFilter()}
        </StyledForm>
      </StyledSection>
      {modalType === MODAL_TYPE.FILTER && (
        <FilterPopup
          visible={modalType === MODAL_TYPE.FILTER}
          onCancel={() => handleModal("")}
          filtersApplied={filtersApplied}
          setFiltersApplied={setFiltersApplied}
          filterList={customPropertyList}
        />
      )}
    </>
  );
};

export default FilterBar;

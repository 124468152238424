import { APIServer } from "../config";

// StatusCode
const STATUS_CODE = {
  SUCCESS: 200,
  FAILED404: 404,
  FAILED400: 400,
  FAILED401: 401,
  FAILED504: 504,
};

const CLIENT_SETTING = `${APIServer}/common/public/client/settings/whiteLabeling`;

// Social
export const SOCIAL_SEARCH_API_URL = `${APIServer}/social`;
export const SOCIAL_REDIRECTED_API_URL = `${SOCIAL_SEARCH_API_URL}/callback/media/redirected-url`;
export const SOCIAL_INSTAGRAM_CONTENT = `${SOCIAL_SEARCH_API_URL}/callback/media/instagram/video-data`;
export const SOCIAL_TIKTOK_CONTENT = `${SOCIAL_SEARCH_API_URL}/callback/media/tiktok/content-url`;
export const SOCIAL_YOUTUBE_CONTENT = `${SOCIAL_SEARCH_API_URL}/callback/media/youtube/content-url`;

// Content
export const CONTENT_API_URL = `${APIServer}/content`;
export const URL_CONTENT_EXTERNAL_API = `${CONTENT_API_URL}/public/video/gif`;

export { CLIENT_SETTING, STATUS_CODE };

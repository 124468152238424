import { PREFIX_CLS } from "constants/common";
import resolution from "constants/resolution";
import THEME from "constants/themes";
import { isUndefined } from "lodash";
import styled, { css } from "styled-components";
import {
  StyledDivider,
  StyledSection,
  StyledSelect,
  StyledText,
} from "styled-components/CommonStyled";

const StyledFilters = styled(StyledSection)`
  display: flex;
  align-items: center;
  margin: 0 24px 0 0;
  font-size: 16px;

  @media only screen and (max-width: ${resolution.tabletM}px) {
    margin: 0 8px 0 0;
  }
`;

const StyledFilterDivider = styled(StyledDivider)`
  margin: 0 24px 0 0;

  @media only screen and (max-width: ${resolution.tabletM}px) {
    margin: 0 12px 0 0;
  }
`;

const StyledTextClearAll = styled(StyledText)`
  font-size: 16px;
  margin: 0 24px 0 0;
  cursor: pointer;
`;

const StyledFilterSelect = styled(StyledSelect)`
  font-family: "IBM Plex Sans" !important;

  ${({ isAnimation }) =>
    isAnimation &&
    css`
      -webkit-transition: width 0.125s linear;
      -moz-transition: width 0.125s linear;
      -ms-transition: width 0.125s linear;
      -o-transition: width 0.125s linear;
      transition: width 0.125s linear;
    `}

  .${PREFIX_CLS}-select-selector {
    background-color: transparent !important;
    transition: none !important;

    .lib-entribe-select-clear {
      transition: none !important;
    }

    .${PREFIX_CLS}-select-selection-overflow {
      display: inline-block;

      &-item {
        &.${PREFIX_CLS}-select-selection-overflow-item-suffix {
          .${PREFIX_CLS}-select-selection-search {
            position: absolute;
            left: 2px;
            top: 0;

            &-input {
              margin-top: -2px;
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }

  .${PREFIX_CLS}-select-selection-placeholder {
    display: inline !important;
    opacity: 1 !important;
    color: ${THEME.colors.darkBlue2} !important;
    font-family: "IBM Plex Sans" !important;
  }

  .${PREFIX_CLS}-select-selection-item {
    display: none;
  }

  &.filter_select_visible {
    .${PREFIX_CLS}-select-selector {
      &::after {
        width: 100%;
        content: "789";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        display: inline;
        padding: 0 0 0 12px;
      }
    }
  }

  &.other_styles {
    .${PREFIX_CLS}-select-selector {
      padding: 1px 4px 1px 12px;
    }
  }
`;

const StyledSelectLabel = styled(StyledText)`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ visibility }) => visibility && `visibility: ${visibility}`};
  flex: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "IBM Plex Sans" !important;
`;

const StyledFilterSelectWrapper = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export {
  StyledFilterDivider,
  StyledTextClearAll,
  StyledFilters,
  StyledFilterSelect,
  StyledFilterSelectWrapper,
  StyledSelectLabel,
};

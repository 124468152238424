import {
  HTML_TAG_ATTRIBUTE,
  MINE_TYPE,
  PLACEHOLDER_IMAGE_IN_BASE64,
  SOCIAL,
  TIME_OUT,
} from "constants/common";
import { isNull } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { getClassName } from "services/gallery";
import utils from "services/utils";
import {
  StyledImg,
  StyledVideo,
} from "styled-components/CustomGallery/ContentDetailStyled";
import { StyledSkeletonImage } from "styled-components/CustomGallery/CustomGalleryStyled";

const ContentMediaDetail = (props) => {
  const {
    children,
    contentUrl,
    contentExternalUrl,
    imgRef,
    videoRef,
    isArrayElm,
    currentRef = 0,
    isVideo,
    social,
    callbackLoadError,
    setContentClass,
    contentClass,
    additionalClassName,
    otherVideoProps,
    otherImageProps,
    setContentHeight,
    setIsRendered,
    setIsLoading,
    isRendered,
    altText,
  } = props;
  const refError = useRef(true);

  const [contentSrc, setContentSrc] = useState(
    utils.getSocialS3URL(contentUrl)
  );
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const newUrl = utils.getSocialS3URL(contentUrl);

    if (contentSrc !== newUrl) {
      refError.current = true;
      setContentSrc(newUrl);
    }
  }, [contentUrl]);

  useEffect(() => {
    if (contentSrc) {
      if (isVideo && videoRef && videoRef.current) {
        if (isArrayElm) {
          if (videoRef.current[currentRef]) {
            videoRef.current[currentRef].poster = "";
            videoRef.current[currentRef].load();
          }
        } else {
          videoRef.current.poster = "";
          videoRef.current.load();
        }
      }
    }
  }, [contentSrc, videoRef.current]);

  useEffect(() => {
    return () => {
      refError.current = true;
    };
  }, []);

  const handleRender = () => {
    setTimeout(() => {
      setIsRendered(true);
    }, TIME_OUT.T_100);
  };

  const detectVideoResolution = (e) => {
    if (e.target) {
      const currentElement = e.target;
      const width = currentElement.videoWidth;
      const height = currentElement.videoHeight;
      if (setContentClass) {
        setContentClass(getClassName(width, height));
      }
      const heightTemp = currentElement?.offsetHeight;
      if (heightTemp && setContentHeight) {
        setContentHeight(heightTemp);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
      handleRender();
    }
  };

  const detectImageResolution = (e) => {
    if (e.target) {
      const currentElement = e.target;
      const width = currentElement.naturalWidth;
      const height = currentElement.naturalHeight;
      if (setContentClass) {
        setContentClass(getClassName(width, height));
      }
      const heightTemp = currentElement.offsetHeight; // rendered dimension

      if (heightTemp && setContentHeight) {
        setContentHeight(heightTemp);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
      handleRender();
    }
  };

  const handleSaveUrl = (val) => {
    if (callbackLoadError && typeof callbackLoadError === "function") {
      callbackLoadError(val);
    }

    if (!utils.isURL(val)) {
      setIsError(true);
    }

    setContentSrc(val || PLACEHOLDER_IMAGE_IN_BASE64);
  };

  const processVideoLoadedError = async (e) => {
    const socialSource = social ? social.socialSource.toLowerCase() : "";

    if (!social) {
      const src = utils.handleImageLoadedError(
        e,
        contentUrl,
        contentExternalUrl,
        true
      );

      handleSaveUrl(src);
    } else if (
      socialSource === SOCIAL.TIKTOK.toLowerCase() ||
      socialSource === SOCIAL.YOUTUBE.toLowerCase()
    ) {
      const src = await utils.handleVideoLoadedError(
        e,
        socialSource,
        social.permalink,
        social
      );
      handleSaveUrl(src);
    } else if (socialSource === SOCIAL.INSTAGRAM.toLowerCase()) {
      const src = await utils.handleVideoLoadedError(
        e,
        SOCIAL.INSTAGRAM,
        social.permalink,
        social
      );
      handleSaveUrl(src);
    }
  };

  const handleError = async (e) => {
    handleRender();
    if (refError.current) {
      refError.current = false;
      if (setIsLoading) {
        setIsLoading(false);
      }

      if (isVideo) {
        processVideoLoadedError(e);
      } else {
        if (social && SOCIAL.TIKTOK === social.socialSource) {
          const newUrl = await utils.handleImageThumbnailLoadedError(
            e,
            SOCIAL.TIKTOK,
            social.permalink
          );
          handleSaveUrl(newUrl);
          return;
        }

        const newUrl = utils.handleImageLoadedError(
          e,
          contentSrc,
          contentExternalUrl
        );

        handleSaveUrl(newUrl);
      }
    } else {
      handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
    }
  };

  const otherMediaAttrs = useMemo(() => {
    const attrs = { [HTML_TAG_ATTRIBUTE.ARIA_LABEL]: altText };

    if (isError && altText) {
      attrs.title = altText;
    }

    return { ...attrs };
  }, [isError, altText]);

  return (
    <>
      {isVideo && (
        <StyledVideo
          isLoaded={isRendered}
          ref={(element) => {
            if (!isNull(element)) {
              if (isArrayElm) {
                const includeVideo = videoRef.current.includes(element);
                const idx = videoRef.current.indexOf(element);
                if (includeVideo) {
                  videoRef.current[idx] = element;
                } else {
                  videoRef.current.push(element);
                }
              } else {
                videoRef.current = element;
              }
            }
          }}
          onLoadedMetadata={detectVideoResolution}
          preload="auto"
          playsInline
          className={`${contentClass} video-content-detail ${additionalClassName}`}
          onError={(e) => {
            handleError(e);
          }}
          controls
          {...otherVideoProps}
          {...otherMediaAttrs}
        >
          <source src={`${contentSrc}#t=0.5`} type={MINE_TYPE.MP4} />
        </StyledVideo>
      )}
      {!isVideo && (
        <StyledImg
          isLoaded={isRendered}
          ref={(element) => {
            if (!isNull(element)) {
              if (isArrayElm) {
                if (!imgRef.current.includes(element)) {
                  imgRef.current.push(element);
                }
              } else {
                imgRef.current = element;
              }
            }
          }}
          className={`${contentClass} ${additionalClassName}`}
          src={contentSrc}
          onError={(e) => handleError(e)}
          onLoad={detectImageResolution}
          {...otherImageProps}
          {...otherMediaAttrs}
        />
      )}
      {!isRendered && (
        <StyledSkeletonImage
          active
          bgColor="rgba(22, 22, 22, 1)"
          bgImg=" linear-gradient(
          90deg,
          rgb(110, 110, 110, 0.4) 25%,
          rgb(141, 141, 141, 0.6) 37%,
          rgb(110, 110, 110, 0.4) 63%
        )"
        />
      )}
      {children}
    </>
  );
};

export default ContentMediaDetail;

import { handleActions } from "redux-actions";
import { STATUS_CODE } from "../../constants/APIs";
import {
  DEFAULT_ERROR,
  FAILED,
  IN_PROGRESS,
  NONE,
  SUCCEEDED,
} from "../../constants/common";
import { getClientSettingTypes, storeDataTypes } from "./gallery.types";

const initialState = {
  clientSettingWhitelabeling: true,
  error: "",
};

const getClientSettingRequest = (state) => {
  return {
    ...state,
    getClientSettingStatus: IN_PROGRESS,
  };
};

const getClientSettingSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: clientSettingWhitelabeling,
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        clientSettingWhitelabeling,
        getClientSettingStatus: SUCCEEDED,
      };
    }
  }

  return {
    ...state,
    getClientSettingStatus: FAILED,
  };
};

const getClientSettingFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      getClientSettingStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    uploadTikTokContentStatus: FAILED,
  };
};

const getClientSettingEnd = (state) => {
  return {
    ...state,
    getClientSettingStatus: NONE,
  };
};

const storeDataRequest = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const widgetsReducers = handleActions(
  {
    [getClientSettingTypes.GET_CLIENT_SETTING_REQUEST]: getClientSettingRequest,
    [getClientSettingTypes.GET_CLIENT_SETTING_SUCCEEDED]:
      getClientSettingSucceeded,
    [getClientSettingTypes.GET_CLIENT_SETTING_FAILED]: getClientSettingFailed,
    [getClientSettingTypes.GET_CLIENT_SETTING_END]: getClientSettingEnd,
    [storeDataTypes.STORE_DATA_REQUEST]: storeDataRequest,
  },
  initialState
);

export default widgetsReducers;

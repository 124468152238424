import { useEffect, useMemo, useRef, useState } from "react";
import {
  IconClose,
  IconEye,
  IconInstagram,
  IconKeyboardArrowLeft,
  IconKeyboardArrowRight,
  IconTwitter,
} from "../../../../../assets/icons";

import ContentDetailContext from "contexts/ContentDetail";
import { useTranslation } from "react-i18next";
import {
  StyledExplicitOverlay,
  StyledSection,
  StyledTitle,
} from "styled-components/CommonStyled";
import { landingURL } from "../../../../../config";
import {
  MEDIA_TYPE,
  PENDO_TRACKING_TYPE,
  SOCIAL,
  SOCIAL_PROFILE,
} from "../../../../../constants/common";
import {
  DETAIL_LIGHTBOX,
  KEYBOARD,
  MEDIA_SIZE_DEFAULT,
  TYPE_ACTIONS,
  VIEW_TYPE,
} from "../../../../../constants/gallery";
import resolution from "../../../../../constants/resolution";
import THEME from "../../../../../constants/themes";
import {
  checkExplicitContent,
  formatSocialUsername,
} from "../../../../../services/gallery";
import utils from "../../../../../services/utils";
import {
  StyledCaption,
  StyledContainer,
  StyledContentMedia,
  StyledContentModal,
  StyledFooterLightBox,
  StyledIconChangeContent,
  StyledIconClose,
  StyledNameUser,
  StyledSocialUsername,
  StyledThumbnail,
  StyledWrapperContainer,
  StyledWrapperContent,
  StyledWrapperPopup,
} from "../../../../../styled-components/CustomGallery/ContentDetailStyled";
import {
  StyledHyperlink,
  StyledIcon,
  StyledSkeleton,
} from "../../../../../styled-components/CustomGallery/CustomGalleryStyled";
import GalleryFooter from "../../../../shared/section/GalleryFooter/GalleryFooter";
import ContentMediaDetail from "./ContentMediaDetail";
import CustomProperties from "./CustomProperties";
import useTrackCaptionLink from "./UseTrackCaptionLink";
const ContentDetail = (props) => {
  const {
    showDetailLightBox,
    content,
    onCancel,
    handleChangeContent,
    dimension,
    className,
    pendoTrackingData,
    visible,
  } = props;

  const {
    creatorProfile,
    description = "",
    mediaType,
    contentUrl,
    social,
    review,
    contentExternalUrl,
  } = content || {};
  const { firstName = "", lastName = "" } = creatorProfile || {};
  const { productDepicted = "" } = review;

  const initialLastName = creatorProfile?.lastName?.charAt(0);

  let instagram =
    social && social.socialSource === SOCIAL.INSTAGRAM.toLowerCase()
      ? social.socialUsername
      : creatorProfile?.instagramUsername;
  const twitter =
    social && social.socialSource === SOCIAL.TWITTER.toLowerCase()
      ? social.socialUsername
      : creatorProfile?.twitterUsername;

  instagram = formatSocialUsername(instagram, SOCIAL.INSTAGRAM.toLowerCase());
  const hrefInstagram = `${SOCIAL_PROFILE.INSTAGRAM}${instagram || ""}`;
  const hrefTwitter = `${SOCIAL_PROFILE.TWTTER}${twitter || ""}`;
  const { t } = useTranslation();
  const videoRef = useRef();
  const imgRef = useRef();
  const captionRef = useRef();

  const [sizeImg, setSizeImg] = useState(MEDIA_SIZE_DEFAULT);
  const [classImage, setClassImage] = useState(VIEW_TYPE.SQUARE);
  const [responsiveSize, setResponsiveSize] = useState();
  const [isRendered, setIsRendered] = useState(false);
  const [isShowExplicitOverlay, setIsShowExplicitOverlay] = useState(false);

  const isCustomizedModal = dimension?.width <= resolution.tabletM;
  const MAX_HEIGHT = resolution.tabletS;

  useEffect(() => {
    const isVideo = content?.mediaType === MEDIA_TYPE.VIDEO;
    if (sizeImg !== imgRef.current?.offsetHeight && !isVideo) {
      setSizeImg(imgRef.current?.offsetHeight);
    }
    if (sizeImg !== videoRef.current?.offsetHeight && isVideo) {
      setSizeImg(videoRef.current?.offsetHeight);
    }
  }, [
    classImage,
    videoRef.current?.offsetHeight,
    imgRef.current?.offsetHeight,
    isRendered,
    content,
    sizeImg,
  ]); // add content in case onLoad img does not work since the next or previous content has the same URL with the current content

  useEffect(() => {
    pendoTracking(PENDO_TRACKING_TYPE.IMAGE_CLICKED);
    pendoTracking(PENDO_TRACKING_TYPE.CONTENT_VISITED);
    pendoTracking(PENDO_TRACKING_TYPE.ALL_CONTENT_VISITED);

    if (content) {
      setIsRendered(false);
      setIsShowExplicitOverlay(checkExplicitContent(content?.review?.flags));
    }
  }, [content]);

  useEffect(() => {
    if (videoRef.current || imgRef.current) {
      const onKeyup = (e) => {
        const onCheckEvent = (callback) => {
          const activeElement = document.activeElement;
          const inputs = ["input", "select", "button", "textarea", "video"];
          const skipEvent =
            activeElement &&
            inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;

          if (!skipEvent) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            callback();
          }
        };

        if (e.code === KEYBOARD.ARROW_LEFT.STR) {
          const func = () => {
            moveContent(TYPE_ACTIONS.PREV);
          };
          onCheckEvent(func);
        }
        if (e.code === KEYBOARD.ARROW_RIGHT.STR) {
          const func = () => {
            moveContent(TYPE_ACTIONS.NEXT);
          };
          onCheckEvent(func);
        }
      };
      window.addEventListener("keyup", onKeyup);
      return () => window.removeEventListener("keyup", onKeyup);
    }
    return () => {};
  }, [videoRef.current, imgRef.current, content?.id]);

  const pendoTracking = (trackType) => {
    const data = {
      ...pendoTrackingData,
      trackType,
      contentId: content?.id,
    };
    utils.pendoTracking(data);
  };

  useEffect(() => {
    const subtractedNumber = 50;

    if (dimension.height <= MAX_HEIGHT) {
      let sizeTemp = {
        height: dimension.height - subtractedNumber,
      };

      if (classImage === VIEW_TYPE.SQUARE) {
        sizeTemp.width = sizeTemp.height;
      } else if (classImage === VIEW_TYPE.LANDSCAPE) {
        sizeTemp.width = sizeTemp.height + subtractedNumber;
      } else {
        sizeTemp.width = sizeTemp.height - subtractedNumber;
      }

      setResponsiveSize(sizeTemp);
    }
  }, [dimension, classImage]);

  useTrackCaptionLink(
    content,
    captionRef.current,
    "caption-gallery",
    pendoTracking
  );

  const renderMedia = useMemo(() => {
    const additionalClassName = isCustomizedModal ? "customize-item" : "";
    const className = !responsiveSize ? classImage : "responsive-content";
    const altText = content?.review?.altText || "";
    const otherProps = {
      dimension,
      responsiveSize: responsiveSize || {},
    };
    const isVideo = mediaType === MEDIA_TYPE.VIDEO;

    return (
      <ContentMediaDetail
        key={content?.id}
        content={content}
        imgRef={imgRef}
        videoRef={videoRef}
        contentUrl={contentUrl}
        contentExternalUrl={contentExternalUrl}
        isVideo={isVideo}
        setIsRendered={setIsRendered}
        contentClass={className}
        additionalClassName={additionalClassName}
        setContentClass={setClassImage}
        setContentHeight={setSizeImg}
        otherVideoProps={otherProps}
        otherImageProps={otherProps}
        isRendered={isRendered}
        altText={altText.trim()}
      />
    );
  }, [responsiveSize, content, classImage, dimension, isRendered]);

  const handleBlockEvent = (e) => {
    e.stopPropagation();
  };

  const moveContent = (action) => {
    setSizeImg(MEDIA_SIZE_DEFAULT);
    setClassImage(VIEW_TYPE.SQUARE);
    handleChangeContent(action);
  };

  const handleTrackSocialLink = () => {
    pendoTracking(PENDO_TRACKING_TYPE.SOCIAL_USERNAME_CLICKED);
    pendoTracking(PENDO_TRACKING_TYPE.GALLERY_SOCIALLINK_CLICKED);
  };

  const renderContentInfo = () => {
    let className = isCustomizedModal
      ? `${classImage} customize-container`
      : classImage;

    if (responsiveSize) {
      if (mediaType === MEDIA_TYPE.VIDEO) {
        className += ` responsive-content-video`;
      } else {
        className += ` responsive-content`;
      }
    }

    return (
      <StyledContainer
        isCustomizedModal={isCustomizedModal}
        height={sizeImg}
        dimension={dimension}
        className={className}
        subtractedHeight={50}
        responsiveSize={responsiveSize || {}}
      >
        <StyledWrapperContent dimension={dimension}>
          {!isRendered && (
            <StyledSkeleton
              bgImg="linear-gradient(
              90deg,
              rgb(110, 110, 110, 0.4) 25%,
              rgb(141, 141, 141, 0.6) 37%,
              rgb(110, 110, 110, 0.4) 63%
            )"
              paragraph={{ rows: 2 }}
              active
            />
          )}
          {isRendered && (
            <>
              {instagram &&
                showDetailLightBox?.includes(
                  DETAIL_LIGHTBOX.showInstagramUsername
                ) && (
                  <StyledNameUser
                    onClick={handleTrackSocialLink}
                    target="_blank"
                    href={hrefInstagram}
                  >
                    <StyledSocialUsername margin="0 0 6px 0">
                      <StyledIcon
                        color={THEME.colors.white}
                        width="22px"
                        height="22px"
                        margin="0 4px 0 0"
                      >
                        <IconInstagram />
                      </StyledIcon>
                      {instagram}
                    </StyledSocialUsername>
                  </StyledNameUser>
                )}

              {twitter &&
                showDetailLightBox?.includes(
                  DETAIL_LIGHTBOX.showTwitterUsername
                ) && (
                  <StyledNameUser
                    margin="0 0 12px 0"
                    onClick={handleTrackSocialLink}
                    target="_blank"
                    href={hrefTwitter}
                  >
                    <StyledSocialUsername>
                      <StyledIcon
                        color={THEME.colors.white}
                        width="22px"
                        height="22px"
                        margin="0 4px 0 0"
                      >
                        <IconTwitter />
                      </StyledIcon>
                      {twitter}
                    </StyledSocialUsername>
                  </StyledNameUser>
                )}

              {showDetailLightBox?.includes(DETAIL_LIGHTBOX.customFields) && (
                <CustomProperties />
              )}

              {showDetailLightBox?.includes(DETAIL_LIGHTBOX.showCaption) &&
                description && (
                  <StyledCaption>{`“ ${description} ”`}</StyledCaption>
                )}

              {showDetailLightBox?.includes(DETAIL_LIGHTBOX.customDetails) &&
                (firstName || lastName) && (
                  <StyledCaption
                    id="caption-gallery"
                    ref={captionRef}
                    fontSize="14px"
                    margin="18px 0 0 0"
                    dangerouslySetInnerHTML={{
                      __html: productDepicted,
                    }}
                  />
                )}

              {showDetailLightBox?.includes(
                DETAIL_LIGHTBOX.showFirstNameLastInitial
              ) &&
                (firstName || lastName) && (
                  <StyledCaption>
                    {`- ${firstName || ""} ${
                      initialLastName ? initialLastName + "." : ""
                    }`}
                  </StyledCaption>
                )}
            </>
          )}
        </StyledWrapperContent>

        {dimension && dimension.width > resolution.tabletM && (
          <StyledIconClose
            className={isCustomizedModal ? "customize-icon-close" : ""}
            onClick={() => onCancel()}
          >
            <IconClose />
          </StyledIconClose>
        )}

        <StyledHyperlink href={landingURL} target="_blank">
          <StyledFooterLightBox isCustomizedModal={isCustomizedModal}>
            <i>
              <GalleryFooter
                customizeText={{
                  fontSize: "12px",
                  color: THEME.colors.white,
                }}
              />
            </i>
          </StyledFooterLightBox>
        </StyledHyperlink>
      </StyledContainer>
    );
  };

  const renderNextPrevBtn = () => {
    return (
      <>
        <StyledIconChangeContent
          dimension={dimension}
          onClick={(e) => {
            handleBlockEvent(e);
            moveContent(TYPE_ACTIONS.PREV);
          }}
          className="prev"
        >
          <IconKeyboardArrowLeft />
        </StyledIconChangeContent>
        <StyledIconChangeContent
          dimension={dimension}
          onClick={(e) => {
            handleBlockEvent(e);
            moveContent(TYPE_ACTIONS.NEXT);
          }}
          className="next"
        >
          <IconKeyboardArrowRight />
        </StyledIconChangeContent>
      </>
    );
  };

  const renderContentMedia = () => {
    if (!isCustomizedModal) {
      return renderMedia;
    }

    return (
      <StyledContentMedia>
        {renderMedia}
        {renderNextPrevBtn()}
      </StyledContentMedia>
    );
  };

  const renderExplicitOverlay = () => {
    return (
      <StyledExplicitOverlay zIndex={99} height={sizeImg}>
        <StyledSection width="232px" textAlign="center">
          <StyledIcon className="absolute" color={THEME.colors.gray4} size={80}>
            <IconEye />
          </StyledIcon>
          <StyledTitle margin="0 0 8px 0" fontSize="24px">
            {t("warning")}
          </StyledTitle>
          <StyledTitle margin="0 0 10px 0">
            {t("sub_sensitive_content")}
          </StyledTitle>
          <StyledTitle
            className="unset_height text_action"
            cursor="pointer"
            onClick={() => setIsShowExplicitOverlay(false)}
            fontWeight="bold"
            textDecoration="underline"
          >
            {t("view_content")}
          </StyledTitle>
        </StyledSection>
      </StyledExplicitOverlay>
    );
  };

  const contextValues = { content };

  return (
    <ContentDetailContext.Provider value={contextValues}>
      <StyledContentModal
        height={dimension?.height}
        dimension={dimension}
        open={visible}
        footer={null}
        closable={false}
        maskClosable
        centered
        onCancel={onCancel}
        maskStyle={{
          backgroundColor: !isCustomizedModal
            ? "rgba(0, 0, 0, 0.8)"
            : "rgba(0, 0, 0, 0.875)",
        }}
      >
        {!isCustomizedModal && renderNextPrevBtn()}
        <StyledWrapperPopup>
          {dimension && dimension.width <= resolution.tabletM && (
            <StyledIconClose
              className="customize-icon-close-responsive"
              onClick={() => onCancel()}
            >
              <IconClose />
            </StyledIconClose>
          )}
          <StyledWrapperContainer>
            <StyledThumbnail
              className={`thumbnail-${className} ${
                responsiveSize ? "responsive-content" : ""
              }`}
              dimension={dimension}
            >
              {renderContentMedia()}
              {isCustomizedModal && renderContentInfo()}
              {isRendered && isShowExplicitOverlay && renderExplicitOverlay()}
            </StyledThumbnail>

            {!isCustomizedModal && renderContentInfo()}
          </StyledWrapperContainer>
        </StyledWrapperPopup>
      </StyledContentModal>
    </ContentDetailContext.Provider>
  );
};

export default ContentDetail;

import { Col, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { IconRemoveSelectItem } from "assets/icons";
import { MAX, WIDTH } from "constants/gallery";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertOptions } from "services/gallery";
import {
  StyledForm,
  StyledFormItem,
  StyledModal,
  StyledSection,
  StyledSelect,
  StyledText,
} from "styled-components/CommonStyled";
import { StyledIcon } from "styled-components/CustomGallery/CustomGalleryStyled";

const FilterPopup = (props) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { visible, onCancel, filtersApplied, setFiltersApplied, filterList } =
    props;

  const [filteredValues, setFilteredValues] = useState({ ...filtersApplied });

  useEffect(() => {
    if (visible && filtersApplied) {
      form.setFieldsValue({ ...filtersApplied });
    }
  }, [visible, filtersApplied]);

  const clearAll = () => {
    form.resetFields();
    setFilteredValues({});
  };

  const onApplyFilters = () => {
    if (setFiltersApplied) {
      if (!_.isEqual(filtersApplied, filteredValues)) {
        setFiltersApplied(
          !_.isEmpty(filteredValues) ? { ...filteredValues } : {}
        );
      }

      onCancel();
    }
  };

  const renderLabel = (label) => {
    return <StyledText className="overflow">{label}</StyledText>;
  };

  const onValuesChange = (changedValue) => {
    const fieldName = Object.keys(changedValue)[0];
    const selectedValues = { ...filteredValues, ...changedValue };
    if (changedValue[fieldName].length === 0) {
      delete selectedValues[fieldName];
    }
    setFilteredValues(selectedValues);
  };

  return (
    <StyledModal
      title={t("gallery_filters.title")}
      open={visible}
      centered
      footer={false}
      onCancel={onCancel}
      maxWidth={
        filterList.length <= MAX.CUSTOM_PROPERTIES_INLINE
          ? WIDTH.FILTER_POPUP.W_420
          : WIDTH.FILTER_POPUP.W_550
      }
    >
      <StyledSection
        maxHeight="calc(100vh - 200px)"
        overflow="hidden auto"
        padding="0 4px"
      >
        <StyledForm
          form={form}
          layout="vertical"
          initialValues={{ filtersApplied }}
          onValuesChange={onValuesChange}
        >
          <Row gutter={12}>
            {filterList.map((item) => {
              return (
                item?.label && (
                  <Col
                    key={item.label}
                    xs={24}
                    sm={
                      filterList.length > MAX.CUSTOM_PROPERTIES_INLINE
                        ? MAX.CUSTOM_PROPERTIES_INLINE * 2
                        : MAX.CUSTOM_PROPERTIES_INLINE * 4
                    }
                  >
                    <StyledFormItem
                      label={renderLabel(item.label)}
                      name={item?.fieldName}
                    >
                      <StyledSelect
                        mode="multiple"
                        options={convertOptions(item.value)}
                        removeIcon={
                          <StyledIcon>
                            <IconRemoveSelectItem />
                          </StyledIcon>
                        }
                        maxTagTextLength={25}
                        maxTagCount={
                          filterList.length > MAX.CUSTOM_PROPERTIES_INLINE
                            ? MAX.CUSTOM_PROPERTIES_INLINE / 3
                            : MAX.CUSTOM_PROPERTIES_INLINE - 2
                        }
                      />
                    </StyledFormItem>
                  </Col>
                )
              );
            })}
          </Row>
        </StyledForm>
      </StyledSection>
      <StyledSection display="flex" justify="flex-end">
        <StyledText
          fontWeight={500}
          cursor="pointer"
          textTransform="uppercase"
          onClick={clearAll}
        >
          {t("gallery_filters.clear_all")}
        </StyledText>
        <StyledText
          fontWeight={500}
          cursor="pointer"
          margin="0 0 0 14px"
          textTransform="uppercase"
          onClick={onApplyFilters}
        >
          {t("gallery_filters.done")}
        </StyledText>
      </StyledSection>
    </StyledModal>
  );
};

export default FilterPopup;

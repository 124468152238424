import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import PropTypes from "prop-types";

import NotFound from "../components/pages/NotFound/NotFound";
import CustomGallery from "../components/widgets/galleries/CustomGallery/CustomGallery";

Routes.propTypes = {
  globalProps: PropTypes.shape({
    isShowLoading: PropTypes.bool,
    setIsShowLoading: PropTypes.func,
    requestAPI: PropTypes.func,
    didMountCallback: PropTypes.func,
    globalInfo: PropTypes.object,
  }),
};

export default function Routes(props) {
  const { globalProps } = props;

  /** Detect page change, maybe use later */
  /* 
  React.useEffect(() => {
    return history.listen((location) => {
      if (location.hash === "") {
        // do action after changing page
      }
    })
  }, [history]); */

  return (
    <Switch>
      {/* Custom Gallery */}
      <Route
        path={"/"}
        component={() => <CustomGallery globalProps={globalProps} />}
      />

      {/* render landing page builder in the client portal */}
      <Route
        path={"srcdoc"}
        component={() => <CustomGallery globalProps={globalProps} />}
      />

      {/* Others */}
      <Route
        path="/404"
        component={() => <NotFound globalProps={globalProps} />}
      />

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={() => <NotFound globalProps={globalProps} />} />
    </Switch>
  );
}

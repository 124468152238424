import PropTypes from "prop-types";
import React, { Component, Suspense } from "react";
import { Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import {
  IoIosArrowDropleft,
  IoIosArrowDropleftCircle,
  IoIosArrowDropright,
  IoIosArrowDroprightCircle,
  IoIosPlay,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";
import Select from "react-select";
// import Preload from "react-preload";
import logo from "assets/img/logo-black.png";
import SmoothImage from "components/shared/SmoothImage";
import { sortBy as _sortBy } from "lodash";
import { Env } from "../../../../config";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import UploadWidget from "../../uploads/Inkedibles/Inkedibles";
import data from "./../../uploads/Inkedibles/data";
import "./Inkedibles.scss";

const otherOption = [{ value: "Other", label: "Other" }];
const itemsPerPage = 20;
const loadingIndicator = (
  <div className="gallery-preloader">
    <h5>Loading..</h5>
  </div>
);

const uploadUrl = Env.includes("prod")
  ? "upload.entribe.com"
  : "uploadqa.entribe.com";

class Inkedibles extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    setIsShowLoading: () => {},
    globalProps: {
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      contents: [],
      contentDetail: null,
      contentDetailIndex: 0,
      creators: [],
      isDisabledWidget: false,
      pageCount: 1,
      page: 0,
      isOpenImageDetail: false,
      isOpenUploadWidget: false,
      isImageDetailLoaded: false,
      errorMsg: "",
      // Search / Filter
      keyword: "",
      creator: null,
      productsDepicted: null,
      productsUsed: null,
      creationTheme: null,
      creationCategory: null,
    };

    this.customInput = {};
  }

  componentWillMount() {
    this.getLocationAPI();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  getLocationAPI = () => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const url = configUrls.externalAPI.getLocation;
    const successCallback = (resp) => {
      this.setState({ location: resp });
      this.getAllContentsInGallery();
      const {
        globalProps: { sendTrackingPendoData },
        location: { pathname = "" },
      } = this.props;
      const pathName = pathname.includes("/:")
        ? utils.removeFirstSlash(pathname).split("/")[0]
        : pathname;
      const clientId = utils.getClientIdByName(pathName);
      sendTrackingPendoData(this.state.location, clientId);
    };
    const failedCallback = (resp) => {
      this.getAllContentsInGallery();
    };
    const errorCallback = () => {
      this.getAllContentsInGallery();
    };

    requestAPI(
      "get",
      url,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      true
    );
  };

  existInList = (array, keyword) => {
    return typeof array === "object"
      ? array.some((item) => item.toLowerCase().includes(keyword))
      : array.toLowerCase().includes(keyword);
  };

  matchWithKeyword = (customInfo, keyword) => {
    for (let i = 0; i < customInfo.length; i++) {
      if (this.existInList(customInfo[i].values, keyword)) {
        return true;
      }
    }
    return false;
  };

  matchWithCategories = (
    creatorProfile,
    customInfo,
    productsDepicted,
    productsUsed,
    creationTheme,
    creationCategory,
    instagramUsername
  ) => {
    let existing = true;
    if (productsDepicted) {
      existing &= customInfo.some(
        (item) =>
          item.name === "products depicted" &&
          item.values.includes(productsDepicted.toLowerCase())
      );
    }
    if (existing && productsUsed) {
      existing &= customInfo.some(
        (item) =>
          item.name === "products used" &&
          item.values.includes(productsUsed.toLowerCase())
      );
    }
    if (existing && creationTheme) {
      existing &= customInfo.some(
        (item) =>
          item.name === "creation theme" &&
          item.values.includes(creationTheme.toLowerCase())
      );
    }
    if (existing && creationCategory) {
      existing &= customInfo.some(
        (item) =>
          item.name === "creation category" &&
          item.values.includes(creationCategory.toLowerCase())
      );
    }
    if (existing && instagramUsername && creatorProfile.instagramUsername) {
      existing &= creatorProfile.instagramUsername
        .toLowerCase()
        .includes(instagramUsername.toLowerCase());
    }
    return existing;
  };

  showGallery(page) {
    const {
      keyword = "",
      creator = {},
      productsDepicted = {},
      productsUsed = {},
      creationTheme = {},
      creationCategory = {},
    } = this.state;
    const data = {
      productsDepicted: productsDepicted ? productsDepicted.value : "",
      productsUsed: productsUsed ? productsUsed.value : "",
      creationTheme: creationTheme ? creationTheme.value : "",
      creationCategory: creationCategory ? creationCategory.value : "",
      instagramUsername: creator ? creator.value : "",
      keyword: keyword.toLowerCase(),
    };

    const { allContents } = this.state;
    let filteredContents;
    if (data.keyword) {
      filteredContents = allContents.filter((contentContainer) => {
        const content = contentContainer.content;
        return this.matchWithKeyword(content.customInfo, data.keyword);
      });
    } else if (
      data.productsDepicted ||
      data.productsUsed ||
      data.creationTheme ||
      data.creationCategory ||
      data.instagramUsername
    ) {
      filteredContents = allContents.filter((contentContainer) => {
        const content = contentContainer.content;
        return this.matchWithCategories(
          content.creatorProfile,
          content.customInfo,
          data.productsDepicted,
          data.productsUsed,
          data.creationTheme,
          data.creationCategory,
          data.instagramUsername
        );
      });
    } else {
      filteredContents = allContents.concat();
    }
    const total = filteredContents.length;
    const pageCount =
      total < itemsPerPage
        ? 1
        : total < itemsPerPage
        ? Math.ceil(total / itemsPerPage)
        : Math.ceil(total / itemsPerPage);
    const contents = filteredContents.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );
    this.setState({ contents: contents, pageCount, page });
  }

  destructContents = () => {
    const { allContents } = this.state;
    if (!allContents) {
      return;
    }
    const creators = [];
    const creatorExist = (aray, key) => {
      if (!key) {
        return false;
      }
      return aray.some((item) => item.value === key.toLowerCase());
    };
    allContents.forEach((contentContainer) => {
      if (contentContainer.content) {
        const content = contentContainer.content;
        const instagramUsername = content.creatorProfile.instagramUsername;
        const { review = {} } = content;
        const { custom: customInfo = [] } = review;
        const convertCustomInfo = JSON.stringify(customInfo).toLowerCase();
        content.customInfo = JSON.parse(convertCustomInfo) || [];
        if (instagramUsername && !creatorExist(creators, instagramUsername)) {
          creators.push({
            label: `@${instagramUsername}`,
            value: instagramUsername.toLowerCase(),
          });
        }
      }
    });
    this.setState({ creators: _sortBy(creators, ["label"]) });
  };

  getAllContentsInGallery() {
    const page = 1;
    const maxRecords = 1000;
    const {
      globalProps: { requestAPI },
      location: { pathname = "" },
    } = this.props;

    const pathName = pathname.includes("/:")
      ? utils.removeFirstSlash(pathname).split("/")[0]
      : pathname;
    const {
      location: { search = "" },
    } = this.props;
    const gid = utils.getGalleryFolderId(search);
    const defaultGalleryId = utils.getGalleryFolderIdByName(pathName);
    const clientId = utils.getClientIdByName(pathName);
    sessionStorage.setItem("clientId", clientId);
    sessionStorage.setItem("clientName", utils.removeFirstSlash(pathName));

    const data = {
      maxRecords,
      page,
      clientId: parseInt(clientId),
      galleryId: parseInt(gid == 0 ? defaultGalleryId : gid),
    };

    const successCallback = (resp) => {
      const result = resp || [];
      this.setState({ allContents: result });
      this.destructContents();
      this.showGallery(0);
    };

    const failedCallback = (resp) => {
      this.setState({
        isDisabledWidget: true,
        errorMsg: resp.status.status || errorMsgs.callAPIFailed,
      });
    };

    const errorCallback = () => {
      this.setState({
        isDisabledWidget: true,
        errorMsg: errorMsgs.callAPIFailed,
      });
    };

    if (clientId) {
      const url = `${configUrls.API.getGallery}`;
      requestAPI(
        "put",
        url,
        data,
        successCallback,
        failedCallback,
        errorCallback
      );
    } else {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Page Not Found",
      });
    }
  }

  getMaxContentDetailIndex = () => {
    const { contents = [] } = this.state;
    return contents.length === 0 ? 0 : contents.length - 1;
  };

  handlePageChange = (page) => {
    this.showGallery(page.selected);
  };

  handleOpenImageDetail = (isOpen, contentDetailIndex) => {
    const { contents = [] } = this.state;
    const maxContentDetailIndex = this.getMaxContentDetailIndex();

    if (contentDetailIndex < 0) {
      contentDetailIndex = 0;
    }

    if (contentDetailIndex >= maxContentDetailIndex) {
      contentDetailIndex = maxContentDetailIndex;
    }
    const contentContainer = contents[contentDetailIndex];
    this.setState({
      isOpenImageDetail: isOpen,
      contentDetailIndex,
      contentDetail: contentContainer
        ? contentContainer.content
          ? contentContainer.content
          : contentContainer.select
        : null,
      isImageDetailLoaded: false,
    });
  };

  handleOpenUploadWidget = (isOpen) => {
    this.setState({ isOpenUploadWidget: isOpen });
  };

  handleChangeKeyword = (e) => {
    this.setState({ keyword: e.target.value });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.showGallery(0);
    }
  };

  handleAfterImgLoaded = () => {
    this.setState({ isImageDetailLoaded: true });
  };

  handleReset = () => {
    this.setState(
      {
        keyword: "",
        creator: null,
        productsDepicted: null,
        productsUsed: null,
        creationTheme: null,
        creationCategory: null,
      },
      () => this.showGallery(0)
    );
  };

  formatGroupLabel = (data) => {
    return (
      <div className="groupStyles">
        <span>{data.label}</span>
        <span className="groupBadgeStyles">{data.options.length}</span>
      </div>
    );
  };

  onChangeSelect = (e, action, name) => {
    this.setState({ [name]: e });
  };

  sortGroupOptions = (groupOption) => {
    if (groupOption) {
      groupOption.sort((a, b) => {
        let A = a.label.toLowerCase();
        let B = b.label.toLowerCase();

        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      });
    }
    return groupOption;
  };

  render() {
    const {
      isDisabledWidget,
      contents = [],
      contentDetail = {},
      contentDetailIndex,
      pageCount,
      page,
      creators,
      isOpenImageDetail,
      isOpenUploadWidget,
      isImageDetailLoaded,
      errorMsg,
      // Search / Filter
      keyword,
      creator,
      productsDepicted,
      productsUsed,
      creationTheme,
      creationCategory,
    } = this.state;

    const isDisabledFilter = keyword !== "";
    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";
    const imgDetailClassName = !isImageDetailLoaded ? "hidden-img" : "";

    const {
      groupedOptions,
      groupedOptions1,
      groupedOptions2,
      groupedOptions3,
    } = data;

    const sortedGroupOptions2 =
      this.sortGroupOptions(groupedOptions2).concat(otherOption);
    const sortedGroupOptions3 =
      this.sortGroupOptions(groupedOptions3).concat(otherOption);

    const {
      contentUrl = "",
      review = {},
      type = "",
    } = isOpenImageDetail ? contentDetail : {};
    const instagramUsername =
      isOpenImageDetail && contentDetail.creatorProfile
        ? contentDetail.creatorProfile.instagramUsername
        : "";
    const maxContentDetailIndex = this.getMaxContentDetailIndex();
    const isDisabledPreviousIcon = contentDetailIndex <= 0;
    const isDisabledNextIcon = contentDetailIndex >= maxContentDetailIndex;
    const { custom: customInfo = [] } = review || {};
    let preloadImages = [];

    contents.forEach((contentContainer) => {
      const content = contentContainer.content
        ? contentContainer.content
        : contentContainer.select;
      preloadImages.push(`${utils.getSocialS3URL(content.thumbnail)}`);
    });

    const modalContent = contentUrl && (
      <>
        {type === "video" ? (
          <video
            className="gallery-video custom"
            src={`${utils.getSocialS3URL(contentUrl)}`}
            controls
            autoPlay="autoplay"
            width="100%"
            onError={(e) => utils.handleThumbnailLoadedError(e)}
          />
        ) : (
          <React.Fragment>
            <img
              className={imgDetailClassName}
              src={`${utils.getSocialS3URL(contentUrl)}`}
              onLoad={() => this.handleAfterImgLoaded()}
              alt="content gallery image detail"
            />
            {!isImageDetailLoaded && loadingIndicator}
          </React.Fragment>
        )}
      </>
    );

    return (
      <div className={`widget-inkedibles-gallery ${widgetContainerClasses}`}>
        <div className="container">
          <div className="widget-block widget-gallery">
            <div className="widget-header">
              <h3>WHAT WILL YOU CREATE WITH INKEDIBLES?</h3>
              <h4>Get inspired by what others are doing with our products!</h4>
            </div>

            <div className="widget-body">
              <div className="widget-form">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={keyword}
                    onChange={(e) => this.handleChangeKeyword(e)}
                    onKeyDown={(e) => this.handleEnter(e)}
                    placeholder="Search InkEdibles creations or filter options below"
                  />
                </div>
                <div className="row">
                  <div className="col-sm-6 p-right">
                    <div className="form-group">
                      <Select
                        classNamePrefix="react-select"
                        options={groupedOptions}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={(e, action) =>
                          this.onChangeSelect(e, action, "productsDepicted")
                        }
                        value={productsDepicted}
                        placeholder="Products depicted"
                        isClearable={true}
                        isDisabled={isDisabledFilter}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 p-left">
                    <div className="form-group">
                      <Select
                        classNamePrefix="react-select"
                        options={creators}
                        onChange={(e, action) =>
                          this.onChangeSelect(e, action, "creator")
                        }
                        value={creator}
                        placeholder="Creators"
                        noOptionsMessage={() => "No Creators"}
                        isClearable={true}
                        isDisabled={isDisabledFilter}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 p-right">
                    <div className="form-group">
                      <Select
                        classNamePrefix="react-select"
                        options={groupedOptions1}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={(e, action) =>
                          this.onChangeSelect(e, action, "productsUsed")
                        }
                        value={productsUsed}
                        placeholder="Products used"
                        isClearable={true}
                        isDisabled={isDisabledFilter}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 p-left">
                    <div className="form-group">
                      <Select
                        classNamePrefix="react-select"
                        options={sortedGroupOptions3}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={(e, action) =>
                          this.onChangeSelect(e, action, "creationTheme")
                        }
                        value={creationTheme}
                        placeholder="Creation theme"
                        isClearable={true}
                        isDisabled={isDisabledFilter}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 p-right">
                    <div className="form-group">
                      <Select
                        classNamePrefix="react-select"
                        options={sortedGroupOptions2}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={(e, action) =>
                          this.onChangeSelect(e, action, "creationCategory")
                        }
                        value={creationCategory}
                        placeholder="Creation category"
                        isClearable={true}
                        isDisabled={isDisabledFilter}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 p-left">
                    <div className="row">
                      <div className="col p-right">
                        <button
                          type="button"
                          className="btn btn-dark btn-reset"
                          onClick={() => this.handleReset()}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col p-left pr-0">
                        <button
                          type="button"
                          className="btn btn-primary btn-search"
                          onClick={() => this.showGallery(0)}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* / Widget Form */}

              <div className="widget-content">
                <h6>
                  Want to share your work here? Upload your content to us at
                  &nbsp;
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.handleOpenUploadWidget(true)}
                  >
                    {uploadUrl}/InkEdibles
                  </a>
                  &nbsp;for chances to be rewarded and featured on this gallery!
                </h6>
                <div className="gallery">
                  <Suspense fallback={loadingIndicator}>
                    <div className="row row-cols-5">
                      {contents &&
                        contents.length > 0 &&
                        contents.map((item, index) => (
                          <div className="col" key={index}>
                            <div
                              className="gallery-item"
                              onClick={() =>
                                this.handleOpenImageDetail(true, index)
                              }
                            >
                              <a>
                                <SmoothImage
                                  src={`${utils.getSocialS3URL(
                                    item.content
                                      ? item.content.thumbnail
                                      : item.select.thumbnail
                                  )}`}
                                  alt="content gallery image"
                                />
                              </a>
                              {item.type === "video" && (
                                <div className="gallery-icon-play">
                                  <IconContext.Provider
                                    value={{
                                      className: "react-icons",
                                      color: "#fff",
                                    }}
                                  >
                                    <IoIosPlay />
                                  </IconContext.Provider>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                      {!errorMsg && contents.length <= 0 && (
                        <span>No records found</span>
                      )}
                      {errorMsg && (
                        <div className="error-msg">
                          <p>{errorMsg} &nbsp;</p>
                        </div>
                      )}
                    </div>
                  </Suspense>
                </div>
              </div>
              {/* / Widget Content */}
            </div>

            <div className="widget-footer">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                initialPage={page}
                forcePage={page}
                disableInitialCallback={true}
              />
              <div className="logo">
                <span>Powered By</span>
                <a href="https://www.entribe.com" target="_blank">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            {/* End Footer */}
          </div>
        </div>

        <Modal
          show={isOpenImageDetail}
          size="lg"
          aria-labelledby="Gallery image"
          dialogClassName="inkedibles-gallery-modal"
          backdrop="static"
          onHide={() => this.handleOpenImageDetail(false, contentDetailIndex)}
          centered
        >
          <Modal.Body>
            <div className="row">
              <div className="col-sm-8 pr-0 d-flex align-items-center">
                {!utils.isMobileWidth() && modalContent}
              </div>
              <div className="col-sm-4 pl-0">
                <div className="gallery-item-detail">
                  <a
                    className="close-icon"
                    onClick={() =>
                      this.handleOpenImageDetail(false, contentDetailIndex)
                    }
                  >
                    <IconContext.Provider value={{ size: "2rem" }}>
                      <BsX />
                    </IconContext.Provider>
                  </a>
                  <h4>Get Inkspired!</h4>
                  <p>
                    <a
                      href={`https://www.instagram.com/${instagramUsername}`}
                      target="_blank"
                    >
                      {`@${instagramUsername}`}
                    </a>
                    &nbsp;used the following InkEdibles products for their
                    creation
                  </p>
                  {utils.isMobileWidth() && (
                    <div className="image-container">
                      {modalContent}

                      <div className="slide-control">
                        <IconContext.Provider value={{ size: "2rem" }}>
                          <a
                            className="prev-icon"
                            onClick={() =>
                              this.handleOpenImageDetail(
                                true,
                                contentDetailIndex - 1
                              )
                            }
                            disabled={isDisabledPreviousIcon}
                          >
                            <IoIosArrowDropleftCircle />
                          </a>
                          <a
                            className="next-icon"
                            onClick={() =>
                              this.handleOpenImageDetail(
                                true,
                                contentDetailIndex + 1
                              )
                            }
                            disabled={isDisabledNextIcon}
                          >
                            <IoIosArrowDroprightCircle />
                          </a>
                        </IconContext.Provider>
                      </div>
                    </div>
                  )}
                  <ul className="categories">
                    {customInfo &&
                      customInfo.map((item, nameIndex) => (
                        <li key={nameIndex}>
                          <label className="category-name">{item.name}</label>
                          {item.values.map((value, valueIndex) => (
                            <span className="category-value" key={valueIndex}>
                              {value}
                            </span>
                          ))}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>

            {!utils.isMobileWidth() && (
              <div className="slide-control">
                <IconContext.Provider value={{ size: "2rem" }}>
                  <a
                    className="prev-icon"
                    onClick={() =>
                      this.handleOpenImageDetail(true, contentDetailIndex - 1)
                    }
                    disabled={isDisabledPreviousIcon}
                  >
                    <IoIosArrowDropleft />
                  </a>
                  <a
                    className="next-icon"
                    onClick={() =>
                      this.handleOpenImageDetail(true, contentDetailIndex + 1)
                    }
                    disabled={isDisabledNextIcon}
                  >
                    <IoIosArrowDropright />
                  </a>
                </IconContext.Provider>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={isOpenUploadWidget}
          aria-labelledby="Upload modal"
          dialogClassName="inkedibles-upload-modal"
          backdrop="static"
          onHide={() => this.handleOpenUploadWidget(false)}
          centered
        >
          <Modal.Body>
            <a
              className="close-icon"
              onClick={() => this.handleOpenUploadWidget(false)}
            >
              <IconContext.Provider value={{ size: "2rem" }}>
                <BsX />
              </IconContext.Provider>
            </a>
            <UploadWidget {...this.props} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Inkedibles);

import CircleK from "../components/widgets/galleries/CircleK/CircleK";
import Inkedibles from "../components/widgets/galleries/Inkedibles/Inkedibles";

export const STATIC_GALLERY = {
  circlek: CircleK,
  inkedibles: Inkedibles,
};

export const GALLERY_THEME = {
  SLIDER: "slider",
  GRID: "grid",
  MOSAIC: "mosaic",
  SIGNAGE: "signage",
};

export const CALL_TO_ACTION = {
  message: "message",
  QRCode: "QRCode",
};

export const NUMBER_SLIDER_ITEM = [1, 2, 3, 4, 5];

export const MOSAIC_PATTERN = {};

export const CHUNK_SIZE = {
  DESKTOP: [5],
  TABLET: [3, 6],
  MOBILE: [1],
};

export const GRID = {
  ROWS: 2,
  COLS: 3,
};

export const PATTERN_AMOUNT = [2, 3]; //2 - Desktop, 3 - Breakpoint <= 1200px

export const DETAIL_ON_HOVER = {
  showInstagramName: "showInstagramName",
  showTwitterName: "showTwitterName",
};

export const DETAIL_LIGHTBOX = {
  showInstagramUsername: "showInstagramUsername",
  showTwitterUsername: "showTwitterUsername",
  showCaption: "showCaption",
  showFirstNameLastInitial: "showFirstNameLastInitial",
  customDetails: "customDetails",
  customFields: "customFields",
};

export const MEDIA_SIZE_DEFAULT = 600;

export const VIEW_TYPE = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
  SQUARE: "square",
};

export const TYPE_ACTIONS = {
  NEXT: "next",
  PREV: "prev",
};

export const PADDING_FRAME = 30;

export const COLOR_FIELD = {
  GALLERY_BACKGROUND: "galleryBackground",
  CTA_BACKGROUND: "ctaBackground",
  FONT_QR_CODE_COLOR: "fontQRCodeColor",
  IS_FAILED_CONTRAST: "isFailedContrast",
};

export const INPUT_TYPE = {
  DROPDOWN: "dropdown",
};

export const FILTER_FIELD_NAME = {
  CREATOR: "creator",
};

export const DIMENSION = {
  FILTER: {
    HEIGHT: 65,
  },
};

export const KEYBOARD = {
  ARROW_LEFT: {
    STR: "ArrowLeft",
  },
  ARROW_RIGHT: {
    STR: "ArrowRight",
  },
  ARROW_UP: {
    STR: "ArrowUp",
  },
  ARROW_DOWN: {
    STR: "ArrowDown",
  },
  DELETE: {
    STR: "Delete",
  },
  BACKSPACE: {
    STR: "Backspace",
  },
  SPACE: {
    STR: "Space",
  },
  KEY_0: {
    STR: "Digit0",
  },
  KEY_1: {
    STR: "Digit1",
  },
  KEY_2: {
    STR: "Digit2",
  },
  KEY_3: {
    STR: "Digit3",
  },
  KEY_4: {
    STR: "Digit4",
  },
  KEY_5: {
    STR: "Digit5",
  },
  KEY_S: {
    STR: "KeyS",
  },
  KEY_C: {
    STR: "KeyC",
  },
  KEY_F: {
    STR: "KeyF",
  },
  KEY_L: {
    STR: "KeyL",
  },
  KEY_N: {
    STR: "KeyN",
  },
  ENTER: {
    STR: "Enter",
    CODE: 13,
  },
  SHIFT: {
    STR: "Shift",
    CODE: 16,
  },
  ESCAPE: {
    STR: "Escape",
  },
};

export const SUFFIX = {
  CUSTOM_PROPERTIES: "_cp",
};

export const MAX = {
  TAG_TEXT_LENGTH: 8,
  CUSTOM_PROPERTIES_INLINE: 6,
};

export const WIDTH = {
  FILTER_POPUP: {
    W_420: 420,
    W_550: 550,
  },
  FILTER_MENU: {
    W_100: 100,
    W_150: 150,
    W_176: 176,
    W_180: 180,
    W_225: 225,
    W_215: 215,
    W_230: 230,
    W_260: 260,
    W_290: 290,
    W_320: 320,
  },
};

export const NUMBER_OF_LOADED_IMAGES = {
  GRID: 8,
  OTHERS: 5,
  MOBILE: 3,
};

import THEME from "constants/themes";
import styled from "styled-components";

const StyledPropertyContainer = styled.div`
  color: ${THEME.colors.white};
  ${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledText = styled.p`
  ${({ fontWeight }) => fontWeight && `  font-weight: ${fontWeight}`};
  margin: ${({ margin }) => margin || 0};
`;

const StyledTag = styled.div`
  display: inline-block;
  width: fit-content;
  padding: 0 6px;
  border-radius: 4px 4px;
  background: #e0e3e5;
  color: black;
  margin: 0 6px 0 0;
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export { StyledPropertyContainer, StyledText, StyledTag };

import { Badge } from "antd";
import { IconRemoveSelectItem } from "assets/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculateWidth } from "services/gallery";
import { StyledFormItem } from "styled-components/CommonStyled";
import { StyledIcon } from "styled-components/CustomGallery/CustomGalleryStyled";
import {
  StyledFilterSelect,
  StyledFilterSelectWrapper,
  StyledSelectLabel,
} from "styled-components/CustomGallery/GalleryFilterStyled";

const CustomizedSelect = (props) => {
  const { t } = useTranslation();

  const {
    className,
    fieldName,
    label,
    options,
    selectWidth,
    selectedValues = [],
    otherSelectProps,
    onClear,
    ...otherProps
  } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isAnimation, setIsAnimation] = useState(true);
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  const MAX_WIDTH_SELECT = 300;
  const expandWidth = selectedValues?.length ? 50 : 30;

  useEffect(() => {
    handleDismissSelectionDropdown();
  }, [dropdownVisible]);

  useEffect(() => {
    if (selectedValues.length) {
      const transitionEndHandler = () => {
        setIsTransitionComplete(true);
      };
      const selectBox = document.querySelector(`.${className}`);

      if (selectBox) {
        selectBox.addEventListener("transitionend", transitionEndHandler);

        return () => {
          selectBox.removeEventListener("transitionend", transitionEndHandler);
        };
      }
    }
  }, [selectedValues, className]); // Empty dependency array ensures this effect runs only once after initial render

  // When scrolling filter menu
  const handleDismissSelectionDropdown = () => {
    let start = null;
    const containerEl = document.querySelector(".scrollable_wrapper");
    const handleTouchEnd = (e) => {
      let end = e.changedTouches[0];
      if (dropdownVisible && start.screenX - end.screenX !== 0) {
        setDropdownVisible(false);
      }
    };
    const handleTouchStart = (e) => {
      start = e.changedTouches[0];
    };

    if (containerEl) {
      containerEl.addEventListener("touchstart", handleTouchStart);
      containerEl.addEventListener("touchend", handleTouchEnd);

      return () => {
        containerEl.removeEventListener("touchstart", handleTouchStart);
        containerEl.removeEventListener("touchend", handleTouchEnd);
      };
    }
  };

  const count = selectedValues?.length - 1;

  const renderLabel = () => {
    if (selectedValues && selectedValues.length) {
      const labelContainer = MAX_WIDTH_SELECT - 52;
      const labelWidth = calculateWidth(label);
      const firstValueWidth = calculateWidth(`| ${selectedValues[0]}`);
      const maxWidthLabel = selectedValues?.length > 1 ? 112 : 125;
      const totalWidth =
        selectedValues?.length > 1 ? labelContainer - 30 : labelContainer; // maxWidth of select 300 - padding, 220 -> when has the text + the number of selected tags

      const getMaxWidth = (currentWidth, comparedWidth) =>
        currentWidth > maxWidthLabel
          ? `${
              comparedWidth < maxWidthLabel
                ? totalWidth - comparedWidth
                : maxWidthLabel
            }px`
          : undefined;

      return (
        <StyledFilterSelectWrapper>
          <StyledSelectLabel
            className="ibm_plex_sans"
            maxWidth={getMaxWidth(labelWidth, firstValueWidth)}
            margin="0 4px 0 0"
            title={t("gallery_filters.filter_by", {
              field: label,
            })}
          >
            {label}
          </StyledSelectLabel>
          <StyledSelectLabel
            className="ibm_plex_sans"
            visibility={!isTransitionComplete ? "hidden" : "visible"}
            maxWidth={getMaxWidth(firstValueWidth, labelWidth)}
          >{`| ${selectedValues[0]}`}</StyledSelectLabel>
          <StyledSelectLabel
            className="overflow ibm_plex_sans"
            margin="0 0 0 6px"
            visibility={!isTransitionComplete ? "hidden" : "visible"}
          >
            {`${count ? ` +${count}` : ""}`}
          </StyledSelectLabel>
        </StyledFilterSelectWrapper>
      );
    }

    return label;
  };

  const selectWidthToPlaceholder = calculateWidth(
    selectedValues?.length
      ? `${label} | ${selectedValues[0]}${count ? ` +${count}` : ""}`
      : label,
    expandWidth
  );

  return (
    <StyledFormItem name={fieldName} margin="0 12px 0 0" {...otherProps}>
      <StyledFilterSelect
        className={`${className} filter_select ${
          selectedValues && selectedValues.length > 0 ? "other_styles" : ""
        }`}
        isAnimation={isAnimation}
        popupClassName="select_filter_dropdown"
        open={dropdownVisible}
        autoFocus={dropdownVisible}
        mode="multiple"
        showArrow={false}
        allowSearch
        allowClear={{
          clearIcon: (
            <StyledIcon>
              <IconRemoveSelectItem />
            </StyledIcon>
          ),
        }}
        options={options}
        maxTagCount={1}
        placeholder={label}
        onDropdownVisibleChange={(open) => {
          setDropdownVisible(open);
        }}
        onChange={() => {
          setDropdownVisible(false);
          setSearchValue("");
        }}
        width={selectWidthToPlaceholder}
        fontSizePlaceholder="16px"
        onSearch={(value) => {
          setSearchValue(value);
        }}
        maxTagPlaceholder={(omittedValues) => (
          <Badge>{omittedValues.length + 1}</Badge>
        )}
        tagRender={() => (searchValue ? "" : renderLabel())}
        maxWidth={`${MAX_WIDTH_SELECT}px`}
        onClear={() => {
          setIsAnimation(false);
          setIsTransitionComplete(false);
          setTimeout(() => {
            setIsAnimation(true);
          }, 100);
        }}
        {...otherSelectProps}
      />
    </StyledFormItem>
  );
};

export default React.memo(CustomizedSelect);

const errorFieldPrefix = "(!)";

export default {
  callAPIFailed:
    "Sorry, our service is under maintenance. Please try in a couple of minutes.",
  requiredEmail: `${errorFieldPrefix} Please enter email.`,
  requiredFirstName: `${errorFieldPrefix} Please enter first name.`,
  requiredLastname: `${errorFieldPrefix} Please enter last name.`,
  requiredInstagram: `${errorFieldPrefix} Please enter instagram user name.`,
  invalidEmail: `${errorFieldPrefix} Email address is invalid.`,
  requiredSelect: `${errorFieldPrefix} Please select at least a single option.`,
  requiredFile: "Please select file(s) to upload.",
  multipleVideos: `We don't allow multiple videos to be uploaded at the same time.`,
  maxFiles: "Please select 20 items or less.",
  invalidFileType: "Please upload video or image.",
  invalidFilesize:
    "The selected image is too big. Please choose one with maximum dimensions of 6000 x 6000 up to 150MB in size.",
  uploadFailed: "Could not upload content.",

  invalidChallengeId: "Invalid Campaign ID.",
  invalidSelectId: "Invalid Select ID.",
};

import { useEffect } from "react";

export const useIsVisible = (ref, callback, otherDependencies) => {
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (callback) callback(ref);
      }
    });

    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, otherDependencies]);
};

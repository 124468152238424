import React from "react";
import {
  StyledFooterCarousel,
  StyledImageLogoFooter,
} from "../../../../styled-components/CustomGallery/SliderGalleryStyled";
import { StyledHyperlink } from "../../../../styled-components/CustomGallery/CustomGalleryStyled";
import { S3Server, landingURL } from "../../../../config";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import THEME from "../../../../constants/themes";
const whiteLogo = `${S3Server}/gallery/logo-white.png`;
const blackLogo = `${S3Server}/gallery/logo-black.png`;

const GalleryFooter = (props) => {
  const {
    customizeText = {
      color: "",
      fontSize: "",
    },
  } = props;
  const { clientSettingWhitelabeling } = useSelector((state) => state.gallery);

  const renderLabel = useMemo(() => {
    if (!clientSettingWhitelabeling) {
      return (
        <StyledHyperlink target="_blank" href={landingURL}>
          <StyledFooterCarousel customize={customizeText} className="footer">
            Powered by
            <StyledImageLogoFooter
              src={
                customizeText.color === THEME.colors.white
                  ? whiteLogo
                  : blackLogo
              }
            />
          </StyledFooterCarousel>
        </StyledHyperlink>
      );
    }

    return null;
  }, [clientSettingWhitelabeling]);

  return <>{renderLabel}</>;
};

GalleryFooter.propTypes = {
  customizeText: PropTypes.object,
};

export default GalleryFooter;

import {
  APIServer,
  AppServer,
  S3Server,
  socialS3URL,
  HomeUrl,
  ipstackKey,
} from "../config";

let API = {
  getCreator: "creator/public/info-creator", // PUT
  getChallengeById: "widget/validate-campaign", // GET
  upload: "content/public/upload-content", // POST
  getGallery: "content/hub/gallery", // PUT
  getGalleryCreators: "client/gallery/creators", // GET
  gallery: "content/hub/gallery",
};

let App = {
  home: "",
  terms: "terms",
  privacy: "privacy",
};

let externalAPI = {
  getLocation: `${ipstackKey}`,
};

Object.keys(API).forEach((key) => {
  API[key] = `${APIServer}/${API[key]}`;
});

Object.keys(App).forEach((key) => {
  App[key] = `${AppServer}/${App[key]}`;
});

export default { API, App, externalAPI, S3Server, socialS3URL, HomeUrl };

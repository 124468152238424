import { call } from "redux-saga/effects";
import { STATUS_CODE } from "../constants/APIs";
import axios from "axios";
import { DEFAULT_ERROR } from "../constants/common";

function* genericApiSaga({ completed, failed, gatewayCall }) {
  try {
    const response = yield call(gatewayCall);
    switch (response.status) {
      case STATUS_CODE.FAILED404:
        if (failed) {
          yield failed(response.body || response.data);
        }
        break;

      case STATUS_CODE.FAILED400:
        if (failed) {
          yield failed(response.body || response.data);
        }
        break;

      case STATUS_CODE.FAILED401:
        if (failed) {
          yield failed(response.body || response.data);
        }
        break;

      case STATUS_CODE.SUCCESS:
        if (completed) {
          yield completed(response.body || response.data);
        }
        break;

      default:
        break;
    }
  } catch (error) {
    yield failed(error || { message: "System error" });
  }
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    const status = response ? response.status : null;

    if (status === STATUS_CODE.FAILED401) {
      return;
    }

    return response;
  }
);

const requestHelpers =
  (method) =>
  async ({ apiUrl, params, contentType, isEncode, customHeaders }) => {
    const url = new URL(apiUrl);
    let headers = {
      "Content-Type": contentType || "application/json",
    };

    if (customHeaders) {
      headers = { ...headers, ...customHeaders };
    }

    if (method === "get" && params && Object.keys(params).length) {
      Object.keys(params).forEach((key) => {
        if (!isEncode) {
          url.searchParams.append(key, params[key]);
        } else {
          url.searchParams.append(key, encodeURIComponent(params[key]));
        }
      });
    }

    const config =
      params && params.timeout
        ? {
            headers,
            validateStatus: () => true,
            timeout: params.timeout,
          }
        : { headers, validateStatus: () => true };

    const generalConfig = {
      method,
      url: url.toString(),
      data: params,
      headers,
      config,
    };

    const promise = axiosInstance
      .request(generalConfig)
      .then(async (response) => ({
        status: response ? response.status : STATUS_CODE.FAILED504,
        body: response || DEFAULT_ERROR,
      }))
      .catch((e) => {
        throw new Error(e);
      });

    return promise;
  };

const getRequest = requestHelpers("get");
const postRequest = requestHelpers("post");
const putRequest = requestHelpers("put");
const deleteRequest = requestHelpers("delete");
const patchRequest = requestHelpers("patch");

export {
  genericApiSaga,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest,
};

import _ from "lodash";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useMemo, useRef, useState } from "react";
import { SCREEN_MODE } from "../../../../../constants/common";
import {
  CALL_TO_ACTION,
  CHUNK_SIZE,
  GALLERY_THEME,
  GRID,
  PADDING_FRAME,
  PATTERN_AMOUNT,
} from "../../../../../constants/gallery";
import THEME from "../../../../../constants/themes";
import {
  StyledMosaicContainer,
  StyledMosaicItem,
  StyledMosaicWrapper,
  StyledQRCode,
  StyledSignageMessage,
  StyledSignageMessageText,
  StyledSlider,
} from "../../../../../styled-components/CustomGallery/MosaicLayoutStyled";
import GalleryFooter from "../../../../shared/section/GalleryFooter/GalleryFooter";
import ThumbnailMedia from "../ThumbnailMedia/ThumbnailMedia";

const MosaicGallery = (props) => {
  const sliderRef = useRef();
  const mosaicRef = useRef();
  const signageMessageRef = useRef();
  const {
    contentList,
    screenMode,
    renderOverlay,
    handleSelectContent,
    isThumbnailGif,
    metadata,
    setIsDragging,
    settings,
    containerDimension,
  } = props;
  const { width, height } = containerDimension || {};
  const [customContentList, setCustomContentList] = useState([]); // [[Slider 1], [Slider 2], [Slider 3]]
  const [signageMessageHeight, setSignageMessageHeight] = useState(0);
  const {
    ctaBackground = THEME.colors.white,
    fontQRCodeColor = THEME.colors.black,
  } = metadata || {};

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    initData();
  }, [contentList, screenMode]);

  useEffect(() => {
    if (signageMessageRef.current) {
      const { clientHeight } = signageMessageRef.current;
      if (signageMessageHeight !== clientHeight) {
        setSignageMessageHeight(clientHeight);
      }
    }
  }, [signageMessageRef.current, containerDimension]);

  const resetSlider = () => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const onResize = () => {
    resetSlider();
  };

  const initData = () => {
    const newListMosaic = [];
    let itemGroupMosaic = [];
    let sliderTypeIndex = 1; // counts the index of slider (3, 6, 3) with the screen mode M.
    const middlePatternIndex = 2;
    let chunkSize = 0; // is used to fill missing contents into the last slider.

    contentList.forEach((item, index) => {
      if (sliderTypeIndex > PATTERN_AMOUNT[1]) {
        sliderTypeIndex = 1;
      }

      if (
        (screenMode === SCREEN_MODE.MOSAIC.L &&
          itemGroupMosaic?.length < CHUNK_SIZE.DESKTOP[0]) ||
        (screenMode === SCREEN_MODE.MOSAIC.S &&
          itemGroupMosaic?.length < CHUNK_SIZE.MOBILE[0]) ||
        (screenMode === SCREEN_MODE.MOSAIC.M &&
          (sliderTypeIndex === middlePatternIndex
            ? itemGroupMosaic?.length < CHUNK_SIZE.TABLET[1]
            : itemGroupMosaic?.length < CHUNK_SIZE.TABLET[0]))
      ) {
        itemGroupMosaic.push(item);
      } else {
        newListMosaic.push(itemGroupMosaic);
        itemGroupMosaic = [item];
        sliderTypeIndex += 1;
      }

      if (index === contentList?.length - 1 && itemGroupMosaic?.length > 0) {
        // fill the missing content in the last slider.
        if (screenMode === SCREEN_MODE.MOSAIC.M) {
          if (sliderTypeIndex === middlePatternIndex) {
            chunkSize = CHUNK_SIZE.TABLET[1];
          } else {
            chunkSize = CHUNK_SIZE.TABLET[0];
          }
        } else if (screenMode === SCREEN_MODE.MOSAIC.L) {
          chunkSize = CHUNK_SIZE.DESKTOP[0];
        }

        if (
          itemGroupMosaic.length <= chunkSize &&
          contentList.length > chunkSize
        ) {
          itemGroupMosaic = _.concat(
            itemGroupMosaic,
            contentList.slice(0, chunkSize - itemGroupMosaic.length)
          );
        }

        newListMosaic.push(itemGroupMosaic);
      }
    });

    setCustomContentList([...newListMosaic]);
  };

  const renderQRCode = (url) => {
    if (url) {
      return (
        <QRCodeSVG
          value={url}
          bgColor={ctaBackground}
          fgColor={fontQRCodeColor}
        />
      );
    }
    return null;
  };

  const prefixClassName = useMemo(() => {
    return metadata?.theme === GALLERY_THEME.SIGNAGE ? "signage" : "mosaic";
  }, [metadata]);

  const renderLayout = () => {
    return contentList.length ? (
      customContentList.map((content, containerIndex) => {
        return (
          <StyledMosaicContainer
            className={`${prefixClassName}-container`}
            key={`${containerIndex}`}
            width={width}
            height={height}
            signageMessageHeight={signageMessageHeight}
          >
            {content.map((item, index) => {
              let className = `mosaic-item `;
              let activeItemIndex = 0;
              let columnStart = 0;

              if (screenMode === SCREEN_MODE.MOSAIC.L) {
                activeItemIndex =
                  ((containerIndex + 1) % GRID.COLS) * GRID.ROWS;
                columnStart = activeItemIndex / GRID.ROWS + 1;
              } else {
                activeItemIndex = (containerIndex + 2) % GRID.COLS;
                columnStart = activeItemIndex;
              }

              if (
                index === activeItemIndex &&
                content.length !== CHUNK_SIZE.TABLET[1]
              ) {
                className += "active-item";
                item = { ...item, isActiveContent: true };
              }

              /* In case the signage gallery is contained an element which is not set a specific with and height
              => practicalHeight <= 0
              => is broken layout */
              if (metadata?.theme === GALLERY_THEME.SIGNAGE) {
                const practicalHeight =
                  height - PADDING_FRAME * 2 - signageMessageHeight;

                className +=
                  practicalHeight <= 0 ? " aspect_ratio" : " none_aspect_ratio";
              }

              const props = {
                columnStart,
                className: `${prefixClassName} ${className}`,
              };

              return (
                <StyledMosaicItem
                  {...props}
                  key={`${item?.id}_${containerIndex}_wrapper`}
                  onClick={() => handleSelectContent(item)}
                >
                  <ThumbnailMedia
                    content={item}
                    isThumbnailGif={isThumbnailGif}
                    renderOverlay={renderOverlay}
                  />
                </StyledMosaicItem>
              );
            })}
          </StyledMosaicContainer>
        );
      })
    ) : (
      <StyledMosaicContainer
        className={`${prefixClassName}-container`}
        width={width}
        height={height}
        signageMessageHeight={signageMessageHeight}
      />
    );
  };

  const renderQRCodeContainer = () => {
    if (metadata.uploaderLink) {
      return (
        <StyledQRCode className="qr_code" width={width}>
          {renderQRCode(metadata.uploaderLink.url)}
        </StyledQRCode>
      );
    }

    return null;
  };

  const renderMosaicGallery = () => {
    const customizeText = {
      color:
        metadata?.theme === GALLERY_THEME.SIGNAGE
          ? THEME.colors.white
          : THEME.colors.black,
    };

    const hasSignageMessage =
      metadata?.uploaderLink?.message &&
      metadata?.uploaderLink?.options?.includes(CALL_TO_ACTION.message);

    const hasQRCode = metadata?.uploaderLink?.options?.includes(
      CALL_TO_ACTION.QRCode
    );

    return (
      <>
        <StyledMosaicWrapper
          id="mosaic-signage-wrapper"
          ref={mosaicRef}
          className={`${prefixClassName}-wrapper`}
        >
          <StyledSlider
            className={`${prefixClassName}-slider`}
            ref={sliderRef}
            onSwipe={() => setIsDragging(true)}
            afterChange={() => setIsDragging(false)}
            {...settings}
          >
            {renderLayout()}
          </StyledSlider>

          {metadata?.theme !== GALLERY_THEME.SIGNAGE && (
            <GalleryFooter customizeText={customizeText} />
          )}
        </StyledMosaicWrapper>
        {metadata?.theme === GALLERY_THEME.SIGNAGE ? (
          <>
            {(hasSignageMessage || hasQRCode) && (
              <StyledSignageMessage
                ref={signageMessageRef}
                width={width}
                ctaBackground={ctaBackground}
                fontQRCodeColor={fontQRCodeColor}
              >
                {hasSignageMessage && (
                  <StyledSignageMessageText width={width} hasQRCode={hasQRCode}>
                    {metadata?.uploaderLink?.message}
                  </StyledSignageMessageText>
                )}
                {hasQRCode && renderQRCodeContainer()}
              </StyledSignageMessage>
            )}
          </>
        ) : null}
      </>
    );
  };

  return <>{containerDimension && renderMosaicGallery()}</>;
};

MosaicGallery.propTypes = {
  galleryDetail: PropTypes.object,
  contentList: PropTypes.array,
  screenMode: PropTypes.string,
  renderOverlay: PropTypes.func,
  handleSelectContent: PropTypes.func,
  isThumbnailGif: PropTypes.bool,
  metadata: PropTypes.object,
  setIsDragging: PropTypes.func,
};

export default MosaicGallery;

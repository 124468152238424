import { INPUT_TYPE } from "constants/gallery";
import ContentDetailContext from "contexts/ContentDetail";
import { useContext } from "react";
import {
  StyledPropertyContainer,
  StyledTag,
  StyledText,
} from "styled-components/CustomGallery/CustomPropertiesStyled";

const CustomProperties = () => {
  const { content } = useContext(ContentDetailContext);
  const { review: { custom = {} } = {} } = content;

  const renderSelectOptions = (values) => {
    values = typeof values === "string" ? [values] : values;

    return values.map((item) => {
      return (
        <StyledTag title={item} key={Math.random()}>
          {item}
        </StyledTag>
      );
    });
  };

  const renderProperty = () => {
    return (
      custom &&
      custom.map((item) => {
        const isDropdown = item?.type.toLowerCase() === INPUT_TYPE.DROPDOWN;
        return (
          <StyledPropertyContainer
            key={item.key || Math.random()}
            margin="0 0 16px 0"
          >
            <StyledText
              margin={isDropdown ? "0 0 8px 0" : "0"}
              fontWeight="700"
            >
              {item?.name || item?.placeholder}
            </StyledText>
            {isDropdown ? (
              renderSelectOptions(item?.values)
            ) : (
              <StyledText>{item?.values}</StyledText>
            )}
          </StyledPropertyContainer>
        );
      })
    );
  };

  return <>{renderProperty()}</>;
};

export default CustomProperties;

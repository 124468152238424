import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { CaretRightOutlined } from "@ant-design/icons";
import { IconEye } from "assets/icons";
import { URL_CONTENT_EXTERNAL_API } from "constants/APIs";
import THEME from "constants/themes";
import { useTranslation } from "react-i18next";
import { getRequest } from "services/api";
import { checkExplicitContent } from "services/gallery";
import {
  StyledExplicitOverlay,
  StyledSection,
  StyledTitle,
} from "styled-components/CommonStyled";
import {
  StyledIcon,
  StyledSkeletonImage,
} from "styled-components/CustomGallery/CustomGalleryStyled";
import {
  FILE_TYPE,
  HTML_TAG_ATTRIBUTE,
  MEDIA_TYPE,
  PLACEHOLDER_IMAGE_IN_BASE64,
  SOCIAL,
} from "../../../../../constants/common";
import utils from "../../../../../services/utils";
import {
  StyledIconPlay,
  StyledImageCarousel,
} from "../../../../../styled-components/CustomGallery/SliderGalleryStyled";

const ThumbnailMedia = (props) => {
  const { content, isThumbnailGif, renderOverlay } = props;
  const { t } = useTranslation();
  const [thumbnail, setThumbnail] = useState(content?.thumbnail);
  const [contentUrl, setContentUrl] = useState(thumbnail);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const refError = useRef(true);

  useEffect(() => {
    if (content.isActiveContent && content?.mediaType !== MEDIA_TYPE.VIDEO) {
      setThumbnail(content?.contentUrl);
    }
  }, [content]);

  useEffect(() => {
    if (setContentUrl !== thumbnail) {
      refError.current = true;
      setContentUrl(thumbnail);
    }
  }, [thumbnail]);

  const handleConvertThumbnail = async () => {
    const gif = FILE_TYPE.GIF;
    const png = FILE_TYPE.PNG;
    const positionType = content?.thumbnail?.lastIndexOf(".");
    const urlNotType = content?.thumbnail?.slice(0, positionType);
    const type = content?.thumbnail?.slice(positionType);
    const renderUrl = (tail) => {
      return urlNotType + tail;
    };

    if (!isThumbnailGif) {
      if (type === gif) {
        const url = renderUrl(png);
        setThumbnail(url);
      } else {
        setThumbnail(content?.thumbnail);
      }
    } else {
      setThumbnail(renderUrl(gif));
    }
  };

  useEffect(() => {
    if (content?.mediaType === MEDIA_TYPE.VIDEO) {
      handleConvertThumbnail();
    }
  }, [isThumbnailGif]);

  const handleSaveUrl = (val) => {
    if (!utils.isURL(val)) {
      setIsError(true);
    }
    setContentUrl(val);
  };

  const handleFetchUrl = async () => {
    const { contentExternalUrl } = content || {};
    await getRequest(URL_CONTENT_EXTERNAL_API, { url: contentExternalUrl })
      .then((response) => {
        {
          const newUrl = response.body.data.result;
          if (response.status === 200 && newUrl) {
            handleSaveUrl(newUrl);
          } else {
            handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
          }
        }
      })
      .catch((err) => {
        handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
      });
  };

  const handleError = async (e) => {
    if (refError.current) {
      refError.current = false;
      const { contentExternalUrl, social, mediaType } = content || {};
      const isVideo = mediaType === MEDIA_TYPE.VIDEO;
      if (social && SOCIAL.TIKTOK === social.socialSource) {
        const newUrl = await utils.handleImageThumbnailLoadedError(
          e,
          SOCIAL.TIKTOK,
          social.permalink
        );
        handleSaveUrl(newUrl);
        return;
      }
      if (contentExternalUrl && utils.isURL(contentExternalUrl)) {
        if (isVideo) {
          handleFetchUrl();
          return;
        }
      }

      const newUrl = utils.handleImageLoadedError(
        e,
        thumbnail,
        contentExternalUrl
      );
      handleSaveUrl(newUrl);
    } else {
      handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
    }
  };

  const renderExplicitOverlay = () => {
    return (
      <StyledExplicitOverlay zIndex={99} borderRadius="4px">
        <StyledSection>
          <StyledIcon
            className="absolute"
            color={THEME.colors.gray4}
            width="26px"
            height="26px"
          >
            <IconEye />
          </StyledIcon>
          <StyledTitle margin="6px 0 0 0">{t("sensitive_content")}</StyledTitle>
        </StyledSection>
      </StyledExplicitOverlay>
    );
  };

  const renderVideoIcon = (item) => {
    if (item.mediaType === MEDIA_TYPE.VIDEO) {
      return (
        <StyledIconPlay className="icon_play">
          <CaretRightOutlined />
        </StyledIconPlay>
      );
    }
    return "";
  };

  const otherMediaAttrs = useMemo(() => {
    let altText = content?.review?.altText || "";
    altText = altText.trim();

    const attrs = {
      [HTML_TAG_ATTRIBUTE.ARIA_LABEL]: altText,
    };

    if (isError && altText) {
      attrs.title = altText.trim();
    }

    return { ...attrs };
  }, [isError, content?.review?.altText]);

  return (
    <>
      <StyledImageCarousel
        isLoaded={isLoaded}
        key={`${content.id}_img`}
        src={contentUrl}
        onLoad={() => {
          setIsLoaded(true);
        }}
        onError={(e) => handleError(e)}
        {...otherMediaAttrs}
      />

      {!isLoaded && <StyledSkeletonImage active />}
      {isLoaded && (
        <>
          {renderVideoIcon(content)}
          {!isError && renderOverlay(content)}
          {checkExplicitContent(content?.review?.flags) &&
            renderExplicitOverlay()}
        </>
      )}
    </>
  );
};

ThumbnailMedia.propTypes = {
  content: PropTypes.object,
  isThumbnailGif: PropTypes.bool,
};

export default React.memo(ThumbnailMedia);

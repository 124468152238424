const THEME = {
  colors: {
    primary: "#e2582b",
    secondary: "#70C2F2",
    dark: "#020c15",
    gray: "#575757",
    lightGray: "#C4C4C4",
    lighterGray: "#F5F5F5",
    white: "#FFFFFF",
    blue: "#1392DB",
    lightBlue: "#A8DFFF",
    darkBlue: "#021627",
    darkerBlue: "#020c15",
    black: "#000",
    blueBase: "#1890ff",
    greenBase: "#52c41a",
    redBase: "#f5222d",
    orangeBase: "#e2582b",
    yellowBase: "#faad14",
    violetBase: "#7f61fb",
    darkBlue1: "#020c15",
    darkBlue2: "#011627",
    darkBlue3: "#203241",
    darkBlue4: "#344552",
    darkBlue5: "#4d5b67",
    gray1: "#67737d",
    gray2: "#808a93",
    gray3: "#a3abb1",
    gray4: "#ccd0d4",
    gray5: "#e0e3e5",
    transparent: "transparent",
  },
  fonts: {
    primary: "Open Sans",
    secondary: "Open Sans",
  },
  fontSizes: {
    h1: "56px",
    h2: "42px",
    h3: "24px",
    h4: "22px",
    h5: "18px",
    large: "16px",
    normal: "14px",
    small: "12px",
    xLarge: "18px",
  },
  spaces: [
    "0px",
    "2px",
    "4px",
    "8px",
    "12px",
    "16px",
    "20px",
    "24px",
    "28px",
    "32px",
  ],
  fontWeight: {
    normal: 400,
    bold: 600,
    h2: 700,
    h1: 900,
  },
  disabledOpacity: 0.6,
  hoveredOpacity: 0.8,
};

export default THEME;

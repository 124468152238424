import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { STATIC_GALLERY } from "../../../../constants/gallery";
import GalleryContext from "../../../../contexts/Gallery";
import utils from "../../../../services/utils";
import { getClientSettingRequest } from "../../../../stores/actions";
import GalleryWrapper from "./GalleryWrapper/GalleryWrapper";

const CustomGallery = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [pendoTrackingData, setPendoTrackingData] = useState();
  const [customPropertyList, setCustomPropertyList] = useState([]);
  const [isFiltered, setIsFiltered] = useState();
  const [filtersApplied, setFiltersApplied] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { pathname, search } = location;
  const pathName = pathname.includes("/:")
    ? utils.removeFirstSlash(pathname).split("/")[0]
    : pathname;

  const { globalProps } = props;

  useEffect(() => {
    const globalInfo = globalProps?.globalInfo;
    if (globalInfo?.clientIdGlobal && globalInfo?.galleryIdGlobal) {
      const clientId = globalInfo?.clientIdGlobal;
      dispatch(getClientSettingRequest({ clientId }));
    } else {
      if (search) {
        let searchParams = utils.convertSearchParamsToObj(search);
        if (searchParams && searchParams.cl) {
          const clientId = searchParams.cl;
          dispatch(getClientSettingRequest({ clientId }));
        }
      }
    }
  }, []);

  const renderGallery = () => {
    const StaticGallery = STATIC_GALLERY[utils.removeFirstSlash(pathName)];

    if (StaticGallery) return <StaticGallery globalProps={globalProps} />;
    else return <GalleryWrapper globalProps={globalProps} />;
  };

  const galleryWrapperValues = {
    setPendoTrackingData,
    pendoTrackingData,
    customPropertyList,
    setCustomPropertyList,
    filtersApplied,
    setFiltersApplied,
    isFiltered,
    setIsFiltered,
    isLoading,
    setIsLoading,
  };

  return (
    <GalleryContext.Provider value={galleryWrapperValues}>
      {renderGallery()}
    </GalleryContext.Provider>
  );
};

CustomGallery.propTypes = {
  globalProps: PropTypes.object,
};

export default CustomGallery;

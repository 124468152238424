export default {
  tl: {
    teampixel: 269, // Account: teampixel
    inkedibles: 270, // Account: inkedibles
    circlek: 276, // Account circlek
  },
  qa: {
    teampixel: 269, // Account: teampixel
    inkedibles: 270, // Account: inkedibles
    circlek: 276, // Account circlek
  },
  prod: {
    teampixel: 87, // Account: teampixel
    inkedibles: 98, // Account: inkedibles
    circlek: 62,
  },
  qav2: {
    teampixel: 51, // Account: teampixel
    inkedibles: 32, // Account: inkedibles
    circlek: 60,
  },
  prodv2: {
    teampixel: 87, // Account: teampixel
    inkedibles: 63, // Account: inkedibles
    circlek: 1,
  },
};

import { IconClose } from "assets/icons";
import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  StyledIconClose,
  StyledIframe,
  StyledUploaderModal,
} from "styled-components/CustomGallery/UploaderModalStyled";

const UploaderModal = (props) => {
  const { visible, uploaderLink, onCancel, dimension } = props;

  return (
    <StyledUploaderModal
      open={visible}
      width="fit-content"
      footer={false}
      onCancel={onCancel}
      closable={false}
      centered
      style={{
        margin: "0 auto",
        textAlign: "center",
        pointerEvents: "auto !important",
      }}
    >
      <StyledIconClose onClick={onCancel}>
        <IconClose />
      </StyledIconClose>
      <StyledIframe
        src={`${uploaderLink.url}${
          uploaderLink.url.includes("?") ? "&" : "?"
        }script_tag=true`}
        dimension={dimension}
        allow="geolocation *"
      />
    </StyledUploaderModal>
  );
};

UploaderModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  dimension: PropTypes.object,
};

export default UploaderModal;

const getClientSettingTypes = {
  GET_CLIENT_SETTING_REQUEST: "GET_CLIENT_SETTING_REQUEST",
  GET_CLIENT_SETTING_SUCCEEDED: "GET_CLIENT_SETTING_SUCCEEDED",
  GET_CLIENT_SETTING_FAILED: "GET_CLIENT_SETTING_FAILED",
  GET_CLIENT_SETTING_END: "GET_CLIENT_SETTING_END",
};

const storeDataTypes = {
  STORE_DATA_REQUEST: "STORE_DATA_REQUEST",
};

export { getClientSettingTypes, storeDataTypes };

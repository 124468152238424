import { createAction } from "redux-actions";
import { getClientSettingTypes, storeDataTypes } from "./gallery.types";

export const getClientSettingRequest = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_REQUEST
);
export const getClientSettingSucceeded = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_SUCCEEDED
);
export const getClientSettingFailed = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_FAILED
);
export const getClientSettingEnd = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_END
);

export const storeDataRequest = createAction(storeDataTypes.STORE_DATA_REQUEST);

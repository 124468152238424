import { put, takeLatest } from "redux-saga/effects";
import { CLIENT_SETTING } from "../../constants/APIs";
import { genericApiSaga, getRequest } from "../../services/api";
import * as actions from "../actions";
import { getClientSettingTypes } from "./gallery.types";

export function* getClientSetting({ payload }) {
  const metadata = {
    apiUrl: CLIENT_SETTING,
    customHeaders: {
      "x-client-id": payload.clientId,
    },
  };
  yield genericApiSaga({
    gatewayCall: () => getRequest({ ...metadata }),
    *completed(response) {
      yield put(actions.getClientSettingSucceeded(response));
    },
    *failed(response) {
      yield put(actions.getClientSettingFailed(response));
    },
  });
}

export function* watcherGallerySaga() {
  yield takeLatest(
    getClientSettingTypes.GET_CLIENT_SETTING_REQUEST,
    getClientSetting
  );
}

import styled, { css } from "styled-components";
import { GALLERY_THEME } from "../../constants/gallery";
import resolution from "../../constants/resolution";
import THEME from "../../constants/themes";
import { StyledOverlay } from "./CustomGalleryStyled";

const POSITION_ARROW = {
  SLIDER: 3.25,
  GRID: -5.25,
};

const StyleContainerCarousel = styled.div`
  padding: ${({ padding }) => padding || "30px"};
  width: 100%;
  ${({ opacity }) => `opacity:${opacity} !important`};
  transition: opacity 2s ease-in-out;

  &.initial_loading {
    padding: 16px 30px;

    .slick-slide {
      max-height: ${({ maxHeight }) => `${maxHeight}px`};
      overflow: hidden;

      * {
        height: ${({ maxHeight }) => `${maxHeight}px !important`};
      }
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transition-delay: 10ms !important;
  }

  .slick-prev,
  .slick-next {
    display: ${({ isShowNavigationArrows }) =>
      isShowNavigationArrows ? "block" : "none !important"};
  }

  &.slider_carousel {
    text-align: center;
    max-width: 100%;
    min-height: ${({ subtractedHeight }) =>
      `calc(100vh - ${subtractedHeight}px)`};

    .image_carousel {
      outline: none;
    }

    &.mosaic_theme,
    &.signage_theme,
    &.grid_theme {
      .footer {
        justify-content: left;
        margin-left: 6px;
      }
    }

    &.mosaic_theme {
      min-height: 100vh;

      .slick-slide {
        padding: 0 6px !important;
      }
    }

    &.signage_theme {
      height: ${({ subtractedHeight }) => `calc(100% - ${subtractedHeight}px)`};
      min-height: ${({ subtractedHeight }) =>
        `calc(100% - ${subtractedHeight}px) !important`};
      overflow: hidden;
    }

    &.grid_theme {
      .slick-list {
        .slick-track {
          margin: unset;
          overflow: hidden;
        }

        .slick-slide {
          padding: 0 0.5px;
        }
      }

      .slick-prev {
        z-index: 50;
      }

      .slick-next {
        z-index: 50;
      }
    }

    &.slider_theme {
      ${({ dimension: { width } = {} }) =>
        width < resolution.minDesktop &&
        css`
          .slick-prev {
            left: ${POSITION_ARROW.SLIDER - 1.25}% !important;
          }

          .slick-next {
            right: ${POSITION_ARROW.SLIDER - 1.25}% !important;
          }
        `}
      ${({ dimension: { width } = {} }) =>
        width < resolution.tabletM &&
        css`
          padding: ${({ padding }) => padding || "30px"};

          .slick-prev {
            left: -15px !important;
          }

          .slick-next {
            right: -15px !important;
          }
        `}
        .slick-slide {
        aspect-ratio: 1;
        display: inline-flex;

        > div:first-of-type {
          box-sizing: unset;
          width: 100%;
          height: 100%;
        }

        .image_carousel {
          width: 100%;
          height: 100%;
          transition: 0.25s;
          padding-top: 40px;
          padding-bottom: 40px;

          &.image_left {
            padding-right: 6px;
            padding-left: calc(20% + 6px);
            transform: scale(0.95);
            transition: padding 0.2s ease-out;

            .icon_play {
              transform: scale(0.95) translate(-50%, -50%);
            }
          }

          &.image_right {
            padding-left: 6px;
            padding-right: calc(20% + 6px);
            transform: scale(0.95);
            transition: padding 0.2s ease-out;

            .icon_play {
              transform: scale(0.95) translate(-50%, -50%);
            }
          }

          &.image_carousel_center {
            padding-top: 0;
            padding-bottom: 0;
          }

          &.image_carousel_left {
            padding-top: 18px;
            padding-bottom: 18px;
            transform: scale(0.975);
            padding-right: 12px;
            transition: padding 0.2s ease-out;

            .icon_play {
              transform: scale(0.975) translate(-50%, -50%);
            }
          }

          &.image_carousel_right {
            padding-top: 18px;
            padding-bottom: 18px;
            transform: scale(0.975);
            padding-left: 12px;
            transition: padding 0.2s ease-out;

            .icon_play {
              transform: scale(0.975) translate(-50%, -50%);
            }
          }
        }
      }

      .slick-prev {
        left: ${POSITION_ARROW.SLIDER}%;
        z-index: 50;
      }

      .slick-next {
        right: ${POSITION_ARROW.SLIDER}%;
        z-index: 50;
      }
    }

    ${({ dimension: { width } = {} }) =>
      width < resolution.tabletS &&
      css`
        &.grid_theme {
          .footer {
            justify-content: center;
          }
        }
      `}

    ${({ dimension: { width } = {} }) =>
      width < resolution.mobileXL &&
      css`
        min-height: 100% !important;
        height: -webkit-fill-available;
        overflow: hidden;

        &.slider_theme,
        &.grid_theme,
        &.mosaic_theme {
          .slick-prev {
            left: -15px !important;
          }

          .slick-next {
            right: -15px !important;
          }

          .image_carousel {
            padding: 0 !important;
          }

          .slick-slide {
            margin: 0 !important;
            padding: 0 5px !important;
          }
        }
      `}

    ${({ dimension: { width } = {} }) =>
      width < resolution.mobileL1 &&
      css`
        &.signage_theme,
        &.mosaic_theme {
          .footer {
            justify-content: center;
          }
        }
      `}
  }

  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;

    &:active {
      rect {
        fill: ${THEME.colors.orangeBase};
      }
    }
  }

  .slick-arrow {
    &.slick-disabled {
      pointer-events: none;
      opacity: 0.9;
    }
  }

  ${({ dimension: { width } = {} }) =>
    width < resolution.mobileXL &&
    css`
      &:not(.signage_theme) {
        padding: 30px 18px;
      }
    `}
`;

const StyledWrapperImageCarousel = styled.div`
  position: relative;
  aspect-ratio: 1;

  &.grid {
    padding: 6px;
  }

  &.slider {
    box-sizing: border-box;
  }
`;

const StyledPrevButton = styled.div`
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledNextButton = styled.div`
  position: absolute;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;

  &:hover ${StyledOverlay} {
    opacity: 1;
  }
`;

const StyledWrapperCarousel = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  ${({ theme }) =>
    theme === GALLERY_THEME.SIGNAGE && "height: 100%; min-height: unset;"};
  ${({ theme }) => theme === GALLERY_THEME.MOSAIC && "min-height: 100vh;"};
  background: ${({ bgColor }) => bgColor || "transparent"};
`;

const StyledBgEmpty = styled.div`
  width: 100%;
  background: transparent;
  height: 100%;
  border-radius: 2px;
`;

const StyledImageCarousel = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ isLoaded }) => `opacity: ${isLoaded ? 1 : 0}`};
`;

const StyledIconPlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-color: ${THEME.colors.darkBlue2};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${THEME.colors.gray2};
    width: 16px;
  }
`;

const StyledFooterCarousel = styled.div`
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto;
  color: ${({ customize }) => customize.color || THEME.colors.black};
  font-style: italic;
  font-weight: 400;
  font-size: ${({ customize }) => customize.fontSize || "12.5px"};
  white-space: nowrap;
`;

const StyledImageLogoFooter = styled.img`
  height: 14px;
  margin-left: 7px;
`;

const StyledHyperlinkWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`;

const StyledSliderWrapper = styled.div`
  position: relative;
  /* padding: 30px; */

  .icon-slick_arrow {
    width: 40px;
    height: 40px;
  }
`;

const StyledHyperlinkWidget = styled.a`
  width: fit-content;
  display: inline-block;
  font-size: 1.429rem;
  color: ${THEME.colors.black};
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${THEME.colors.orangeBase};
    text-decoration: underline;
  }
`;

export {
  StyleContainerCarousel,
  StyledBgEmpty,
  StyledFooterCarousel,
  StyledHyperlinkWidget,
  StyledHyperlinkWrapper,
  StyledIconPlay,
  StyledImageCarousel,
  StyledImageLogoFooter,
  StyledImageWrapper,
  StyledNextButton,
  StyledPrevButton,
  StyledSliderWrapper,
  StyledWrapperCarousel,
  StyledWrapperImageCarousel,
};

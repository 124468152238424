import axios from "axios";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Router } from "react-router-dom";
import Routes from "./routes";
import history from "./services/history";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import _ from "lodash";
import "./App.scss";
import utils from "./services/utils";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useDispatch } from "react-redux";
import { storeDataRequest } from "stores/actions";

function App(props) {
  const dispatch = useDispatch();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const containerRef = useRef();

  useLayoutEffect(() => {
    const updateDevice = () => {
      if (containerRef.current) {
        const dimension = {
          width: containerRef.current?.clientWidth,
          height: containerRef.current?.clientHeight,
        };
        dispatch(
          storeDataRequest({
            globalGalleryContainerDimension: dimension,
          })
        );
      }
    };

    window.addEventListener("resize", updateDevice);
    updateDevice();
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  useEffect(() => {
    utils.loadPendoScript();
  }, []);

  const sendTrackingPendoData = (location, clientId) => {
    const { pendo = {} } = window;

    try {
      if (pendo && !_.isEmpty(pendo)) {
        setTimeout(() => {
          const clientName = utils.capitalizeFirstLetter(
            sessionStorage.getItem("clientName")
          );
          const initData = {
            visitor: {
              id: "",
            },
            account: {
              id: clientId,
            },
          };
          pendo.initialize(initData);
          sessionStorage.setItem("location", JSON.stringify(location));
          const trackingData = {
            visitorId: "",
            accountId: clientId,
            timestamp: new Date().getTime(),
            properties: location,
          };
          setTimeout(() => {
            pendo.track(
              `Client ${
                clientName ? "Name: " + clientName : "Id: " + clientId
              } - Widget Gallery Loaded`,
              trackingData
            );
            console.info("[Pendo] Sent tracking data!");
          }, 2000);
        }, 2000);
      }
    } catch (error) {
      console.info("[Pendo] Could not send tracking data!");
    }
  };

  const didMountCallback = () => {
    // TBD - do something as global actions after component was mounted
  };

  // Common request function to call API
  const requestAPI = (
    method,
    url,
    data,
    successCallback,
    failedCallback,
    errorCallback,
    isServiceAPI
  ) => {
    setIsShowLoading(true);

    axios.defaults.withCredentials = false;
    axios.defaults.validateStatus = () => true;
    axios["headers"] = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios["mode"] = "no-cors";
    const headers = {
      "x-client-id": sessionStorage.getItem("clientId"),
    };

    axios[method](url, data, { headers: headers, validateStatus: () => true })
      .then((resp) => {
        setIsShowLoading(false);

        // unpredictable responsve data format from external APIs, so no need to check data for success case
        if (isServiceAPI) {
          successCallback(resp.data);
        }

        if (resp.data && resp.data.status) {
          if (resp.data.status.code === 200) {
            if (url.includes("content/public/upload-content")) {
              successCallback(
                resp.data.result && resp.data.result.length > 0
                  ? resp.data.result[0]
                  : resp.data.result
              );
            } else {
              successCallback(resp.data.result);
            }
          } else {
            failedCallback(resp.data);
          }
        }
      })
      .catch((err) => {
        setIsShowLoading(false);
        setIsDisabled(true);

        if (errorCallback && typeof errorCallback === "function") {
          errorCallback();
        }
        console.warn("Error", err);
      });
  };

  let globalClasses = "";
  if (!isDisabled) {
    globalClasses = isShowLoading ? "loading-state" : "";
  } else {
    globalClasses = "disabled";
  }

  const globalProps = {
    setIsShowLoading,
    isShowLoading,
    requestAPI,
    didMountCallback,
    sendTrackingPendoData,
    globalInfo: props,
  };

  const routes = useMemo(() => <Routes globalProps={globalProps} />, []); // Prevent the page component re-render after opening modal

  return (
    <div ref={containerRef} className={`widget ${globalClasses}`}>
      <Router history={history}>{routes}</Router>
    </div>
  );
}

export default App;

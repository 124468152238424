import { GALLERY_THEME } from "constants/gallery";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { getScreenMode } from "services/gallery";
import { StyledSection } from "styled-components/CommonStyled";
import {
  StyledSkeleton,
  StyledSkeletonImage,
} from "styled-components/CustomGallery/CustomGalleryStyled";
import {
  StyleContainerCarousel,
  StyledWrapperImageCarousel,
} from "styled-components/CustomGallery/SliderGalleryStyled";

const DefaultSkeletonLoading = () => {
  const wrapperRef = useRef();
  const defaultHeightOfTwoTextRows = 150; // + padding
  const maxHeight = 350;

  const [numberOfEls, setNumberOfEls] = useState(8);
  const { globalGalleryContainerDimension } = useSelector(
    (state) => state.gallery
  );

  const handleGalleryConfig = (galleryContainerRef) => {
    if (galleryContainerRef.current) {
      const mode = getScreenMode(
        galleryContainerRef.current?.clientWidth,
        GALLERY_THEME.GRID
      );
      if (mode) {
        setNumberOfEls(mode.slidesToShow);
      }
    }
  };

  useLayoutEffect(() => {
    const updateDevice = () => {
      if (wrapperRef.current) {
        handleGalleryConfig(wrapperRef);
      }
    };

    window.addEventListener("resize", updateDevice);
    updateDevice();
    return () => window.removeEventListener("resize", updateDevice);
  }, [wrapperRef.current]);

  const settingSlider = {
    dots: false,
    rows: 1,
    slidesPerRow: numberOfEls,
  };

  const renderSkeletonItem = () => {
    return [...Array(numberOfEls)].map((_, index) => {
      return (
        <StyledWrapperImageCarousel key={index}>
          <StyledSkeletonImage
            width="97%"
            borderRadius="4px"
            active={() => {
              setTimeout(() => {
                return true;
              }, 0);
            }}
          />
        </StyledWrapperImageCarousel>
      );
    });
  };

  const skeletonDimension = useMemo(() => {
    const width = (globalGalleryContainerDimension?.width - 30) / numberOfEls;
    let height =
      globalGalleryContainerDimension?.height - defaultHeightOfTwoTextRows;

    if (height > maxHeight && height > width) {
      height = width;
    }

    return { width, height };
  }, [globalGalleryContainerDimension]);

  return (
    <StyleContainerCarousel
      ref={wrapperRef}
      className="slider_carousel grid_theme initial_loading"
      maxHeight={skeletonDimension?.height}
    >
      <StyledSection margin="0 0 12px 0">
        <StyledSkeleton className="default" paragraph={{ rows: 1 }} active />
      </StyledSection>
      <Slider {...settingSlider}>{renderSkeletonItem()}</Slider>
    </StyleContainerCarousel>
  );
};

export default DefaultSkeletonLoading;

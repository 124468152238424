import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const configMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(sagaMiddleware);
  } else {
    return applyMiddleware(sagaMiddleware, loggerMiddleware);
  }
};

const composeEnhancers = composeWithDevTools({
  // Specify name here
});

const configStore = () => {
  const store = createStore(rootReducer, composeEnhancers(configMiddleware()));

  sagaMiddleware.run(rootSaga);
  return store;
};

const stores = configStore();
export default stores;

import { useEffect } from "react";
import { PENDO_TRACKING_TYPE } from "../../../../../../constants/common";

const useTrackCaptionLink = (content, trackElement, id, onTrackFunc) => {
  const _blank = "_blank";

  useEffect(() => {
    if (trackElement) {
      const trackingFunc = () => {
        onTrackFunc(PENDO_TRACKING_TYPE.HYPERLINK_CAPTION_CLICKED);
        onTrackFunc(PENDO_TRACKING_TYPE.ALL_HYPERLINK_CLICKED);
        onTrackFunc(PENDO_TRACKING_TYPE.GALLERY_HYPERLINK_CLICKED);
      };

      const linkEls = document.getElementById(id)?.getElementsByTagName("a");
      if (linkEls && linkEls.length) {
        for (var i = 0; i < linkEls.length; i++) {
          const target = linkEls[i].getAttribute("target");
          if (!target || target !== _blank) {
            linkEls[i].setAttribute("target", _blank);
          }
          linkEls[i].addEventListener("click", () => {
            trackingFunc();
          });
        }
      }

      return () => {
        const linkEls = document.getElementById(id)?.getElementsByTagName("a");
        if (linkEls && linkEls.length) {
          for (var i = 0; i < linkEls.length; i++) {
            const target = linkEls[i].getAttribute("target");
            if (!target || target !== _blank) {
              linkEls[i].setAttribute("target", _blank);
            }
            linkEls[i].removeEventListener("click", () => {
              trackingFunc();
            });
          }
        }
      };
    }
  }, [content, trackElement]);
};

export default useTrackCaptionLink;

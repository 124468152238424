import _ from "lodash";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { FILTER_FIELD_NAME } from "constants/gallery";
import FontFaceObserver from "fontfaceobserver";
import { useTranslation } from "react-i18next";
import { filterContentList, setFilterList } from "services/gallery";
import { StyledSpin } from "styled-components/CommonStyled";
import {
  FONT_FAMILY,
  PATTERNS,
  PENDO_TRACKING_TYPE,
  TIME_OUT,
} from "../../../../../constants/common";
import configUrls from "../../../../../constants/configUrls";
import GalleryContext from "../../../../../contexts/Gallery";
import utils from "../../../../../services/utils";
import {
  StyledGalleryWrapper,
  StyledLoading,
} from "../../../../../styled-components/CustomGallery/CustomGalleryStyled";
import NotFound from "../../../../pages/NotFound/NotFound";
import DefaultSkeletonLoading from "../DefaultSkeletonLoading";
import SliderGallery from "../SliderGallery/SliderGallery";

const GalleryWrapper = (props) => {
  const { t } = useTranslation();
  const { globalProps } = props;
  const { requestAPI } = globalProps;
  const location = useLocation();
  const { pathname = "", search = "" } = location;

  const globalInfo = globalProps?.globalInfo;
  const clientId = globalInfo?.clientIdGlobal || utils.getClientId(search);
  const galleryId =
    globalInfo?.galleryIdGlobal || utils.getGalleryFolderId(search);
  const {
    setPendoTrackingData,
    setCustomPropertyList,
    filtersApplied,
    setIsLoading,
    isLoading,
  } = useContext(GalleryContext);

  const [galleryDetail, setGalleryDetail] = useState();
  const [originalContentList, setOriginalContentList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoadedFont, setIsLoadedFont] = useState(false);

  useEffect(() => {
    initPendo();
    loadFonts();
  }, []);

  const showSkeletonLoading = useMemo(() => {
    return initialLoading || !isLoadedFont;
  }, [initialLoading, isLoadedFont]);

  const loadFonts = () => {
    const observers = [];
    const timeoutGivingUpLoading = 20000;
    const fonts = [
      {
        fontFamily: FONT_FAMILY.IBM_PLEX_SANS,
        data: {},
      },
    ];
    fonts.forEach((item) => {
      const obs = new FontFaceObserver(item.fontFamily, item.data);
      observers.push(obs.load(null, timeoutGivingUpLoading));
    });
    // eslint-disable-next-line no-undef
    Promise.all(observers).finally(() => {
      setIsLoadedFont(true);
    });
  };

  useEffect(() => {
    if (filtersApplied) {
      setIsLoading(true);
      setTimeout(() => {
        if (!_.isEmpty(filtersApplied)) {
          const filteredList = filterContentList(
            filtersApplied,
            originalContentList
          );
          setContentList(filteredList);
        } else {
          setContentList(originalContentList);
        }
        setIsLoading(false);
      }, TIME_OUT.T_200);
    }

    return () => {
      setContentList([]);
      setIsLoading(false);
    };
  }, [filtersApplied]);

  const initPendo = () => {
    setInitialLoading(true);
    if (clientId) {
      verifyGallery();
      const data = {
        clientId,
        galleryId,
        location: null,
      };

      utils.pendoTracking({ ...data, trackType: PENDO_TRACKING_TYPE.LOADED });
      utils.pendoTracking({
        ...data,
        trackType: PENDO_TRACKING_TYPE.ALL_GALLERY_VISITED,
      });
      setPendoTrackingData(data);
    } else {
      setTimeout(() => {
        setInitialLoading(false);
      }, TIME_OUT.T_400);
      setGalleryDetail(null);
    }
  };

  const verifyGallery = () => {
    const urlAPI = `${configUrls.API.gallery}/${clientId}/${galleryId}`;
    const pathName = pathname.includes("/:")
      ? utils.removeFirstSlash(pathname).split("/")[0]
      : pathname;

    sessionStorage.setItem("clientId", clientId);
    sessionStorage.setItem("clientName", utils.removeFirstSlash(pathName));
    const successCallback = (resp) => {
      if (resp) {
        const { metadata } = resp;
        let galleryDetailTemp = { ...resp };
        if (metadata) {
          galleryDetailTemp = {
            ...galleryDetailTemp,
            metadata: JSON.parse(metadata),
          };
        }

        setGalleryDetail(galleryDetailTemp);
        const contentListTemp = _.concat(
          contentList || [],
          formatContentList(resp.contentContainers)
        );
        handleFilterData(
          contentListTemp,
          galleryDetailTemp?.metadata?.galleryFilters
        );
      }
      setInitialLoading(false);
    };
    const failedCallback = () => {
      setGalleryDetail(null);
      setInitialLoading(false);
    };
    const errorCallback = () => {};

    requestAPI(
      "get",
      urlAPI,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      false
    );
  };

  const handleFilterData = (contentList, galleryFilters) => {
    const filterData = setFilterList(contentList);
    const creatorEl = {
      label: t("gallery_filters.creator"),
      value: filterData?.creators || [],
      fieldName: FILTER_FIELD_NAME.CREATOR,
    };
    let customProperties = [];
    if (galleryFilters && galleryFilters?.customProperties?.length) {
      filterData?.customProperties.unshift(creatorEl);
      customProperties = filterData?.customProperties?.filter((item) =>
        galleryFilters?.customProperties?.some((customPropertyItem) => {
          return (
            customPropertyItem === item.label ||
            (PATTERNS.BASE64.test(customPropertyItem) &&
              atob(customPropertyItem) === item.fieldName)
          );
        })
      );
    }
    if (filterData) {
      setCustomPropertyList(customProperties);
    }
    setOriginalContentList(contentList);
    setContentList(contentList);
  };

  const formatContentList = (data) =>
    data.map((item) => {
      let contentTemp = utils.getContentByType(item);
      const thumbnail = utils.getSocialS3URL(contentTemp.thumbnail);
      const contentUrl = utils.getSocialS3URL(contentTemp.contentUrl);

      return { ...contentTemp, thumbnail, contentUrl };
    });

  const renderGallery = useMemo(() => {
    if (!_.isEmpty(galleryDetail) && contentList && contentList.length >= 0) {
      return (
        <StyledGalleryWrapper initialLoading={showSkeletonLoading}>
          <SliderGallery
            galleryDetail={galleryDetail}
            contentList={contentList}
          />
        </StyledGalleryWrapper>
      );
    }

    if (_.isNull(galleryDetail) && !initialLoading) {
      return <NotFound globalProps={globalProps} />;
    }

    return null;
  }, [galleryDetail, contentList, showSkeletonLoading]);

  return (
    <>
      {showSkeletonLoading && (
        <StyledGalleryWrapper
          className="default"
          initialLoading={showSkeletonLoading}
        >
          <DefaultSkeletonLoading />
        </StyledGalleryWrapper>
      )}

      <StyledLoading isLoading={isLoading}>
        <StyledSpin size="large" />
      </StyledLoading>

      {renderGallery}
    </>
  );
};

GalleryWrapper.propTypes = {
  globalProps: PropTypes.object,
};

export default GalleryWrapper;

import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useIsVisible } from "services/hooks";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ width }) => width && `width:${width}`};
  overflow-x: scroll;
  cursor: pointer;
  scrollbar-width: none; // Firefox

  &,
  * {
    font-family: "IBM Plex Sans" !important;
  }

  &.disable_scrolling {
    overflow: unset;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ScrollableWrapper = (props) => {
  const { children, wrapperWidth, className, callback } = props;
  const ref = useRef();

  useIsVisible(ref, callback, children);

  useEffect(() => {
    let mouseDown = false;
    let startX, scrollLeft;
    const slider = document.querySelector(".scrollable_wrapper");

    if (slider) {
      const startDragging = (e) => {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };

      const stopDragging = (e) => {
        mouseDown = false;
      };

      const move = (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      };

      slider.addEventListener("mousemove", move, false);
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);

      return () => {
        slider.removeEventListener("mousemove", move, false);
        slider.removeEventListener("mousedown", startDragging, false);
        slider.removeEventListener("mouseup", stopDragging, false);
        slider.removeEventListener("mouseleave", stopDragging, false);
      };
    }
  }, [ref, children]);

  return (
    <StyledWrapper ref={ref} className={className} width={wrapperWidth}>
      {children}
    </StyledWrapper>
  );
};

export default React.memo(ScrollableWrapper);

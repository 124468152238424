import { Skeleton } from "antd";
import SkeletonImage from "antd/es/skeleton/Image";
import SkeletonInput from "antd/es/skeleton/Input";
import { PREFIX_CLS } from "constants/common";
import styled, { css } from "styled-components";
import THEME from "../../constants/themes";

export const StyledGalleryWrapper = styled.div`
  height: 100%;
  ${({ initialLoading }) => `opacity: ${initialLoading ? 0 : 1}`};
  transition: opacity 0.2s ease-in-out;

  &.default {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    ${({ initialLoading }) => `opacity: ${initialLoading ? 1 : 0}`};
  }
`;

export const StyledSliderGallery = styled.div`
  pointer-events: auto !important;
  height: 100%;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  z-index: 98;
  transition: 0.25s ease-out;
  padding: 8px;
  font-size: 12px;
`;

export const StyledFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ align }) => align || "center"};
  margin: ${({ margin }) => margin || "unset"};
  padding: ${({ padding }) => padding || "unset"};
`;

export const StyledIcon = styled.div`
  ${({ display }) => display && `display: ${display}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};

  svg {
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    ${({ size }) =>
      size &&
      css`
        width: ${size}px;
        height: ${size}px;
      `}

    &,
    path {
      ${({ color }) => color && `fill: ${color} !important`};
    }
  }
`;

export const StyledInfoItem = styled.a`
  margin: 0 0 8px 8px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${THEME.colors.darkBlue4};
  word-break: break-all;

  &:hover {
    color: ${THEME.colors.orangeBase};

    svg {
      fill: ${THEME.colors.orangeBase};
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;

export const StyledHyperlink = styled.a`
  width: fit-content;
  font-size: 1.429rem;
  color: ${THEME.colors.black};
  text-decoration: unset;
  cursor: pointer;
`;

export const StyledLoading = styled.div`
  display: ${({ isLoading }) => (isLoading === true ? "flex" : "none")};
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColorLoading }) =>
    bgColorLoading || "rgba(32, 50, 65, 0.1)"};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`;

export const StyledSkeletonImage = styled(SkeletonImage)`
  background-color: ${({ bgColor }) => bgColor || "rgba(238, 238, 238, 0.6);"};
  position: ${({ position }) => position || "absolute"};
  width: ${({ width }) => `${width || "100%"} !important`};
  height: 100%;
  top: 0;
  left: 0;

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      &,
      .${PREFIX_CLS}-skeleton-image {
        border-radius: ${borderRadius};
      }
    `}

  &.${PREFIX_CLS}-skeleton-active {
    .${PREFIX_CLS}-skeleton-image {
      ${({ bgImg }) => bgImg && `background-image: ${bgImg} !important`};
    }
  }

  .${PREFIX_CLS}-skeleton-image {
    width: 100% !important;
    height: ${({ height }) => `${height || "100%"} !important`};

    svg {
      display: none;
    }
  }
`;

export const StyledSkeletonInput = styled(SkeletonInput)`
  width: 200px !important;
  ${({ height }) => height && `height: ${height}px`};
`;

export const StyledSkeleton = styled(Skeleton)`
  &.${PREFIX_CLS}-skeleton-active {
    li,
    .${PREFIX_CLS}-skeleton-title {
      ${({ bgImg }) => bgImg && `background-image: ${bgImg} !important`};
    }
  }

  &.default {
    .${PREFIX_CLS}-skeleton-title, .${PREFIX_CLS}-skeleton-paragraph li {
      background-color: rgba(238, 238, 238, 0.6);
      height: 24px !important;
    }
  }
`;

export const StyledDefaultSkeletonLoadingWrapper = styled.div``;

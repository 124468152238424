const data = {
  groupedOptions: [
    {
      label: "Custom Printed Items",
      options: [
        { value: "Cake Toppers", label: "Cake Toppers" },
        {
          value: "Cookie / Cupcake Toppers",
          label: "Cookie / Cupcake Toppers",
        },
        { value: "Oreo Toppers", label: "Oreo Toppers" },
        { value: "Drink Toppers", label: "Drink Toppers" },
        { value: "Iced Cookies", label: "Iced Cookies" },
        { value: "Naked Cookies", label: "Naked Cookies" },
        { value: "Marshmallows", label: "Marshmallows" },
        { value: "Macarons", label: "Macarons" },
        { value: "Oreos", label: "Oreos" },
        { value: "Ginger Cookies", label: "Ginger Cookies" },
        { value: "Frosting Sheets", label: "Frosting Sheets" },
        { value: "Wafer Sheets", label: "Wafer Sheets" },
        {
          value: "Chocolate Transfer Sheets",
          label: "Chocolate Transfer Sheets",
        },
        { value: "Custom Printed Masks", label: "Custom Printed Masks" },
      ],
    },
    {
      label: "Printers",
      options: [
        {
          value: "Standard Edible Ink Printer (Canon)",
          label: "Standard Edible Ink Printer (Canon)",
        },
        {
          value: "Standard Edible Ink Printer (Epson)",
          label: "Standard Edible Ink Printer (Epson)",
        },
        {
          value: "Direct to Food Edible Ink Printer (CakePro)",
          label: "Direct to Food Edible Ink Printer (CakePro)",
        },
      ],
    },
    {
      label: "Other",
      options: [{ value: "Other", label: "Another Product" }],
    },
  ],

  groupedOptions1: [
    {
      label: "Edible Ink",
      options: [
        { value: "Edible Ink for Canon", label: "Edible Ink for Canon" },
        { value: "Edible Ink for Epson", label: "Edible Ink for Epson" },
        { value: "Edible Ink for CakePro", label: "Edible Ink for CakePro" },
        { value: "Edible Ink Marker", label: "Edible Ink Marker" },
      ],
    },
    {
      label: "Edible Paper",
      options: [
        { value: "Premium-Ultra Sheets", label: "Premium-Ultra Sheets" },
        { value: "Premium Sheets", label: "Premium Sheets" },
        { value: "Artisan Sheets", label: "Artisan Sheets" },
        { value: "FlexFrost Sheets", label: "FlexFrost Sheets" },
        { value: "Original Sheets", label: "Original Sheets" },
        { value: "Wafer Paper", label: "Wafer Paper" },
      ],
    },
    {
      label: "Chocolate or Candy Related Products",
      options: [
        {
          value: "Chocolate Transfer Sheets (I printed my own designs)",
          label: "Chocolate Transfer Sheets (I printed my own designs)",
        },
        {
          value: "Chocolate Transfer Sheets (pre-printed designs)",
          label: "Chocolate Transfer Sheets (pre-printed designs)",
        },
        {
          value: "Chocolate Transfer Accessories (such as choco-spray, other)",
          label: "Chocolate Transfer Accessories (such as choco-spray, other)",
        },
        { value: "Magnetic Molds", label: "Magnetic Molds" },
        {
          value: "Silicone / PolyCarbonate / 3D Molds",
          label: "Silicone / PolyCarbonate / 3D Molds",
        },
      ],
    },
    {
      label: "AirBrush",
      options: [
        { value: "AirBrush Inks", label: "AirBrush Inks" },
        { value: "AirBrush Equipment", label: "AirBrush Equipment" },
        { value: "Other AirBrush Items", label: "Other AirBrush Items" },
      ],
    },
    {
      label: "Ink Printer",
      options: [
        {
          value: "Standard Edible Ink Printer (Canon)",
          label: "Standard Edible Ink Printer (Canon)",
        },
        {
          value: "Standard Edible Ink Printer (Epson)",
          label: "Standard Edible Ink Printer (Epson)",
        },
        {
          value: "Direct to Food Edible Ink Printer (CakePro)",
          label: "Direct to Food Edible Ink Printer (CakePro)",
        },
      ],
    },
    {
      label: "Other",
      options: [{ value: "Other", label: "Another Product" }],
    },
  ],

  groupedOptions2: [
    { label: "Cake", value: "Cake" },
    { label: "How-To (instructional)", value: "How-To (instructional)" },
    { label: "Cupcakes", value: "Cupcakes" },
    { label: "Oreos", value: "Oreos" },
    { label: "Macarons", value: "Macarons" },
    { label: "Cookies", value: "Cookies" },
    { label: "Drinks", value: "Drinks" },
    { label: "Chocolates", value: "Chocolates" },
    { label: "Candy", value: "Candy" },
    { label: "Cake Topper", value: "Cake Topper" },
    { label: "Cookie or Cupcake Topper", value: "Cookie or Cupcake Topper" },
  ],

  groupedOptions3: [
    { label: "Birthday (Kids)", value: "Birthday (Kids)" },
    { label: "Birthday (Adult)", value: "Birthday (Adult)" },
    { label: "New Baby / Pregnant", value: "New Baby / Pregnant" },
    { label: "Engagement / Wedding", value: "Engagement / Wedding" },
    { label: "Religious", value: "Religious" },
    { label: "In memory of / Memorial", value: "In memory of / Memorial" },
    { label: "Photo / Personal", value: "Photo / Personal" },
    { label: "Business / Logo", value: "Business / Logo" },
    { label: "Sports", value: "Sports" },
    { label: "Cartoon / Animation", value: "Cartoon / Animation" },
    { label: "Movie / Cinematic", value: "Movie / Cinematic" },
    { label: "Social / Fun", value: "Social / Fun" },
    { label: "Funny / Comedy", value: "Funny / Comedy" },
    { label: "Social Causes", value: "Social Causes" },
    { label: "Games", value: "Games" },
    { label: "Education", value: "Education" },
    { label: "Halloween / Scary", value: "Halloween / Scary" },
    { label: "Fun / Novelty", value: "Fun / Novelty" },
    { label: "Parody", value: "Parody" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Product Representation", value: "Product Representation" },
    { label: "Luxury", value: "Luxury" },
    { label: "Adult / Naughty", value: "Adult / Naughty" },
    { label: "Kid-Friendly", value: "Kid-Friendly" },
    { label: "Music", value: "Music" },
    { label: "Mom / Dad", value: "Mom / Dad" },
    { label: "Family", value: "Family" },
    { label: "Love / Peace", value: "Love / Peace" },
    { label: "Food / Culinary", value: "Food / Culinary" },
    { label: "Christmas", value: "Christmas" },
    { label: "Easter", value: "Easter" },
    { label: "USA / Patriotic", value: "USA / Patriotic" },
    { label: "Other Holiday", value: "Other Holiday" },
    { label: "International", value: "International" },
    { label: "Cultural", value: "Cultural" },
    { label: "Transportation", value: "Transportation" },
    { label: "Celebrity", value: "Celebrity" },
    { label: "Monogram / Initials", value: "Monogram / Initials" },
  ],
};

export default data;

import * as React from "react";

// Default context values
const values = {
  setPendoTrackingData: () => {},
  pendoTrackingData: {},
  customPropertyList: [],
  setCustomPropertyList: () => {},
  filtersApplied: {},
  setFiltersApplied: () => {},
  isFiltered: false,
  setIsFiltered: () => {},
  isLoading: false,
  setIsLoading: () => {},
};

const GalleryContext = React.createContext(values);
export default GalleryContext;
